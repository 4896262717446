import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { getUserType } from '@Utils/helper';
import { getProfile } from '@Libs/slices/userSlice';
import axiosService from '@Libs/axiosService';
import PrivateArtistRouter from './artistRouters';
import PrivateUserRouter from './userRouters';

export default function PrivateRouter() {
  const userType = getUserType();
  const info = useSelector((state) => state.user.info);
  const token = localStorage.getItem(process.env.TOKEN);
  if (token) {
    axiosService.setHeader('Authorization', `Bearer ${token}`);
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (!info) {
      dispatch(getProfile());
    }
  }, [info]);
  const routesConstant = [];
  if (info) {
    if (userType === 'USER') {
      const routes = PrivateUserRouter({ info });
      routesConstant.push(...routes);
    } else {
      const routes = PrivateArtistRouter();
      routesConstant.push(...routes);
    }
  }

  return routesConstant;
}
