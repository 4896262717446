import React from 'react';
import PropTypes from 'prop-types';
import { BANK_INPUT } from '@Constants/artistUserContants';
import { useForm } from 'react-hook-form';
import FormInput from '@Components/FormInput';
import ButtonConfirm from '@Components/ButtonConfirm';
import { useNavigate } from 'react-router-dom';

function Bank({ data, mutate, isLoading }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
    watch,
  } = useForm();

  const onSubmit = (dataUpdate) => {
    const { bankAccounts0, bankAccounts1 } = dataUpdate;
    const formData = new FormData();
    formData.append('bankAccounts[0][bankName]', bankAccounts0?.bankName || '');
    formData.append('bankAccounts[0][accountHolder]', bankAccounts0?.accountHolder || '');
    formData.append('bankAccounts[0][accountNumber]', bankAccounts0?.accountNumber || '');
    formData.append('bankAccounts[1][bankName]', bankAccounts1?.bankName || '');
    formData.append('bankAccounts[1][accountHolder]', bankAccounts1?.accountHolder || '');
    formData.append('bankAccounts[1][accountNumber]', bankAccounts1?.accountNumber || '');
    mutate({
      formData,
    });
  };

  return (
    <div className="artist-content">
      <div className="mb-4">
        <p className="fs-4 mb-4">Thông tin ngân hàng</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            inputsForm={BANK_INPUT({ data })}
            register={register}
            errors={errors}
            setValueForm={setValueForm}
            watch={watch}
          />
          <div className="d-flex gap-8">
            <ButtonConfirm variant="danger" name="Lưu lại" type="submit" loading={isLoading} />
            <ButtonConfirm
              variant="outline-danger"
              name="Huỷ"
              onClick={() => navigate('/dashboard')}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

Bank.propTypes = { data: PropTypes.object, mutate: PropTypes.func, isLoading: PropTypes.bool };

export default Bank;
