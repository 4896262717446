import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchInput from '@Components/SearchInput';
import { ARTWORK_STATUS } from '@Constants/artworkConstants';
import MultiSelect from '@Components/MultiSelect';
import {
  getOptionsByName,
  processArtistMultiOption,
  processTagMultiOption,
  processTagsFilter,
  processTagsFilterWhenClear,
  removeOptionValueAll,
} from '@Utils/helper';
import TagControl from '@Components/TagControl';
import FlatPickrRange from '@Components/FlatPickrRange';
import { Button } from 'react-bootstrap';
import { Plus } from 'react-feather';
import { usePermissions } from '@Utils/usePermissions';

function Filter({
  setFilter,
  pageSize,
  artworkTags = [],
  artistTags = [],
  collectionTags = [],
  artistIdsDefault,
  page = 1,
  isShowButtonAddNew = false,
  setIsOpenAddNew,
  filter,
  productLines = [],
}) {
  const [permissionAddnew, setPermissionAddnew] = useState(false);
  const isHasPermissionAddNew = usePermissions({ roleName: 'artwork', permission: 'create' });
  useEffect(() => {
    if (isShowButtonAddNew && isHasPermissionAddNew) {
      setPermissionAddnew(true);
    } else {
      setPermissionAddnew(false);
    }
  }, [isShowButtonAddNew, isHasPermissionAddNew]);
  const groupOptions = [
    {
      name: 'statuses',
      inititalOptions: ARTWORK_STATUS,
      allOptions: [{ value: 'all', label: 'Tất cả trạng thái' }, ...ARTWORK_STATUS],
      selectedOptions: [],
    },
  ];
  const [options, setOptions] = useState(groupOptions);

  useEffect(() => {
    if (artworkTags.length > 0) {
      const artworkTagOptions = processTagMultiOption(artworkTags);
      const optionsByName = {
        name: 'tagIds',
        inititalOptions: artworkTagOptions,
        allOptions: [{ value: 'all', label: 'Tất cả tag' }, ...artworkTagOptions],
        selectedOptions: [],
      };
      options.push(optionsByName);
      setOptions([...options]);
    }
  }, [artworkTags]);

  useEffect(() => {
    if (artistTags.length > 0) {
      const artistOptions = processArtistMultiOption(artistTags);
      const optionsByName = {
        name: 'artistIds',
        inititalOptions: artistOptions,
        allOptions: [{ value: 'all', label: 'Tất cả artist' }, ...artistOptions],
        selectedOptions: [],
      };
      options.push(optionsByName);
      setOptions([...options]);
    }
  }, [artistTags]);

  useEffect(() => {
    if (collectionTags.length > 0) {
      const collectionOptions = processTagMultiOption(collectionTags);
      const optionsByName = {
        name: 'collectionIds',
        inititalOptions: collectionOptions,
        allOptions: [{ value: 'all', label: 'Tất cả collection' }, ...collectionOptions],
        selectedOptions: [],
      };
      options.push(optionsByName);
      setOptions([...options]);
    }
  }, [collectionTags]);

  useEffect(() => {
    if (productLines.length > 0) {
      const artworkProductLineOptions = processTagMultiOption(productLines);
      const optionsByName = {
        name: 'productLineIds',
        inititalOptions: artworkProductLineOptions,
        allOptions: [{ value: 'all', label: 'Tất cả Product Line' }, ...artworkProductLineOptions],
        selectedOptions: [],
      };
      options.push(optionsByName);
      setOptions([...options]);
    }
  }, [productLines]);

  const [dateRange, setDateRange] = useState();
  const [search, setSearch] = useState();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (search) {
      filter.searchText = search;
    } else {
      delete filter.searchText;
    }
    if (dateRange && dateRange.length > 0) {
      filter.createdAtStart = dateRange[0];
      filter.createdAtEnd = dateRange[1];
    } else {
      delete filter.createdAtStart;
      delete filter.createdAtEnd;
    }
    if (tags && tags.length > 0) {
      tags.map((tag) => {
        const tagValues = tag.values.map((item) => item.value);
        filter[tag.tagName] = tagValues.join(',');
      });
    } else {
      delete filter.tagIds;
      delete filter.artistIds;
      delete filter.collectionIds;
      delete filter.productLineIds;
    }
    if (artistIdsDefault) {
      filter.artistIds = artistIdsDefault;
    }
    setFilter({ orderBy: 'createdAt', order: 'DESC', ...filter, page, pageSize });
  }, [dateRange, page, pageSize, tags, search, artistIdsDefault]);

  useEffect(() => {
    const tags = [];
    options.map((option) => {
      const { name } = option;
      if (option.selectedOptions.length > 0) {
        const values = removeOptionValueAll(option.selectedOptions);
        tags.push({
          tagName: name,
          values,
        });
      } else {
        tags.push({
          tagName: name,
          values: [],
        });
      }
    });
    setTags(processTagsFilter(tags));
  }, [options]);

  const handleOnChangeSelect = (selected, event) => {
    if (selected && event) {
      const { name } = event;

      const optionsByName = options.find((item) => item.name === name);
      if (event.action === 'select-option' && event.option.value === 'all') {
        optionsByName.selectedOptions = optionsByName.allOptions;
        setOptions([...options]);
        return;
      }
      if (event.action === 'deselect-option' && event.option.value === 'all') {
        optionsByName.selectedOptions = [];
        setOptions([...options]);
        return;
      }
      optionsByName.selectedOptions = selected.filter((item) => item.value !== 'all');
      setOptions([...options]);
    }
  };

  const onDeleteAllFilter = () => {
    const optionsDeselected = options.map((item) => {
      item.selectedOptions = [];
      return item;
    });
    setOptions([...optionsDeselected]);
  };

  const onDeleteFilter = ({ tagName, value }) => {
    const optionsByName = options.find((item) => item.name === tagName);
    const filterSelectedOptions = processTagsFilterWhenClear({
      option: optionsByName,
      valueNeedRemove: value,
    });
    optionsByName.selectedOptions = filterSelectedOptions;
    setOptions([...options]);
  };

  const optionStatus = getOptionsByName({ inititalOptions: options, name: 'statuses' });
  const optionsTags = getOptionsByName({ inititalOptions: options, name: 'tagIds' });
  const optionsArtist = getOptionsByName({ inititalOptions: options, name: 'artistIds' });
  const optionsCollection = getOptionsByName({ inititalOptions: options, name: 'collectionIds' });
  const optionProductLine = getOptionsByName({ inititalOptions: options, name: 'productLineIds' });

  return (
    <div className="filter-wrapper">
      <div className="d-flex gap-14 justify-content-between">
        <div className="d-flex gap-14 justify-content-start flex-wrap">
          <SearchInput
            placeholder="Tìm theo tên, mã Artwork, nghệ danh"
            setSearch={setSearch}
            defaultValue={search}
          />
          <MultiSelect
            style={{ width: '170px' }}
            allOptions={optionStatus.allOptions}
            placeholder="Trạng thái"
            className="multi-select"
            selectedOptions={optionStatus.selectedOptions}
            handleOnChange={handleOnChangeSelect}
            name={optionStatus.name}
          />
          {optionsTags && (
            <MultiSelect
              style={{ width: '200px' }}
              allOptions={optionsTags.allOptions}
              placeholder="Chọn tag"
              className="multi-select"
              selectedOptions={optionsTags.selectedOptions}
              handleOnChange={handleOnChangeSelect}
              name={optionsTags.name}
            />
          )}
          {optionsArtist && (
            <MultiSelect
              style={{ width: '200px' }}
              allOptions={optionsArtist.allOptions}
              placeholder="Chọn artist"
              className="multi-select"
              selectedOptions={optionsArtist.selectedOptions}
              handleOnChange={handleOnChangeSelect}
              name={optionsArtist.name}
            />
          )}
          {optionsCollection && (
            <MultiSelect
              style={{ width: '200px' }}
              allOptions={optionsCollection.allOptions}
              placeholder="Chọn collection"
              className="multi-select"
              selectedOptions={optionsCollection.selectedOptions}
              handleOnChange={handleOnChangeSelect}
              name={optionsCollection.name}
            />
          )}
          {optionProductLine && (
            <MultiSelect
              style={{ width: '250px' }}
              allOptions={optionProductLine.allOptions}
              placeholder="Chọn Product Line"
              className="multi-select"
              selectedOptions={optionProductLine.selectedOptions}
              handleOnChange={handleOnChangeSelect}
              name={optionProductLine.name}
            />
          )}
          <FlatPickrRange
            name="date-range"
            placeholder="Ngày tạo"
            date={dateRange}
            setDate={setDateRange}
            maxDate="today"
          />
          {permissionAddnew && (
            <Button
              variant="danger"
              onClick={() => setIsOpenAddNew(true)}
              style={{ width: '160px' }}
            >
              <Plus size="14" />
              <span className="fs-14 fw-semibold ms-1">Thêm Artwork</span>
            </Button>
          )}
        </div>
      </div>
      <TagControl
        tags={tags}
        onDeleteAllFilter={onDeleteAllFilter}
        onDeleteFilter={onDeleteFilter}
      />
    </div>
  );
}

Filter.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  pageSize: PropTypes.number,
  artistTags: PropTypes.array,
  artworkTags: PropTypes.array,
  collectionTags: PropTypes.array,
  artistIdsDefault: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  page: PropTypes.number,
  isShowButtonAddNew: PropTypes.bool,
  setIsOpenAddNew: PropTypes.func,
  productLines: PropTypes.array,
};

export default Filter;
