import React from 'react';
import { Edit3, Trash } from 'react-feather';
import { Nav } from 'react-bootstrap';
import LogItemIcon from '@Assets/images/artist/log-item.png';
import { dayjsNow, dayjsUtc } from '@Utils/helper';
import dayjs from 'dayjs';

function columns({ openModalDetail, openModalDelete, ...props }) {
  return [
    {
      name: '',
      selector: (row) => (
        <div className="d-flex gap-20">
          <img src={LogItemIcon} alt={row.name} width="28px" />
          <div>
            <div className="d-flex gap-8 align-items-center align-items-start">
              <p className="fw-semibold fs-6">{row.name}</p>
              {row.contacted && (
                <div
                  style={{
                    color: '#D97116',
                    background: '#fbf1e8',
                    borderRadius: '100px',
                    padding: '2px 10px',
                    fontSize: '12px',
                    fontWeight: 700,
                  }}
                >
                  Liên hệ artist
                </div>
              )}
            </div>

            <span className="fs-14 text-grey white-space-pre-line">{row.description}</span>
            <p className="fs-14 text-grey">
              <span className="fw-semibold">Thời gian sự kiện: </span>
              <span>{row.timestamp ? dayjs(row.timestamp).format('DD/MM/YYYY・HH:mm') : ''}</span>
            </p>
          </div>
        </div>
      ),
    },
    {
      name: '',
      right: true,
      width: 100,
      selector: (row) => (
        <div className="d-flex align-items-center gap-8">
          <Nav.Link>
            <Edit3 color="#a70e13" size={18} onClick={() => openModalDetail(row)} />
          </Nav.Link>
          <Nav.Link>
            <Trash color="#a70e13" size={18} onClick={() => openModalDelete(row)} />
          </Nav.Link>
        </div>
      ),
    },
  ];
}
export default columns;
