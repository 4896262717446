import React from 'react';
import PropTypes from 'prop-types';
import { SubHeadingWrapper } from './styled';

function SubHeading({ icon, title, className }) {
  return (
    <SubHeadingWrapper className={className}>
      <img src={icon} alt={title} />
      <span>{title}</span>
    </SubHeadingWrapper>
  );
}

SubHeading.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default SubHeading;
