import styled from 'styled-components';

export const TabWrapper = styled.div`
  .nav {
    border-bottom: 1px solid #efeff4;
  }
  .nav-item {
    .nav-link {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      color: #667085;
      display: flex;
      align-items: center;
      gap: 7px;
      padding-bottom: 14px;
      img {
        width: 14px;
      }
    }
    .nav-link.active {
      color: #a70e13;
      border-bottom: 1px solid #a70e13;
    }
  }
`;
