import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VerificationInput from 'react-verification-input';
import ArrowLeft from '@Assets/images/icons/arrow-left.png';
import ButtonConfirm from '@Components/ButtonConfirm';
import useCustomMutation from '@Utils/useCustomMuation';
import { useFuncAuthen } from '@Utils/useFuncAuthen';
import { Title, BackWrrapper } from '../styled';
import { SHOW_RECOVERY_NEW_PASSWORD, SHOW_RECOVERY_PASSWORD } from '../constants';
import LeftSideRecoveryPasswordConfirmCountDown from './LeftSideRecoveryPasswordConfirmCountDown';

function LeftSideRecoveryPasswordConfirm({ setView, email, setTokenReset }) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [confirmCode, setConfirmCode] = useState('');

  const handleOnChange = (code) => {
    if (code.length === 4) {
      setIsDisabled(false);
      setConfirmCode(code);
    } else {
      setIsDisabled(true);
    }
  };
  const funcVerifyResetCode = useFuncAuthen({ name: 'verifyResetCode' });
  const { mutate, isSuccess, isLoading, isError, data } = useCustomMutation({
    callApi: funcVerifyResetCode,
  });

  const handleConfirmCode = () => {
    mutate({
      email,
      resetCode: confirmCode,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setTokenReset(data.resetPasswordToken);
      setView(SHOW_RECOVERY_NEW_PASSWORD);
    }
  }, [isSuccess]);

  return (
    <>
      <BackWrrapper>
        <div className="d-flex align-items-center">
          <img src={ArrowLeft} alt="back" width="20px" className="mr-1" />
          <span
            onClick={() => setView(SHOW_RECOVERY_PASSWORD)}
            tabIndex={0}
            role="button"
            className=""
          >
            Quay lại
          </span>
        </div>
      </BackWrrapper>
      <Title>Nhập mã xác nhận</Title>
      <p className="text-grey mb-5 text-center">Một mã xác nhận đã được gửi tới email {email}</p>
      <div className="d-flex align-items-center justify-content-between">
        <VerificationInput
          placeholder=""
          length={4}
          classNames={{ character: 'character' }}
          onChange={handleOnChange}
        />
      </div>
      {isError && <div className="text-danger text-center pt-2">Mã xác nhận không chính xác</div>}
      <ButtonConfirm
        type="button"
        name="Xác nhận"
        variant="danger"
        className="w-100 mt-5"
        disabled={isDisabled}
        onClick={() => handleConfirmCode()}
        loading={isLoading}
      />
      <LeftSideRecoveryPasswordConfirmCountDown email={email} />
    </>
  );
}

LeftSideRecoveryPasswordConfirm.propTypes = {
  setView: PropTypes.func.isRequired,
  email: PropTypes.string,
  setTokenReset: PropTypes.func,
};

export default LeftSideRecoveryPasswordConfirm;
