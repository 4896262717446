import axios from 'axios';

class Service {
  constructor() {
    const service = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: false,
    });
    service.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return this.handleError(error);
      },
    );

    this.service = service;
    this.subscribers = [];
    this.isAlreadyFetchingAccessToken = false;
    this.isTokenExpiredError = true;
  }

  setHeader(name, value) {
    this.service.defaults.headers.common[name] = value;
  }

  removeHeader(name) {
    delete this.service.defaults.headers.common[name];
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    const { response } = error;
    if (response) {
      switch (response.status) {
        case 401: {
          return this.resetTokenAndReattemptRequest(error);
        }
        default: {
          const message = response.data.message;
          return Promise.reject(message);
        }
      }
    } else {
      return Promise.reject(error.message);
    }
  };

  async resetTokenAndReattemptRequest(error) {
    try {
      const { response: errorResponse } = error;
      const retryOriginalRequest = new Promise((resolve) => {
        /* We need to add the request retry
        to the queue since there another request
        that already to refresh the token */
        this.addSubscriber((accessToken) => {
          errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
          return resolve(axios(errorResponse.config));
        });
      });
      if (!this.isAlreadyFetchingAccessToken) {
        this.isAlreadyFetchingAccessToken = true;
        const refreshToken = localStorage.getItem(process.env.REFRESH_TOKEN);
        try {
          let url;
          const userType = localStorage.getItem('USER_TYPE');
          if (userType === 'USER') {
            url = `${process.env.API_URL}/auth/refresh/user`;
          } else {
            url = `${process.env.API_URL}/auth/refresh/artist`;
          }
          const response = await axios({
            method: 'POST',
            url,
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          });
          if (response) {
            const { accessToken: newToken } = response.data;
            localStorage.setItem(process.env.TOKEN, newToken);
            this.isAlreadyFetchingAccessToken = false;
            this.service.defaults.headers.common.Authorization = `Bearer ${newToken}`;
            this.onAccessTokenFetched(newToken);
            return retryOriginalRequest;
          }
        } catch (err) {
          const { status } = err.response;
          if (status === 401) {
            localStorage.removeItem(process.env.TOKEN);
            localStorage.removeItem(process.env.REFRESH_TOKEN);
            const userType = localStorage.getItem('USER_TYPE');
            localStorage.removeItem('USER_TYPE');
            const pathArray = window.location.pathname.split('/');
            if (userType === 'USER' && !pathArray.includes('login')) {
              this.redirectTo(document, '/login');
            }
            if (userType === 'ARTIST' && !pathArray.includes('login-artist')) {
              this.redirectTo(document, '/login-artist');
            }
          }
          return Promise.reject(err);
        }
      }
      return Promise.reject(errorResponse.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers.forEach((callback) => callback(accessToken));
    this.subscribers = [];
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  redirectTo = (document, path) => {
    document.location = path;
  };

  get(endpoint, params, options) {
    return this.service.get(endpoint, { params }, options);
  }

  post(endpoint, payload, options) {
    return this.service.post(endpoint, payload, options);
  }

  patch(endpoint, payload) {
    return this.service.request({
      method: 'PATCH',
      url: endpoint,
      responseType: 'json',
      data: payload,
    });
  }

  put(endpoint, payload, options) {
    return this.service.put(endpoint, payload, options);
  }

  delete(endpoint, payload) {
    return this.service.request({
      method: 'DELETE',
      url: endpoint,
      responseType: 'json',
      data: payload,
    });
  }
}
const service = new Service();

export default service;
