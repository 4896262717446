import React, { useState } from 'react';
import useCustomQuery from '@Utils/useCustomQuery';
import { getAllRoles } from '@Apis/role';
import DataTableCustom from '@Components/DataTableCustom';
import ModalRole from '@Components/ModalRole';
import { Button } from 'react-bootstrap';
import { Plus } from 'react-feather';
import ModalDeleteRole from '@Components/ModalDeleteRole';
import columns from './RoleColumn';

function Role() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [detail, setDetail] = useState(null);

  const { data, refetch } = useCustomQuery({
    callApi: getAllRoles,
    isShowGlobalLoading: true,
  });

  const openModalDetail = (item) => {
    setIsOpenModal(true);
    setDetail(item);
  };

  const openModalDelete = (item) => {
    setIsOpenModalDelete(true);
    setDetail(item);
  };

  return (
    <div className="pt-3">
      <div className="filter-wrapper">
        <div className="d-flex gap-14 justify-content-end">
          <Button variant="danger" onClick={() => setIsOpenModal(true)}>
            <Plus size="14" />
            <span className="fs-14 fw-semibold">Thêm mới nhóm quyền</span>
          </Button>
        </div>
      </div>
      {data && (
        <DataTableCustom
          columns={columns({ openModalDetail, openModalDelete })}
          data={{ data }}
          noPagination
        />
      )}
      <ModalRole
        isOpen={isOpenModal}
        close={() => {
          setDetail(null);
          setIsOpenModal(false);
        }}
        refetch={refetch}
        detail={detail}
      />
      <ModalDeleteRole
        isOpen={isOpenModalDelete}
        refetch={refetch}
        close={() => {
          setDetail(null);
          setIsOpenModalDelete(false);
        }}
        detail={detail}
      />
    </div>
  );
}

Role.propTypes = {};

export default Role;
