import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import HeadingTitle from '@Components/HeadingTitle';
import ArtistCompact from '@Components/ArtistInfo/ArtistCompact';
import PeridDropdown from '@Components/PeriodDropdown';
import FlatPickrRange from '@Components/FlatPickrRange';
import { getFirstDateOfWeek, getLastDateOfWeek } from '@Utils/helper';
import { Container, Row, Col } from 'react-bootstrap';
import DashboardInfo from './components/DashboardInfo';
import TableMostSold from './components/TableMostSold';

function Dashboard() {
  const info = useSelector((state) => state.user.info);
  const [period, setPeriod] = useState('this_year');
  const firstDate = getFirstDateOfWeek();
  const lastDate = getLastDateOfWeek();
  const [dateRange, setDateRange] = useState([
    firstDate.format('YYYY-MM-DD'),
    lastDate.format('YYYY-MM-DD'),
  ]);
  return (
    <div className="content-wrapper">
      <HeadingTitle title={`${info?.name}, chúc bạn có một ngày tốt lành!`} />
      <div className="content-body">
        <div className="">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <p className="fw-bold fs-5">Tổng quan</p>
            <div className="d-flex align-items-center gap-14">
              <PeridDropdown period={period} setPeriod={setPeriod} />
              {period === 'optional' && (
                <FlatPickrRange
                  name="date-range"
                  placeholder="Chọn ngày"
                  date={dateRange}
                  setDate={setDateRange}
                  style={{ borderRadius: '100px' }}
                />
              )}
            </div>
          </div>
          {info && (
            <Container fluid className="px-0 mb-2">
              <Row className="g-0 gx-3">
                <Col md="3">
                  <ArtistCompact info={info} />
                </Col>
                <Col md="9">
                  <DashboardInfo period={period} dateRange={dateRange} />
                </Col>
              </Row>
            </Container>
          )}
          <TableMostSold period={period} dateRange={dateRange} />
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
