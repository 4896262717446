import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import Logo from '@Assets/images/authen/logo.png';
import LeftSideLogin from './LeftSideLogin';
import {
  SHOW_LOGIN,
  SHOW_RECOVERY_PASSWORD,
  SHOW_RECOVERY_PASSWORD_CONFIRM,
  SHOW_RECOVERY_NEW_PASSWORD,
} from '../constants';
import LeftSideNewPassword from './LeftSideNewPassword';
import LeftSideRecoveryPasswordConfirm from './LeftSideRecoveryPasswordConfirm';
import LeftSideRecoveryPassword from './LeftSideRecoveryPassword';
import { LeftSideWrapper, ImgLogo, Footer } from '../styled';

function LeftSide() {
  const [view, setView] = useState(SHOW_LOGIN);
  const [emailRecovery, setEmailRecovery] = useState();
  const [tokenReset, setTokenReset] = useState();

  return (
    <Col className="position-relative">
      <LeftSideWrapper>
        <div className="w-100">
          <ImgLogo src={Logo} alt="Logo" />
          {view === SHOW_LOGIN && <LeftSideLogin setView={setView} />}
          {view === SHOW_RECOVERY_PASSWORD && (
            <LeftSideRecoveryPassword setView={setView} setEmail={setEmailRecovery} />
          )}
          {view === SHOW_RECOVERY_PASSWORD_CONFIRM && (
            <LeftSideRecoveryPasswordConfirm
              setView={setView}
              email={emailRecovery}
              setTokenReset={setTokenReset}
            />
          )}
          {view === SHOW_RECOVERY_NEW_PASSWORD && (
            <LeftSideNewPassword setView={setView} tokenReset={tokenReset} />
          )}
        </div>
      </LeftSideWrapper>
      <Footer>
        <div className="d-flex align-items-center justify-content-between">
          <span className="text-grey">Developed by XClabs</span>
          <span className="text-grey">Copyright 2023</span>
        </div>
      </Footer>
    </Col>
  );
}

LeftSide.propTypes = {};

export default LeftSide;
