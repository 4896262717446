import React from 'react';
import PropTypes from 'prop-types';
import Frame from '@Assets/images/icons/frame.png';
import { Button } from 'react-bootstrap';
import { Upload } from 'react-feather';

function CustomUpload(props) {
  return (
    <div className="">
      <img src={Frame} alt="frame" width="35px" />
      <p className="text-grey my-3 fs-14">Upload file click here or Drag & Drop (.csv, .xls)</p>
      <Button variant="danger" type="button" className="btn-upload">
        <Upload />
        <span className="ms-2 fs-14 fw-semibold">Upload File</span>
      </Button>
    </div>
  );
}

CustomUpload.propTypes = {};

export default CustomUpload;
