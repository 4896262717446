import React from 'react';
import TableSortColumn from '@Components/DataTableCustom/TableSortColumn';
import { Edit3, Trash } from 'react-feather';
import { Nav } from 'react-bootstrap';

function columns({ openModalDetail, openModalDelete, ...props }) {
  return [
    {
      name: <TableSortColumn columnName="STT" defaultOrderBy="id" {...props} />,
      selector: (row) => row.id,
      width: '60px',
      center: true,
      compact: true,
    },
    {
      name: <TableSortColumn columnName="Tên" defaultOrderBy="name" {...props} />,
      selector: (row) => row.name,
    },
    {
      name: 'Số lượng AW',
      selector: (row) => row.artworksCount,
    },
    {
      name: '',
      right: true,
      selector: (row) => (
        <div className="d-flex align-items-center gap-8">
          <Nav.Link>
            <Edit3 color="#a70e13" size={18} onClick={() => openModalDetail(row)} />
          </Nav.Link>
          <Nav.Link>
            <Trash color="#a70e13" size={18} onClick={() => openModalDelete(row)} />
          </Nav.Link>
        </div>
      ),
    },
  ];
}

export default columns;
