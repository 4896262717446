import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function GlobalLoading(props) {
  const { loading, style } = props;
  let isGlobalLoading = useSelector((state) => state.ui.isGlobalLoading);
  if (loading !== undefined) {
    isGlobalLoading = loading;
  }
  return isGlobalLoading ? (
    <div className="loading-box" style={style}>
      <div className="spinner-list">
        <div className="spinner-grow spinner-grow-sm text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  ) : null;
}

GlobalLoading.propTypes = {
  loading: PropTypes.bool,
  style: PropTypes.object,
};

export default GlobalLoading;
