import styled from 'styled-components';

export const RevenueWrapper = styled.div`
  border: 1px dashed #d0d5dd;
  border-radius: 16px;
  width: 100%;

  .custom-upload {
    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 30px 0;
    outline: none !important;
  }
  .btn-upload {
    padding: 5px 25px;
  }
`;
