import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { showGlobalLoading, hideGlobalLoading } from '@Libs/slices/uiSlice';

export default function useCustomMutation({ callApi, isShowGlobalLoading = false }) {
  const res = useMutation((params) => callApi(params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    retry: 0,
    fetchPolicy: 'no-cache',
  });
  const dispatch = useDispatch();
  const { isLoading } = res;

  useEffect(() => {
    if (isShowGlobalLoading) {
      if (isLoading) {
        dispatch(showGlobalLoading());
      } else {
        dispatch(hideGlobalLoading());
      }
    }
  }, [isLoading]);
  return res;
}
