import styled from 'styled-components';

export const PermissionTableWrapper = styled.div`
  .header {
    .first-col {
      text-transform: uppercase;
    }
    color: #667085;
    font-size: 16px;
    font-weight: 600;
  }
`;
