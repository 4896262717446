import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/artist/statistics/tables`;

export const mostSoldArtworksPeriod = async ({ fromDate, toDate }) => {
  const res = await axiosService.get(`${url}/most-sold-artworks/date-period`, {
    fromDate,
    toDate,
  });
  return res.data;
};

export const mostSoldArtworksThisWeek = async () => {
  const res = await axiosService.get(`${url}/most-sold-artworks/this-week`);
  return res.data;
};

export const mostSoldArtworksThisMonth = async () => {
  const res = await axiosService.get(`${url}/most-sold-artworks/this-month`);
  return res.data;
};

export const mostSoldArtworksThisYear = async () => {
  const res = await axiosService.get(`${url}/most-sold-artworks/this-year`);
  return res.data;
};
