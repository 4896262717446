/* eslint-disable no-confusing-arrow */
import React from 'react';
import Avatar from '@Assets/images/avatar.png';
import NoImage from '@Assets/images/no-image.png';
import ArtistStatusTable from '@Components/ArtistStatusTable';
import dayjs from 'dayjs';
import TableSortColumn from '@Components/DataTableCustom/TableSortColumn';
import { formatDecimal, formatVND, googleImageUrl } from '@Utils/helper';

function columns({ ...props }) {
  const { columnsShow } = props;
  let selectedColumns = [];
  if (columnsShow[0]) {
    selectedColumns = columnsShow[0].selectedOptions;
  }
  const initialColumns = [
    {
      name: <TableSortColumn columnName="STT" defaultOrderBy="id" {...props} />,
      selector: (row, index) => row.id,
      width: '60px',
      center: true,
      compact: true,
      key: 'id',
    },
    {
      name: <TableSortColumn columnName="Nghệ danh" defaultOrderBy="name" {...props} />,
      width: '217px',
      selector: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="avatar-table-wrapper">
              <img
                src={
                  row.profileImageGoogleFileId
                    ? googleImageUrl(row.profileImageGoogleFileId)
                    : NoImage
                }
                alt={row.name}
                width="34px"
                data-tag="allowRowEvents"
              />
            </div>

            <div className="ms-1">
              <b className="d-block fs-14 fw-bold" data-tag="allowRowEvents">
                {row.stageName}
              </b>
              <span className="fs-12" data-tag="allowRowEvents">
                {row.email}
              </span>
            </div>
          </div>
        );
      },
      key: 'name',
    },
    {
      name: 'Mã Artist',
      selector: (row) => row.systemCode,
      key: 'systemCode',
    },
    {
      name: <TableSortColumn columnName="Trạng thái" defaultOrderBy="status" {...props} />,
      selector: (row) => {
        return <ArtistStatusTable status={row.status} />;
      },
      key: 'status',
    },
    {
      name: (
        <TableSortColumn
          columnName="Collection đang bán"
          defaultOrderBy="collectionsCount"
          {...props}
        />
      ),
      selector: (row) => row.collectionsCount,
      center: true,
      key: 'collectionsCount',
    },
    {
      name: <TableSortColumn columnName="AW đang bán" defaultOrderBy="artworksCount" {...props} />,
      selector: (row) => row.artworksCount,
      center: true,
      key: 'artworksCount',
    },
    {
      name: 'Doanh thu TC',
      selector: (row) => formatVND(row.totalRevenueAfterDiscount),
      key: 'totalRevenueAfterDiscount',
    },
    {
      name: 'Tỉ trọng DT',
      selector: (row) => formatDecimal({ value: row.revenueToTotalPercentage }),
      key: 'revenueToTotalPercentage',
    },
    {
      name: 'LN Artist nhận',
      width: '120px',
      selector: (row) => formatVND(row.totalArtistProfit),
      key: 'totalArtistProfit',
    },
    {
      name: 'Tỉ trọng LN',
      selector: (row) => `${formatDecimal({ value: row.profitToTotalPercentage })} %`,
      key: 'profitToTotalPercentage',
    },
    {
      name: 'Ký HĐ đầu tiên',
      selector: (row) =>
        row.firstContractSigningDate
          ? dayjs(row.firstContractSigningDate).format('YYYY-MM-DD')
          : '',
      width: '120px',
      compact: true,
      key: 'firstContractSigningDate',
    },
    {
      name: (
        <TableSortColumn
          columnName="Liên hệ gần nhất"
          defaultOrderBy="mostRecentContactedEventDate"
          {...props}
        />
      ),
      selector: (row) =>
        row.mostRecentContactedEventDate
          ? dayjs(row.mostRecentContactedEventDate).format('YYYY-MM-DD')
          : '',
      width: '135px',
      compact: true,
      key: 'mostRecentContactedEventDate',
    },
    {
      name: (
        <TableSortColumn
          columnName="Ký HĐ gần nhất"
          defaultOrderBy="mostRecentContractSigningDate"
          {...props}
        />
      ),
      selector: (row) =>
        row.mostRecentContractSigningDate
          ? dayjs(row.mostRecentContractSigningDate).format('YYYY-MM-DD')
          : '',
      width: '120px',
      compact: true,
      key: 'mostRecentContractSigningDate',
    },
  ];
  if (selectedColumns.length === 0) {
    return initialColumns;
  }
  const columns = initialColumns.reduce((acc, column) => {
    const checkShow = selectedColumns.find((item) => item.value === column.key);
    if (checkShow) {
      acc.push(column);
    }
    return acc;
  }, []);
  return columns;
}
export default columns;
