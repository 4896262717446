import { checkIsEmail } from '@Utils/helper';

export const INFOMATION_INPUT = ({ data }) => {
  return [
    {
      row: { className: 'col-12' },
      items: [
        {
          name: 'profileImage',
          type: 'avatar',
          accept: 'image/*',
          defaultValue: data ? data.profileImageGoogleFileId : '',
        },
      ],
    },
    {
      row: { className: 'col-12' },
      items: [
        {
          label: 'Nghệ danh',
          validate: { required: true },
          name: 'stageName',
          type: 'text',
          defaultValue: data?.stageName,
          disabled: true,
        },
        {
          label: 'Họ tên thật',
          validate: { required: true },
          name: 'name',
          type: 'text',
          defaultValue: data?.name,
          disabled: true,
        },
      ],
    },
    {
      row: { className: 'col-12', id: 2 },
      items: [
        {
          label: 'Email',
          validate: {
            required: true,
            validate: (email) => {
              const isEmail = checkIsEmail(email);
              if (!isEmail) {
                return 'Email không đúng định dạng';
              }
            },
          },
          name: 'email',
          type: 'text',
          defaultValue: data?.email,
        },
        {
          label: 'Phone',
          validate: { required: true },
          name: 'contactNumber',
          type: 'phone',
          defaultValue: data?.contactNumber,
        },
      ],
    },
    {
      row: { className: 'col-12' },
      items: [
        {
          label: 'Địa chỉ',
          name: 'address',
          type: 'text',
          defaultValue: data?.address,
        },
      ],
    },
    {
      row: { className: 'col-12' },
      items: [
        {
          label: 'Link portfolio',
          name: 'portfolioUrl',
          type: 'text',
          defaultValue: data?.portfolioUrl,
        },
        {
          label: 'Link facebook',
          name: 'facebookUrl',
          type: 'text',
          defaultValue: data?.facebookUrl,
        },
      ],
    },
    {
      row: { className: 'col-6' },
      items: [
        {
          label: 'Link Instagram',
          name: 'instagramUrl',
          type: 'text',
          defaultValue: data?.instagramUrl,
        },
      ],
    },
  ];
};

export const BANK_INPUT = ({ data }) => {
  return [
    {
      row: { heading: 'Tài khoản 1', className: 'col-12' },
      items: [
        {
          label: 'Tên chủ tài khoản',
          name: 'bankAccounts0[accountHolder]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[0]?.accountHolder : '',
        },
        {
          label: 'Số tài khoản',
          name: 'bankAccounts0[accountNumber]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[0]?.accountNumber : '',
        },
        {
          label: 'Ngân hàng',
          name: 'bankAccounts0[bankName]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[0]?.bankName : '',
        },
      ],
    },
    {
      row: { heading: 'Tài khoản 2', className: 'col-12' },
      items: [
        {
          label: 'Tên chủ tài khoản',
          name: 'bankAccounts1[accountHolder]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[1]?.accountHolder : '',
        },
        {
          label: 'Số tài khoản',
          name: 'bankAccounts1[accountNumber]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[1]?.accountNumber : '',
        },
        {
          label: 'Ngân hàng',
          name: 'bankAccounts1[bankName]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[1]?.bankName : '',
        },
      ],
    },
  ];
};

export const ACCOUNT_INFO = ({ data }) => {
  return [
    {
      row: { className: 'col-12', id: 1 },
      items: [
        {
          label: 'Tên đăng nhập',
          name: 'email',
          type: 'email',
          disabled: true,
          defaultValue: data?.email,
        },
        {
          label: 'Mật khẩu',
          name: 'password',
          type: 'text',
          validate: {
            validate: (password) => {
              if (password && password.length > 0) {
                const minLength = password.length >= 8;
                const leastOneUppercase = /.*[A-Z].*/.test(password);
                const leastOneNumber = /.*[0-9].*/.test(password);
                if (!minLength || !leastOneUppercase || !leastOneNumber) {
                  return 'Mật khẩu phải có tối thiểu 8 kí tự, có ít nhất 1 kí tự viết hoa và 1 chữ số';
                }
              }
            },
          },
        },
      ],
    },
  ];
};
