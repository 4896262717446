import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function PageForbidden() {
  const user = useSelector((state) => state.user);
  return user.info ? (
    <>
      <div className="d-flex justify-content-center pt-4">
        <img src="/images/logo.png" width="166px" alt="logo" />
      </div>
      <div className="container">
        <div className="content-public-wrapper">
          <div className="d-flex justify-content-center align-items-center pt-5">
            <div className="row">
              <div className="col-6 d-flex align-items-center justify-content-end">
                <div style={{ width: '80%' }}>
                  <p className="fs-3 text-grey">
                    <b style={{ fontSize: '60px' }}>403</b>
                    <span className="fw-normal">Access denied.</span>
                  </p>
                  <p className="fs-3 pt-4 fw-normal text-grey lh-sm">
                    You don’t have permission to access this page.
                  </p>
                </div>
              </div>
              <div className="col-6">
                <img src="/images/forbidden.png" width="500" alt="page not found" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    ''
  );
}

PageForbidden.propTypes = {};

export default PageForbidden;
