import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { InputGroup, Form } from 'react-bootstrap';
import Calender from '@Assets/images/icons/calender.png';
import { FlatPickrWrapper } from './styled';

function FlatPickrDate({ placeholder, name, date, setDate, className, ...props }) {
  const { enableTime = false } = props;
  const handleSetDate = (date) => {
    setDate(date, enableTime);
  };
  return (
    <FlatPickrWrapper>
      <Flatpickr
        options={{
          dateFormat: enableTime ? 'd/m/Y H:i' : 'd/m/Y',
          time_24hr: true,
          static: true,
          allowInput: true,
        }}
        name={name}
        data-enable-time={enableTime}
        value={date}
        onChange={([date]) => {
          handleSetDate(date);
        }}
        render={({ defaultValue, value, ...props }, ref) => {
          return (
            <Form.Group>
              <InputGroup className="input-group-with-icon">
                <input
                  {...props}
                  className="form-control"
                  placeholder={placeholder}
                  defaultValue={defaultValue}
                  ref={ref}
                />
                <InputGroup.Text>
                  <img src={Calender} alt={`${name}-right`} />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          );
        }}
      />
    </FlatPickrWrapper>
  );
}

FlatPickrDate.propTypes = {
  name: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  setDate: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  enableTime: PropTypes.bool,
};

export default FlatPickrDate;
