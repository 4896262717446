import React from 'react';
import AuthenRightSide from '@Components/AuthenRightSide';
import LeftSide from './components/LeftSide';

function SignIn(props) {
  return (
    <div className="container-flud authen">
      <div className="row g-0">
        <LeftSide />
        <AuthenRightSide />
      </div>
    </div>
  );
}

SignIn.propTypes = {};

export default SignIn;
