import styled from 'styled-components';

export const ImageInputWrapper = styled.div`
  position: relative;
  border: 1px dashed #a70e13;
  border-radius: 8px;
  overflow: hidden;
  max-width: 240px;

  .nav-link {
    padding: 0 !important;
    justify-content: center !important;
  }
  .upload-file {
    margin-top: 4px;
    padding-bottom: 100%;
    .upload-file-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
  .clear-button {
    position: absolute;
    z-index: 2;
    top: 4px;
    right: 4px;
    width: 24px;
  }
  .image-upload-wrapper {
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;
    .image-preview {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .file-wrapper {
    height: 100%;
    position: relative;
    padding-bottom: 100%;
    .file-wrapper-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .file-preview {
        width: 40px;
      }
    }
  }
`;
