import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '@Libs/slices/userSlice';
import axiosService from '@Libs/axiosService';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthenWrapper(props) {
  const { children } = props;
  const info = useSelector((state) => state.user.info);
  const token = localStorage.getItem(process.env.TOKEN);
  const dispatch = useDispatch();
  if (token) {
    axiosService.setHeader('Authorization', `Bearer ${token}`);
  }

  useEffect(() => {
    if (!info && token) {
      dispatch(getProfile());
    }
  }, [info, token]);
  return children;
}

AuthenWrapper.propTypes = {
  children: PropTypes.object,
};

export default AuthenWrapper;
