import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DashboardInfoWrapper } from './styled';
import Summary from './Summary';
import ProfitChart from './ProfitChart';

function DashboardInfo({ period, dateRange }) {
  return (
    <DashboardInfoWrapper>
      <Summary period={period} dateRange={dateRange} />
      <ProfitChart period={period} dateRange={dateRange} />
    </DashboardInfoWrapper>
  );
}

DashboardInfo.propTypes = { period: PropTypes.string, dateRange: PropTypes.array };

export default DashboardInfo;
