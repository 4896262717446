import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ARTWORK_STATUS } from '@Constants/artworkConstants';
import { Dropdown, Button, Nav } from 'react-bootstrap';
import { ChevronDown, Plus } from 'react-feather';
import { massUpdateStatus } from '@Apis/artwork';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import ButtonConfirm from '@Components/ButtonConfirm';

function ControlStatus({ items, refetch, clearSelectedRow, setAction }) {
  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: massUpdateStatus,
    isShowGlobalLoading: true,
  });

  const [status, setStatus] = useState('');

  const handleChangeStatus = () => {
    const ids = items.map((item) => item.id);
    mutate({
      ids,
      status: status.value,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Đổi trạng thái thành công');
      refetch();
      clearSelectedRow();
      setAction();
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  return (
    <div className="ms-3 d-flex">
      <Dropdown>
        <Dropdown.Toggle className="fs-14" style={{ width: '180px' }}>
          {status ? status.label : 'Chọn trạng thái'}
          <ChevronDown size={18} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setStatus()}>Chọn trạng thái</Dropdown.Item>
          {ARTWORK_STATUS.map((item) => {
            if (item.value) {
              return (
                <Dropdown.Item onClick={() => setStatus(item)} key={item.value}>
                  {item.label}
                </Dropdown.Item>
              );
            }
          })}
        </Dropdown.Menu>
      </Dropdown>
      <ButtonConfirm
        variant="danger"
        disabled={!status}
        className="ms-3"
        onClick={() => handleChangeStatus()}
        isLoading={isLoading}
        name="Xác nhận"
      />
    </div>
  );
}

ControlStatus.propTypes = {
  items: PropTypes.array,
  refetch: PropTypes.func,
  clearSelectedRow: PropTypes.func,
  setAction: PropTypes.func,
};

export default ControlStatus;
