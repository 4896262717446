import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/artworks`;

export const getArtwork = async (params) => {
  const res = await axiosService.get(url, params);
  return res.data;
};

export const createArtwork = async ({ formData }) => {
  const res = await axiosService.post(url, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const artworkDetail = async ({ id }) => {
  const res = await axiosService.get(`${url}/${id}`);
  return res.data;
};

export const updateArtwork = async ({ id, formData }) => {
  const res = await axiosService.post(`${url}/${id}`, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const massUpdateStatus = async ({ ids, status }) => {
  const res = await axiosService.put(`${url}/updateStatuses`, { ids, status });
  return res.data;
};
export const massUpdateSaleOpeningDate = async ({ ids, saleOpeningDate }) => {
  const res = await axiosService.put(`${url}/updateSaleOpeningDates`, { ids, saleOpeningDate });
  return res.data;
};

export const importArtwork = async ({ formData }) => {
  const res = await axiosService.post(`${url}/importFromExcel`, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const deleteArtwork = async ({ id }) => {
  const res = await axiosService.delete(`${url}/${id}`);
  return res.data;
};

export const deletePreviewImage = async ({ id }) => {
  const res = await axiosService.put(`${url}/${id}/removePreviewImage`);
  return res.data;
};
