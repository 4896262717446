import React from 'react';
import PropTypes from 'prop-types';
import { SortColumnWrapper, ArrowWrapper } from './styled';

function TableSortColumn({ columnName, order, orderBy, defaultOrderBy, onChangeSort }) {
  const handleSort = () => {
    let newOrder;
    if (orderBy !== defaultOrderBy) {
      onChangeSort({
        order: 'DESC',
        orderBy: defaultOrderBy,
      });
      return;
    }
    if (order === 'DESC') {
      newOrder = 'ASC';
    } else if (order === 'ASC') {
      newOrder = '';
    } else {
      newOrder = 'DESC';
    }
    onChangeSort({
      order: newOrder,
      orderBy: defaultOrderBy,
    });
  };
  const checkArrowActive = (type) => {
    if (order === type && defaultOrderBy === orderBy) {
      return 'active';
    }
    return '';
  };
  return (
    <SortColumnWrapper>
      <div
        className="pe-1 font-size-12 text-center white-space-nowrap text-wrap"
        onClick={handleSort}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
      >
        {columnName}
      </div>
      <ArrowWrapper>
        <i aria-hidden="true" className={`icon-arrow icon-sort-asc ${checkArrowActive('ASC')}`} />
        <i aria-hidden="true" className={`icon-arrow icon-sort-desc ${checkArrowActive('DESC')}`} />
      </ArrowWrapper>
    </SortColumnWrapper>
  );
}

TableSortColumn.propTypes = {
  columnName: PropTypes.string,
  order: PropTypes.string,
  defaultOrderBy: PropTypes.string,
  orderBy: PropTypes.string,
  onChangeSort: PropTypes.func,
};

export default TableSortColumn;
