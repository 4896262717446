import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import Background from '@Assets/images/authen/background.png';
import { ImageRightSide, ImageWrapper } from './styled';

function AuthenRightSide(props) {
  return (
    <Col>
      <ImageWrapper>
        <ImageRightSide src={Background} alt="Background" />
      </ImageWrapper>
    </Col>
  );
}

AuthenRightSide.propTypes = {};

export default AuthenRightSide;
