import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/users`;
export const info = async () => {
  const res = await axiosService.get(`${url}/me`);
  return res.data;
};

export const getUsers = async (params) => {
  const res = await axiosService.get(url, params);
  return res.data;
};

export const detailUsers = async ({ id }) => {
  const res = await axiosService.get(`${url}/${id}`);
  return res.data;
};

export const updateUser = async ({ id, formData }) => {
  const res = await axiosService.post(`${url}/${id}`, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const createUser = async ({ formData }) => {
  const res = await axiosService.post(url, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const deleteUsers = async ({ id }) => {
  const res = await axiosService.delete(`${url}/${id}`);
  return res.data;
};
