import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { X } from 'react-feather';
import ButtonConfirm from '@Components/ButtonConfirm';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { deleteRole } from '@Apis/role';
import { deleteLog } from '@Apis/artistLog';

function ModalDeleteLog({ isOpen, close, refetch, detail, artistId }) {
  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: deleteLog,
    isShowGlobalLoading: true,
  });

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Xoá ghi chú thành công');
      close();
      refetch();
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered show={isOpen} onHide={close}>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="fs-5 fw-bold text-grey">Xác nhận</p>
          <X size={18} onClick={close} />
        </div>
        <div className="fs-6">
          Bạn muốn xóa ghi chú<b>{`"${detail?.name}"`}</b> ?
        </div>
        <div className="d-flex justify-content-start gap-8 mt-4">
          <ButtonConfirm
            variant="danger"
            name="Xoá"
            type="submit"
            className="fs-14 px-3"
            loading={isLoading}
            onClick={() => mutate({ id: detail.id, artistId })}
          />
          <ButtonConfirm
            variant="outline-danger"
            className="fs-14 px-3"
            name="Huỷ"
            onClick={close}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

ModalDeleteLog.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  refetch: PropTypes.func,
  detail: PropTypes.object,
  artistId: PropTypes.number,
};

export default ModalDeleteLog;
