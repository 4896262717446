import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@Assets/images/artist-avatar.png';
import FacebookIcon from '@Assets/images/icons/facebook.png';
import InstagramIcon from '@Assets/images/icons/instagram.png';
import PortfolioIcon from '@Assets/images/icons/web.png';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Edit3 } from 'react-feather';
import { googleImageUrl } from '@Utils/helper';
import NoImage from '@Assets/images/no-image.png';
import { ArtistWrapper } from './styled';

function ArtistCompact({ info }) {
  const navigate = useNavigate();
  return (
    <ArtistWrapper>
      <div className="header-artist">
        <div className="artist-avatar-wrapper">
          <img
            src={
              info.profileImageGoogleFileId
                ? googleImageUrl(info.profileImageGoogleFileId)
                : NoImage
            }
            alt="avatar"
            className="artist-avatar mb-2"
          />
        </div>

        <p className="fs-18 fw-bold">{info.stageName}</p>
        <p className="full-name pt-1 pb-2">{info.name}</p>
        <div className="artist-social">
          <a href={info.facebookUrl} target="_blank" rel="noreferrer">
            <img src={FacebookIcon} alt="Facebook" />
          </a>
          <a href={info.instagramUrl} target="_blank" rel="noreferrer">
            <img src={InstagramIcon} alt="Instagram" />
          </a>
          <a href={info.portfolioUrl} target="_blank" rel="noreferrer">
            <img src={PortfolioIcon} alt="Portfolio" />
          </a>
        </div>
        <Button variant="outline-danger btn-edit" onClick={() => navigate('/dashboard/artist')}>
          <Edit3 size={14} />
          <span>Sửa thông tin</span>
        </Button>
      </div>
      <div className="body-artist">
        <div className="body-artist-item">
          <p className="label">Email</p>
          <p className="fs-14 fw-semibold">{info.email}</p>
        </div>
        <div className="body-artist-item">
          <p className="label">Điện thoại</p>
          <p className="fs-14 fw-semibold">{info.contactNumber}</p>
        </div>
        <div className="body-artist-item">
          <p className="label">Địa chỉ</p>
          <p className="fs-14 fw-semibold">{info.address}</p>
        </div>
        <div className="body-artist-item">
          <p className="label">Số tài khoản</p>
          {info.bankAccounts.map((bankAccount, index) => {
            if (index < 2) {
              return (
                <p className="fs-14 fw-semibold" key={bankAccount.id}>
                  {`${bankAccount.accountNumber} - ${bankAccount.bankName} - ${bankAccount.accountHolder}`}
                </p>
              );
            }
            return '';
          })}
        </div>
      </div>
    </ArtistWrapper>
  );
}

ArtistCompact.propTypes = {
  info: PropTypes.object,
};

export default ArtistCompact;
