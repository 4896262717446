import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  artistProfitPeriod,
  artistProfitThisWeek,
  artistProfitThisMonth,
  artistProfitThisYear,
} from '@Apis/statisticChart';
import useDashboardData from '@Utils/useDashboardData';
import { getDatesFromPeriod } from '@Utils/helper';
import dayjs from 'dayjs';
import LineChart from '@Components/LineChart';

function ProfitChart({ period, dateRange, artistId }) {
  const [dataChart, setDataChart] = useState([]);
  const objectFunc = {
    this_year: artistProfitThisYear,
    this_week: artistProfitThisWeek,
    this_month: artistProfitThisMonth,
    optional: artistProfitPeriod,
  };
  const func = objectFunc[period];
  const data = useDashboardData({ func, dateRange, artistId });
  useEffect(() => {
    if (data) {
      const { currentPeriodResult, previousPeriodResult, currentPeriod: periodData } = data;
      const dataProcessed = processDataForChart({
        currentPeriodResult,
        previousPeriodResult,
        period,
        periodData,
      });
      setDataChart(dataProcessed);
    }
  }, [data]);
  const processDataForChart = ({
    currentPeriodResult = [],
    previousPeriodResult = [],
    period,
    periodData,
  }) => {
    if (period === 'this_year') {
      const categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
      const dataProcessed = categories.map((item) => {
        const month = dayjs().month(item);
        const monthFormat = month.format('YYYY-MM');
        const current = currentPeriodResult.find(
          (dataMonth) => dataMonth.timePoint === monthFormat,
        );
        const previos = previousPeriodResult.find(
          (dataMonth) => dataMonth.timePoint === monthFormat,
        );
        return {
          date: month.format('MMM'),
          current: current ? current.value : 0,
          previos: previos ? previos.value : 0,
        };
      });
      return dataProcessed;
    }
    const { fromDate, toDate } = periodData;
    const categories = getDatesFromPeriod({ fromDate, toDate });
    const dataProcessed = categories.map((item) => {
      const date = dayjs(item);
      const dateFormat = date.format('YYYY-MM');
      const current = currentPeriodResult.find((dataMonth) => dataMonth.timePoint === dateFormat);
      const previos = previousPeriodResult.find((dataMonth) => dataMonth.timePoint === dateFormat);
      return {
        date: date.format('DD/MM'),
        current: current ? current.value : 0,
        previos: previos ? previos.value : 0,
      };
    });
    return dataProcessed;
  };

  return (
    <div className="pt-3">
      <LineChart
        data={dataChart}
        name="Lợi nhuận Artist nhận"
        seriesFirst={{
          property: 'current',
          tooltipLabel: 'Artist đã hợp tác',
          color: '#0062ff',
          name: 'Năm nay',
        }}
        seriesSecond={{
          property: 'previos',
          tooltipLabel: 'Artist đang bán',
          color: '#F5B544',
          name: 'Năm trước',
        }}
        period={period}
      />
    </div>
  );
}

ProfitChart.propTypes = {
  period: PropTypes.string,
  dateRange: PropTypes.array,
  artistId: PropTypes.number,
};

export default ProfitChart;
