import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { period as summaryPeriod, thisYear, thisMonth, thisWeek } from '@Apis/statistic';
import useDashboardData from '@Utils/useDashboardData';
import { Container, Row, Col } from 'react-bootstrap';
import { formatVND, calcPeriodTrend } from '@Utils/helper';
import SummaryTrend from '@Components/SummaryTrend';
import { SummaryWrapper } from './styled';

function Summary({ period, dateRange, artistId }) {
  const [summaryData, setSummaryData] = useState({});
  const [percent, setPercent] = useState(0);

  const objectFunc = {
    this_year: thisYear,
    this_week: thisWeek,
    this_month: thisMonth,
    optional: summaryPeriod,
  };
  const func = objectFunc[period];
  const data = useDashboardData({ func, dateRange, artistId });

  useEffect(() => {
    if (data) {
      setSummaryData(data.currentPeriodNumbers);
      const percent = calcPeriodTrend({
        data: data.currentPeriodNumbers,
        property: 'artistProfit',
      });
      setPercent(percent);
    }
  }, [data]);

  return (
    <SummaryWrapper>
      <Container fluid className="pt-3 px-0">
        <Row className="g-0 gx-3">
          <Col md={6}>
            <div className="summary-item d-flex justify-content-between">
              <div>
                <p className="fs-14">Doanh thu</p>
                <p className="fs-5 fw-bold">{formatVND(summaryData.artistProfit)}</p>
              </div>
              {period !== 'optional' && <SummaryTrend percent={percent} period={period} />}
            </div>
          </Col>
          <Col md={3}>
            <div className="summary-item">
              <p className="fs-14">Tỉ trọng doanh thu / Tổng</p>
              <p className="fs-5 fw-bold">{formatVND(summaryData.profitRatio)} %</p>
            </div>
          </Col>
          <Col md={3}>
            <div className="summary-item">
              <p className="fs-14">Tỉ trọng lợi nhuận / Tổng</p>
              <p className="fs-5 fw-bold">{formatVND(summaryData.revenueRatio)} %</p>
            </div>
          </Col>
        </Row>
      </Container>
    </SummaryWrapper>
  );
}

Summary.propTypes = {
  period: PropTypes.string,
  dateRange: PropTypes.array,
  artistId: PropTypes.number,
};

export default Summary;
