import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'react-bootstrap';
import SearchInput from '@Components/SearchInput';
import { Plus, ChevronDown } from 'react-feather';
import { USER_STATUS } from '@Constants/userConstants';
import { usePermissions } from '@Utils/usePermissions';

function StaffFilter({ setFilter, pageSize, page, navigate }) {
  const [search, setSearch] = useState();
  const [status, setStatus] = useState('all');

  useEffect(() => {
    const filter = {};
    if (search) {
      filter.name = search;
    }
    if (status !== 'all') {
      filter.enabled = status === 'active' ? 1 : 0;
    }
    setFilter({
      ...filter,
      page,
      pageSize,
      order: 'DESC',
      orderBy: 'id',
    });
  }, [page, pageSize, search, status]);

  const getStatusSelected = () => {
    const statusActive = USER_STATUS.find((item) => item.value === status);
    return statusActive.label;
  };
  const showButtonAddnew = usePermissions({ roleName: 'user', permission: 'create' });
  return (
    <div className="filter-wrapper mt-3">
      <div className="d-flex gap-14 justify-content-between">
        <div className="d-flex gap-14">
          <SearchInput
            placeholder="Tìm kiếm nhân viên"
            setSearch={setSearch}
            defaultValue={search}
          />
          <Dropdown>
            <Dropdown.Toggle className="fs-14" style={{ width: '180px' }}>
              {getStatusSelected()}
              <ChevronDown size={18} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {USER_STATUS.map((status) => (
                <Dropdown.Item onClick={() => setStatus(status.value)} key={status.label}>
                  {status.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {showButtonAddnew && (
          <Button variant="danger" onClick={() => navigate('/user/addnew')}>
            <Plus size="14" />
            <span className="fs-14 fw-semibold">Thêm mới nhân viên</span>
          </Button>
        )}
      </div>
    </div>
  );
}

StaffFilter.propTypes = {
  setFilter: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  navigate: PropTypes.func,
};

export default StaffFilter;
