import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FormInput from '@Components/FormInput';
import ButtonConfirm from '@Components/ButtonConfirm';
import { createArtwork, updateArtwork, artworkDetail, deletePreviewImage } from '@Apis/artwork';
import useCustomMutation from '@Utils/useCustomMuation';
import useCustomQuery from '@Utils/useCustomQuery';
import { getAllArtist } from '@Apis/artist';
import { X } from 'react-feather';
import { getAllArtworkCollection } from '@Apis/artworkCollection';
import { processTagMultiOption } from '@Utils/helper';
import { getAllArtworkTag } from '@Apis/artworkTag';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { getAllArtworkProductLine } from '@Apis/artworkProductLine';

function ModalArtwork({ isOpen, close, refetch, detailId, inputs, artistIdDefault }) {
  const [detail, setDetail] = useState(null);
  const { data } = useCustomQuery({
    callApi: artworkDetail,
    enabled: !!detailId,
    params: {
      id: detailId,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    const formData = new FormData();
    Object.keys(data).map((key) => {
      if (data[key] && key !== 'tagIds' && key !== 'productLineIds') {
        formData.append(key, data[key]);
      }
      if (key === 'tagIds' && data.tagIds.length > 0) {
        data.tagIds.forEach((id) => formData.append('tagIds[]', id));
      }
      if (key === 'productLineIds' && data.productLineIds.length > 0) {
        data.productLineIds.forEach((id) => formData.append('productLineIds[]', id));
      }
    });
    if (detail) {
      mutateUpdate({ id: detail.id, formData });
    } else {
      mutate({ formData });
    }
  };

  useEffect(() => {
    if (data) {
      const { artwork } = data;
      setDetail(artwork);
    } else {
      setDetail(null);
    }
  }, [data, detailId]);

  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: createArtwork,
    isShowGlobalLoading: true,
  });

  const { mutate: mutateDeletePreviewImage, isSuccess: isSuccessDelete } = useCustomMutation({
    callApi: deletePreviewImage,
    isShowGlobalLoading: false,
  });

  const {
    mutate: mutateUpdate,
    isSuccess: isSuccessUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
  } = useCustomMutation({
    callApi: updateArtwork,
    isShowGlobalLoading: true,
  });

  const { data: artists } = useCustomQuery({
    callApi: getAllArtist,
    isShowGlobalLoading: false,
  });

  const { data: collections } = useCustomQuery({
    callApi: getAllArtworkCollection,
    isShowGlobalLoading: false,
  });

  const { data: tags } = useCustomQuery({
    callApi: getAllArtworkTag,
    isShowGlobalLoading: false,
  });

  const { data: productLines } = useCustomQuery({
    callApi: getAllArtworkProductLine,
    isShowGlobalLoading: false,
  });

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Tạo artwork thành công');
      close();
      refetch();
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isSuccessUpdate) {
      toastSuccess('Sửa artwork thành công');
      close();
      refetch();
    }
    if (isErrorUpdate) {
      toastError(errorUpdate);
    }
  }, [isSuccessUpdate, isErrorUpdate]);

  const previewImage = watch('previewImage');
  useEffect(() => {
    if (previewImage === null) {
      mutateDeletePreviewImage({ id: detail.id });
    }
  }, [previewImage]);

  useEffect(() => {
    if (isSuccessDelete) {
      refetch();
    }
  }, [isSuccessDelete]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isOpen}
      onHide={close}
    >
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between">
          <p className="fs-5 fw-bold">{detail ? 'Sửa Artwork' : 'Thêm mới Artwork'}</p>
          <X size={18} onClick={close} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            inputsForm={inputs({
              data: detail,
              artists: processTagMultiOption(artists),
              collections: processTagMultiOption(collections),
              tags: processTagMultiOption(tags),
              artistIdDefault,
              productLines: processTagMultiOption(productLines),
            })}
            register={register}
            errors={errors}
            setValueForm={setValueForm}
            watch={watch}
          />
          <div className="d-flex justify-content-center gap-8">
            <ButtonConfirm
              variant="danger"
              className="fs-14 px-3"
              name="Lưu lại"
              type="submit"
              loading={isLoading}
            />
            <ButtonConfirm
              variant="outline-danger"
              className="fs-14 px-3"
              name="Huỷ"
              onClick={close}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

ModalArtwork.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  refetch: PropTypes.func,
  detailId: PropTypes.number,
  inputs: PropTypes.func,
  artistIdDefault: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
};

export default ModalArtwork;
