import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  artworkCountPeriod,
  artworkCountThisWeek,
  artworkCountThisMonth,
  artworkCountThisYear,
} from '@Apis/statisticChart';
import dayjs from 'dayjs';
import BasicColumnChart from '@Components/BasicColumnChart';
import { getDatesFromPeriod } from '@Utils/helper';
import useDashboardData from '@Utils/useDashboardData';

function ColumnChartArtwork({ period, dateRange }) {
  const [dataChart, setDataChart] = useState([]);
  const objectFunc = {
    this_year: artworkCountThisYear,
    this_week: artworkCountThisWeek,
    this_month: artworkCountThisMonth,
    optional: artworkCountPeriod,
  };
  const func = objectFunc[period];
  const data = useDashboardData({ func, dateRange });

  useEffect(() => {
    if (data) {
      const { pendingArtworksResult, sellingArtworksResult, period: periodData } = data;
      const dataProcessed = processDataForChart({
        pendingArtworksResult,
        sellingArtworksResult,
        period,
        periodData,
      });
      setDataChart(dataProcessed);
    }
  }, [data]);

  const processDataForChart = ({
    sellingArtworksResult,
    pendingArtworksResult,
    period,
    periodData,
  }) => {
    if (period === 'this_year') {
      const categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
      const dataProcessed = categories.map((item) => {
        const month = dayjs().month(item);
        const monthFormat = month.format('YYYY-MM');
        const sellingByMonth = sellingArtworksResult.find(
          (dataMonth) => dataMonth.timePoint === monthFormat,
        );
        const pendingByMonth = pendingArtworksResult.find(
          (dataMonth) => dataMonth.timePoint === monthFormat,
        );
        return {
          date: month.format('MMM'),
          selling: sellingByMonth ? sellingByMonth.value : 0,
          pending: pendingByMonth ? pendingByMonth.value : 0,
        };
      });
      return dataProcessed;
    }
    const { fromDate, toDate } = periodData;
    const categories = getDatesFromPeriod({ fromDate, toDate });
    const dataProcessed = categories.map((item) => {
      const date = dayjs(item);
      const dateFormat = date.format('YYYY-MM');
      const sellingByMonth = sellingArtworksResult.find(
        (dataMonth) => dataMonth.timePoint === dateFormat,
      );
      const pendingByMonth = pendingArtworksResult.find(
        (dataMonth) => dataMonth.timePoint === dateFormat,
      );
      return {
        date: date.format('DD/MM'),
        selling: sellingByMonth ? sellingByMonth.value : 0,
        pending: pendingByMonth ? pendingByMonth.value : 0,
      };
    });
    return dataProcessed;
  };

  return (
    <BasicColumnChart
      data={dataChart}
      name="Số lượng Artwork"
      seriesFirst={{
        property: 'selling',
        tooltipLabel: 'Artwork đang bán',
        color: '#0062ff',
        name: 'Đang bán',
      }}
      seriesSecond={{
        property: 'pending',
        tooltipLabel: 'Artwork chờ bán',
        color: '#F5B544',
        name: 'Chờ bán',
      }}
    />
  );
}

ColumnChartArtwork.propTypes = { period: PropTypes.string, dateRange: PropTypes.array };

export default ColumnChartArtwork;
