import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

function useQuery({ name }) {
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const value = query.get(name);
  return value;
}

useQuery.propTypes = {};

export default useQuery;
