import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NoImage from '@Assets/images/no-image.png';
import { Button } from 'react-bootstrap';
import { toastError } from '@Utils/toastrHelper';
import { bytesToMegaBytes, googleImageUrl } from '@Utils/helper';
import Resizer from 'react-image-file-resizer';
import imageCompression from 'browser-image-compression';
import { AvatarInputWrapper } from './styled';

function AvatarInput({ accept, name, setValueForm, defaultValue }) {
  const inputRef = useRef(null);
  const [file, setFile] = useState();
  const [fileReview, setFileReview] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setFileReview(googleImageUrl(defaultValue));
    }
  }, [defaultValue]);

  const handleUpload = () => {
    inputRef.current.click();
  };

  const prepareFileUpload = (file) => {
    setFile(file);
    setValueForm(name, file);
    setFileReview(URL.createObjectURL(file));
  };

  const onChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      toastError('No file selected.');
    }
    const fileSize = bytesToMegaBytes(file.size);
    if (fileSize > 1) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        prepareFileUpload(compressedFile);
      } catch (error) {
        console.log(error);
      }
    } else {
      prepareFileUpload(file);
    }
  };

  return (
    <AvatarInputWrapper>
      <input
        name={name}
        onChange={onChange}
        accept={accept}
        type="file"
        className="d-none"
        ref={inputRef}
      />
      <div className="avatar-wrapper">
        {fileReview ? (
          <img src={fileReview} className="avatar" alt={name} />
        ) : (
          <img src={NoImage} className="avatar" alt="no-avatar" />
        )}
      </div>
      <Button variant="danger" className="fs-14" onClick={() => handleUpload()}>
        {defaultValue ? 'Đổi ảnh đại diện' : 'Upload ảnh đại diện'}
      </Button>
    </AvatarInputWrapper>
  );
}

AvatarInput.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.string,
  setValueForm: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default AvatarInput;
