import SellingIcon from '@Assets/images/artwork/selling.png';
import LaunchSoonIcon from '@Assets/images/artwork/launch_soon.png';
import WaitingIcon from '@Assets/images/artwork/waiting.png';
import PendingIcon from '@Assets/images/artwork/pending.png';
import CanceledIcon from '@Assets/images/artwork/canceled.png';
import NoLongerSellingIcon from '@Assets/images/artwork/no_longer_selling.png';

export const ARTWORK_STATUS = [
  {
    label: 'Selling',
    value: 'selling',
    color: '#219653',
    background: '#e2f5ea',
    icon: SellingIcon,
  },
  // {
  //   label: 'Launch soon',
  //   value: 'launch_soon',
  //   color: '#9B51E0',
  //   background: '#f8e7e8',
  //   icon: LaunchSoonIcon,
  // },
  {
    label: 'Waiting',
    value: 'waiting',
    color: '#D97116',
    background: '#f6effc',
    icon: WaitingIcon,
  },
  // {
  //   label: 'Pending',
  //   value: 'pending',
  //   color: '#2D9CDB',
  //   background: '#fffaeb',
  //   icon: PendingIcon,
  // },
  {
    label: 'Canceled',
    value: 'canceled',
    color: '#A70E13',
    background: '#f0f1f3',
    icon: CanceledIcon,
  },
  {
    label: 'No longer selling',
    value: 'no_longer_selling',
    color: '#667085',
    background: '#f0f1f3',
    icon: NoLongerSellingIcon,
  },
  // {
  //   label: 'Uncategorized',
  //   value: 'uncategorized',
  //   color: '#667085',
  //   background: '#f0f1f3',
  //   icon: NoLongerSellingIcon,
  // },
];

export const INPUT = ({ data, collections, artists, tags, productLines }) => {
  return [
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tên Artwork',
          validate: { required: true },
          name: 'name',
          type: 'text',
          defaultValue: data?.name,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Mã artwork',
          validate: { required: true },
          name: 'systemCode',
          type: 'text',
          options: artists,
          defaultValue: data?.systemCode,
        },
        {
          label: 'Chọn artist',
          name: 'artistId',
          type: 'select',
          options: artists,
          defaultValue: data ? data?.artist?.id : artists[0]?.value,
        },
        {
          label: 'Chọn collection',
          name: 'collectionId',
          type: 'select',
          options: collections,
          defaultValue: data ? data.collection?.id : collections[0]?.value,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'File preview (PNG, JPG)',
          name: 'previewImage',
          type: 'file',
          defaultValue: data ? data.image?.googleFileId : '',
          accept: 'image/*',
        },
        // {
        //   label: 'File gốc (.Ai, .PSD)',
        //   name: 'originalDesignFile',
        //   type: 'file',
        //   accept: '.psd,.ai',
        //   defaultValue: data?.originalDesignGoogleFileId,
        // },
        // {
        //   label: 'File Scan HĐ (PDF)',
        //   name: 'contractFile',
        //   type: 'file',
        //   accept: '.pdf',
        //   defaultValue: data?.contractGoogleFileId,
        // },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Link file gốc',
          name: 'originalDesignGoogleFileId',
          type: 'text',
          placeholder: 'Nhập link file gốc',
          defaultValue: data?.originalDesignGoogleFileId,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Câu chuyện gốc',
          name: 'originalStory',
          type: 'textarea',
          placeholder: 'Câu chuyện gốc giới thiệu artwork..',
          rows: 3,
          defaultValue: data?.originalStory,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Mô tả',
          name: 'description',
          type: 'textarea',
          placeholder: 'Viết mô tả giới thiệu artwork..',
          rows: 3,
          defaultValue: data?.description,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Profit (%)',
          validate: {
            required: true,
            validate: (value) => {
              const profit = Math.ceil(value);
              if (profit > 100) {
                return 'Profit không được lớn hơn 100';
              }
            },
          },
          name: 'profitPercentage',
          type: 'decimal',
          defaultValue: data?.profitPercentage,
        },
        {
          label: 'Chọn ngày mở bán',
          name: 'saleOpeningDate',
          type: 'date',
          defaultValue: data?.saleOpeningDate,
        },
        {
          label: 'Chọn ngày ký HĐ',
          name: 'contractSigningDate',
          type: 'date',
          defaultValue: data?.contractSigningDate,
        },
        // {
        //   label: 'Mã hợp đồng',
        //   validate: { required: true },
        //   name: 'contractNumber',
        //   type: 'text',
        //   defaultValue: data?.contractNumber,
        //   disabled: true,
        // },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Trạng thái',
          name: 'status',
          type: 'radio',
          options: ARTWORK_STATUS,
          defaultValue: data ? data.status : 'waiting',
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tag',
          name: 'tagIds',
          type: 'multiSelect',
          options: tags,
          placeholder: 'Nhập tag',
          defaultValue: data?.tags,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Line sản phẩm',
          name: 'productLineIds',
          type: 'multiSelect',
          options: productLines,
          placeholder: 'Nhập Line sản phẩm',
          defaultValue: data?.productLines,
        },
      ],
    },
  ];
};

export const INPUT_BASIC = ({ data }) => {
  return [
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tên Artwork',
          validate: { required: true },
          name: 'name',
          type: 'text',
          defaultValue: data?.name,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Mô tả',
          name: 'description',
          type: 'textarea',
          placeholder: 'Viết mô tả giới thiệu artwork..',
          rows: 3,
          defaultValue: data?.description,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Chọn ngày mở bán',
          name: 'saleOpeningDate',
          type: 'date',
          defaultValue: data?.saleOpeningDate,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'File preview (PNG, JPG)',
          name: 'previewImage',
          type: 'file',
          defaultValue: data ? data.image?.googleFileId : '',
          accept: 'image/*',
          width: 4,
        },
      ],
    },
  ];
};

export const INPUT_ADVANCE = ({ data, collections, artists, tags }) => {
  return [
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Mã artwork',
          validate: { required: true },
          name: 'systemCode',
          type: 'text',
          defaultValue: data?.systemCode,
        },
        {
          label: 'Chọn artist',
          name: 'artistId',
          type: 'select',
          options: artists,
          defaultValue: data ? data?.artist?.id : artists[0]?.value,
        },
        {
          label: 'Chọn collection',
          name: 'collectionId',
          type: 'select',
          options: collections,
          defaultValue: data ? data.collection?.id : collections[0]?.value,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'File gốc (.Ai, .PSD)',
          name: 'originalDesignFile',
          type: 'file',
          accept: '.psd,.ai',
          defaultValue: data?.originalDesignGoogleFileId,
          width: 4,
        },
        {
          label: 'File Scan HĐ (PDF)',
          name: 'contractFile',
          type: 'file',
          accept: '.pdf',
          defaultValue: data?.contractGoogleFileId,
          width: 4,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Link file gốc',
          name: 'originalDesignGoogleFileId',
          type: 'text',
          placeholder: 'Nhập link file gốc',
          defaultValue: data?.originalDesignGoogleFileId,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Câu chuyện gốc',
          name: 'originalStory',
          type: 'textarea',
          placeholder: 'Câu chuyện gốc giới thiệu artwork..',
          rows: 3,
          defaultValue: data?.originalStory,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Chọn ngày ký HĐ',
          name: 'contractSigningDate',
          type: 'date',
          defaultValue: data?.contractSigningDate,
          width: '4',
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Trạng thái',
          name: 'status',
          type: 'radio',
          options: ARTWORK_STATUS,
          defaultValue: data ? data.status : 'waiting',
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tag',
          name: 'tagIds',
          type: 'multiSelect',
          options: tags,
          placeholder: 'Nhập tag',
          defaultValue: data?.tags,
        },
      ],
    },
  ];
};

export const INPUT_COLLECTION = ({ data }) => {
  return [
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tên Collection',
          validate: { required: true },
          name: 'name',
          type: 'text',
          defaultValue: data?.name,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Mã Collection',
          validate: { required: true },
          name: 'systemCode',
          type: 'text',
          defaultValue: data?.systemCode,
        },
      ],
    },
  ];
};

export const INPUT_TAG = ({ data }) => {
  return [
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tên Tag',
          validate: { required: true },
          name: 'name',
          type: 'text',
          defaultValue: data?.name,
        },
      ],
    },
  ];
};

export const INPUT_PRODUCT_LINE = ({ data }) => {
  return [
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tên',
          validate: { required: true },
          name: 'name',
          type: 'text',
          defaultValue: data?.name,
        },
      ],
    },
  ];
};

export const ACTION_CONTROL = [
  {
    label: 'Thao tác',
    value: '',
  },
  {
    label: 'Đổi trạng thái',
    value: 'CHANGE_STATUS',
  },
  {
    label: 'Chọn ngày mở bán',
    value: 'CHANGE_SALE_OPENING_DATE',
  },
];

export const COLUMNS_TABLE = [
  {
    label: 'Artwork',
    value: 'artwork',
    role: 'read_basic',
  },
  {
    label: 'Mã artwork',
    value: 'systemCode',
  },
  {
    label: 'Trạng thái',
    value: 'status',
  },
  {
    label: 'Artist',
    value: 'artistName',
  },
  {
    label: 'Collection',
    value: 'collectionName',
  },
  {
    label: 'Mã HĐ',
    value: 'contractNumber',
  },

  {
    label: 'Ngày ký HĐ',
    value: 'contractSigningDate',
  },
  {
    label: 'Ngày mở bán',
    value: 'saleOpeningDate',
    role: 'read_basic',
  },
  {
    label: 'Profit (%)',
    value: 'profit',
  },
  {
    label: 'SL bán',
    value: 'soldCount',
  },
  {
    label: 'Doanh thu TC',
    value: 'totalRevenueAfterDiscount',
  },
  {
    label: 'Tỉ trọng DT',
    value: 'revenueToTotalPercentage',
  },
  {
    label: 'Tỉ trọng LN',
    value: 'profitToTotalPercentage',
  },
  {
    label: 'Ngày tạo',
    value: 'createdAt',
  },
  {
    label: 'Sửa gần nhất',
    value: 'updatedAt',
  },
];
