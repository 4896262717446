import React, { useEffect, useState } from 'react';
import { checkObjectEmpty } from '@Utils/helper';
import useQuery from '@Utils/useQuery';
import DataTableCustom from '@Components/DataTableCustom';
import { Button } from 'react-bootstrap';
import { Plus } from 'react-feather';
import useCustomQuery from '@Utils/useCustomQuery';
import { getArtworkTag } from '@Apis/artworkTag';
import SearchInput from '@Components/SearchInput';
import ModalTag from '@Components/ModalTag';
import ModalDeleteTag from '@Components/ModalDeleteTag';
import columns from './TagColumn';

function Tag(props) {
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [search, setSearch] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [detail, setDetail] = useState(null);

  const { data, refetch } = useCustomQuery({
    callApi: getArtworkTag,
    isShowGlobalLoading: true,
    params: filter,
    enabled: checkObjectEmpty(filter),
  });

  useEffect(() => {
    const filter = {};
    if (search) {
      filter.name = search;
    }
    setFilter({
      ...filter,
      page,
      pageSize,
      order: 'DESC',
      orderBy: 'id',
    });
  }, [page, pageSize, search]);

  const onChangeSort = ({ order, orderBy }) => {
    if (order) {
      setFilter({
        ...filter,
        order,
        orderBy,
      });
    } else {
      const { order, orderBy, ...restFilter } = filter;
      setFilter(restFilter);
    }
  };
  const openModalDetail = (item) => {
    setIsOpenModal(true);
    setDetail(item);
  };

  const openModalDelete = (item) => {
    setIsOpenModalDelete(true);
    setDetail(item);
  };
  return (
    <>
      <div className="filter-wrapper mt-3">
        <div className="d-flex gap-14 justify-content-between">
          <SearchInput placeholder="Tìm kiếm" setSearch={setSearch} defaultValue={search} />
          <Button variant="danger" onClick={() => setIsOpenModal(true)}>
            <Plus size="14" />
            <span className="fs-14 fw-semibold">Thêm mới Tag</span>
          </Button>
        </div>
      </div>
      {data && (
        <DataTableCustom
          columns={columns({
            order: filter.order,
            orderBy: filter.orderBy,
            onChangeSort,
            openModalDetail,
            openModalDelete,
          })}
          data={data}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPage={setPage}
          isPageEffect
        />
      )}
      <ModalTag
        isOpen={isOpenModal}
        close={() => {
          setDetail(null);
          setIsOpenModal(false);
        }}
        refetch={refetch}
        detail={detail}
      />
      <ModalDeleteTag
        isOpen={isOpenModalDelete}
        refetch={refetch}
        close={() => {
          setDetail(null);
          setIsOpenModalDelete(false);
        }}
        detail={detail}
      />
    </>
  );
}

Tag.propTypes = {};

export default Tag;
