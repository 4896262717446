/* eslint-disable no-nested-ternary */
import { checkIsEmail } from '@Utils/helper';

export const USER_STATUS = [
  { label: 'Tất cả trạng thái', value: 'all', color: '#219653', background: '#e2f5ea', hide: true },
  { label: 'Hoạt động', value: 'active', initialValue: 1, color: '#219653', background: '#e2f5ea' },
  { label: 'Khoá', value: 'disabled', initialValue: 0, color: '#A70E13', background: '#fceef1' },
];

export const USER_ADDNEW_INPUT = ({ data, roles, watch }) => {
  return [
    {
      row: { className: 'col-12' },
      items: [
        {
          name: 'profileImage',
          type: 'avatar',
          accept: 'image/*',
          defaultValue: data ? data.profileImageGoogleFileId : '',
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Trạng thái',
          name: 'enabled',
          type: 'radio',
          options: USER_STATUS,
          defaultValue: data ? (data.enabled ? 'active' : 'disabled') : 'active',
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Họ và tên',
          name: 'name',
          type: 'text',
          validate: { required: true },
          defaultValue: data?.name,
        },
        {
          label: 'Vai trò',
          name: 'roleId',
          type: 'select',
          defaultValue: roles[0]?.value,
          options: roles,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Email',
          validate: {
            required: true,
            validate: (email) => {
              const isEmail = checkIsEmail(email);
              if (!isEmail) {
                return 'Email không đúng định dạng';
              }
            },
          },
          name: 'email',
          type: 'text',
          defaultValue: data?.email,
        },
        {
          label: 'Phone',
          validate: { required: true },
          name: 'contactNumber',
          type: 'phone',
          defaultValue: data?.contactNumber,
        },
      ],
    },
    {
      row: { className: 'row' },
      items: [
        {
          label: 'Tên đăng nhập',
          name: 're_email',
          type: 'email',
          disabled: true,
          defaultValue: watch('email'),
        },
        {
          label: 'Mật khẩu',
          name: 'password',
          type: 'text',
          defaultValue: 'TC1234@abc',
          validate: {
            required: 'Mật khẩu phải có tối thiểu 8 kí tự, có ít nhất 1 kí tự viết hoa và 1 chữ số',
            validate: (password) => {
              const minLength = password.length >= 8;
              const leastOneUppercase = /.*[A-Z].*/.test(password);
              const leastOneNumber = /.*[0-9].*/.test(password);
              if (!minLength || !leastOneUppercase || !leastOneNumber) {
                return 'Mật khẩu phải có tối thiểu 8 kí tự, có ít nhất 1 kí tự viết hoa và 1 chữ số';
              }
            },
          },
        },
      ],
    },
  ];
};

export const USER_UPDATE_INPUT = ({ data, roles, watch }) => {
  return [
    {
      row: { className: 'col-12' },
      items: [
        {
          name: 'profileImage',
          type: 'avatar',
          accept: 'image/*',
          defaultValue: data ? data.profileImageGoogleFileId : '',
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Trạng thái',
          name: 'enabled',
          type: 'radio',
          options: USER_STATUS,
          defaultValue: data ? (data.enabled ? 'active' : 'disabled') : 'active',
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Họ và tên',
          name: 'name',
          type: 'text',
          validate: { required: true },
          defaultValue: data?.name,
        },
        {
          label: 'Vai trò',
          name: 'roleId',
          type: 'select',
          defaultValue: data ? data.role?.id : roles[0]?.value,
          options: roles,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Email',
          validate: {
            required: true,
            validate: (email) => {
              const isEmail = checkIsEmail(email);
              if (!isEmail) {
                return 'Email không đúng định dạng';
              }
            },
          },
          name: 'email',
          type: 'text',
          defaultValue: data?.email,
        },
        {
          label: 'Phone',
          validate: { required: true },
          name: 'contactNumber',
          type: 'phone',
          defaultValue: data?.contactNumber,
        },
      ],
    },
    {
      row: { className: 'row' },
      items: [
        {
          label: 'Tên đăng nhập',
          name: 're_email',
          type: 'email',
          disabled: true,
          defaultValue: watch('email'),
        },
        {
          label: 'Mật khẩu',
          name: 'password',
          type: 'text',
          defaultValue: '',
          validate: {
            required: false,
            validate: (password) => {
              if (password && password.length > 0) {
                const minLength = password.length >= 8;
                const leastOneUppercase = /.*[A-Z].*/.test(password);
                const leastOneNumber = /.*[0-9].*/.test(password);
                if (!minLength || !leastOneUppercase || !leastOneNumber) {
                  return 'Mật khẩu phải có tối thiểu 8 kí tự, có ít nhất 1 kí tự viết hoa và 1 chữ số';
                }
              }
            },
          },
        },
      ],
    },
  ];
};

export const INPUT_ROLE = ({ data }) => {
  return [
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tên nhóm quyền',
          validate: { required: true },
          name: 'name',
          type: 'text',
          defaultValue: data?.name,
          placeholder: 'Nhập tên nhóm quyền',
        },
      ],
    },
  ];
};
