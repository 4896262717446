import React from 'react';
import PropTypes from 'prop-types';

function TableLoading(props) {
  return <div>TableLoading</div>;
}

TableLoading.propTypes = {};

export default TableLoading;
