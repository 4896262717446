import styled from 'styled-components';

export const AvatarInputWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  .avatar-wrapper {
    position: relative;
    padding-bottom: 80px;
    width: 80px;
    max-height: 80px;
    display: block;
  }
  .avatar {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 50%;
  }
`;
