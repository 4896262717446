import { useSelector } from 'react-redux';

export const usePermissions = ({ roleName, permission }) => {
  const user = useSelector((state) => state.user.info);
  const { permissions } = user;
  const permissionByRole = permissions[`${roleName}`];
  if (permissionByRole) {
    return permissionByRole.includes(permission);
  }
  return false;
};
