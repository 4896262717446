import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/product-lines`;

export const getAllArtworkProductLine = async () => {
  const res = await axiosService.get(`${url}/all`);
  return res.data;
};

export const getArtworkProductLine = async (params) => {
  const res = await axiosService.get(url, params);
  return res.data;
};

export const updateArtworkProductLine = async (body) => {
  const { id, ...restBody } = body;
  const res = await axiosService.put(`${url}/${id}`, restBody);
  return res.data;
};

export const deleteArtworkProductLine = async ({ id }) => {
  const res = await axiosService.delete(`${url}/${id}`);
  return res.data;
};

export const createArtworkProductLine = async (body) => {
  const res = await axiosService.post(url, body);
  return res.data;
};
