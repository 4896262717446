import React from 'react';
import PropTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';

function useDashboardData({ func, dateRange = [], artistId }) {
  const fromDate = dateRange[0];
  const toDate = dateRange[1];
  const { data } = useCustomQuery({
    callApi: func,
    params: { fromDate, toDate, artistId },
    isShowGlobalLoading: true,
    enabled: !!func,
  });
  return data;
}

useDashboardData.propTypes = {
  func: PropTypes.func,
  dateRange: PropTypes.array,
  artistId: PropTypes.number,
};

export default useDashboardData;
