import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  mostSoldArtworksPeriod,
  mostSoldArtworksThisWeek,
  mostSoldArtworksThisMonth,
  mostSoldArtworksThisYear,
} from '@Apis/statisticTable';
import DataTableCustom from '@Components/DataTableCustom';
import useDashboardData from '@Utils/useDashboardData';
import columns from './ColumnsTable';

function TableMostSold({ period, dateRange, artistId }) {
  const objectFunc = {
    this_year: mostSoldArtworksThisYear,
    this_week: mostSoldArtworksThisWeek,
    this_month: mostSoldArtworksThisMonth,
    optional: mostSoldArtworksPeriod,
  };
  const [dataTable, setDataTable] = useState({});
  const func = objectFunc[period];
  const data = useDashboardData({ func, dateRange, artistId });

  useEffect(() => {
    if (data) {
      const { currentPeriodResult } = data;
      setDataTable({
        data: currentPeriodResult,
      });
    }
  }, [data]);
  return (
    <div className="table-top-value-wrapper mt-3">
      <div className="fw-bold fs-5 mb-3">Doanh thu theo Artwork</div>
      <DataTableCustom columns={columns()} data={dataTable} noPagination />
    </div>
  );
}

TableMostSold.propTypes = {
  period: PropTypes.string,
  dateRange: PropTypes.array,
  artistId: PropTypes.number,
};

export default TableMostSold;
