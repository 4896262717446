import React from 'react';
import TableSortColumn from '@Components/DataTableCustom/TableSortColumn';
import Avatar from '@Assets/images/avatar.png';
import { Nav } from 'react-bootstrap';
import { Edit3, Trash } from 'react-feather';
import UserStatus from '@Components/UserStatus';
import NoImage from '@Assets/images/no-image.png';
import { formatDecimal, formatVND, googleImageUrl } from '@Utils/helper';

function columns({
  openModalDetail,
  openModalDelete,
  navigate,
  setItemDelete,
  showButtonDelete,
  showButtonDetail,
  ...props
}) {
  return [
    {
      name: <TableSortColumn columnName="STT" defaultOrderBy="id" {...props} />,
      selector: (row, index) => index + 1,
      width: '60px',
      center: true,
      compact: true,
    },
    {
      name: <TableSortColumn columnName="Nhân viên" defaultOrderBy="name" {...props} />,
      cell: (row) => {
        return (
          <>
            <img
              src={
                row.profileImageGoogleFileId
                  ? googleImageUrl(row.profileImageGoogleFileId)
                  : NoImage
              }
              alt={row.name}
              width="34px"
            />
            <div className="ms-1">
              <b className="d-block fs-14 fw-bold">{row.name}</b>
              <span className="fs-12">{row.email}</span>
            </div>
          </>
        );
      },
      compact: true,
    },
    {
      name: <TableSortColumn columnName="Status" defaultOrderBy="enabled" {...props} />,
      selector: (row) => <UserStatus status={row.enabled ? 1 : 0} />,
    },
    {
      name: '',
      right: true,
      cell: (row) => (
        <>
          {showButtonDetail && (
            <Nav.Link>
              <Edit3 color="#a70e13" size={18} onClick={() => navigate(`/user/${row.id}`)} />
            </Nav.Link>
          )}
          {showButtonDelete && (
            <Nav.Link className="ms-2">
              <Trash color="#a70e13" size={18} onClick={() => setItemDelete(row)} />
            </Nav.Link>
          )}
        </>
      ),
    },
  ];
}
export default columns;
