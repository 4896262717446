import React from 'react';
import PropTypes from 'prop-types';

function FileReview({ fileType, name, fileReview }) {
  return fileType === 'image' ? (
    <div className="image-upload-wrapper">
      <img src={fileReview} alt={name} className="image-preview" />
    </div>
  ) : (
    <div className="file-wrapper">
      <div className="file-wrapper-content">
        <img src={fileReview} className="file-preview" alt={name} />
        {fileType === 'ai' && <p className="text-grey fs-14 font-semibold">file-goc.ai</p>}
        {fileType === 'psd' && <p className="text-grey fs-14 font-semibold">file-goc.psd</p>}
        {fileType === 'pdf' && <p className="text-grey fs-14 font-semibold">file-hd.pdf</p>}
        {fileType === 'document' && <p className="text-grey fs-14 font-semibold">file</p>}
      </div>
    </div>
  );
}

FileReview.propTypes = {
  fileType: PropTypes.string,
  file: PropTypes.object,
  name: PropTypes.string,
  fileReview: PropTypes.string,
};

export default FileReview;
