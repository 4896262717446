import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import { getArtwork } from '@Apis/artwork';
import { checkObjectEmpty } from '@Utils/helper';
import DataTableCustom from '@Components/DataTableCustom';
import columns from '@Pages/Artwork/components/ColumnsTable';
import Filter from '@Pages/Artwork/components/Filter';
import { getAllArtworkTag } from '@Apis/artworkTag';
import { getAllArtworkCollection } from '@Apis/artworkCollection';
import Control from '@Pages/Artwork/components/Control';
import ModalArtwork from '@Components/ModalArtwork';
import { INPUT_ARTWORK } from '@Constants/artistConstants';
import ModalArtistDetail from '@Components/ModalArtworkDetail';
import DataArtworkGrid from '@Components/DataArtworkGrid';
import { useSelector } from 'react-redux';
import { getAllArtworkProductLine } from '@Apis/artworkProductLine';

function Artwork({ id }) {
  const [filter, setFilter] = useState({});
  const [detail, setDetail] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenAddNew, setIsOpenAddNew] = useState(false);
  const [isShowGridTable, setIsShowGridTable] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const info = useSelector((state) => state.user.info);
  const { data, isError, error, refetch } = useCustomQuery({
    callApi: getArtwork,
    isShowGlobalLoading: true,
    params: filter,
    enabled: checkObjectEmpty(filter),
  });
  const [pageSize, setPageSize] = useState(15);
  const [page, setPage] = useState(1);

  const onChangeSort = ({ order, orderBy }) => {
    if (order) {
      setFilter({
        ...filter,
        order,
        orderBy,
      });
    } else {
      const { order, orderBy, ...restFilter } = filter;
      setFilter(restFilter);
    }
  };

  const handleOpenModalDetail = (item) => {
    setDetail(item);
    setIsOpenDetail(true);
  };

  const { data: artworkTags } = useCustomQuery({
    callApi: getAllArtworkTag,
    isShowGlobalLoading: false,
  });

  const { data: collections } = useCustomQuery({
    callApi: getAllArtworkCollection,
    isShowGlobalLoading: false,
  });
  const { data: productLines } = useCustomQuery({
    callApi: getAllArtworkProductLine,
    isShowGlobalLoading: false,
  });

  useEffect(() => {
    setSelectedRows([]);
  }, [toggledClearRows]);

  return (
    <div className="content-wrapper p-3">
      <Filter
        filter={filter}
        setFilter={setFilter}
        pageSize={pageSize}
        artworkTags={artworkTags}
        collectionTags={collections}
        artistIdsDefault={id}
        page={page}
        isShowButtonAddNew
        setIsOpenAddNew={setIsOpenAddNew}
        productLines={productLines}
      />
      <Control
        setIsShowGridTable={setIsShowGridTable}
        isShowGridTable={isShowGridTable}
        selectedRows={selectedRows}
        clearSelectedRow={() => setToggleClearRows(!toggledClearRows)}
        refetch={refetch}
      />
      {data && !isShowGridTable && (
        <DataTableCustom
          columns={columns({
            order: filter.order,
            orderBy: filter.orderBy,
            onChangeSort,
            info,
          })}
          data={data}
          onRowClicked={(item) => handleOpenModalDetail(item)}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPage={setPage}
          selectableRows
          onSelectedRowsChange={(selected) => setSelectedRows(selected.selectedRows)}
          isPageEffect
          clearSelectedRows={toggledClearRows}
        />
      )}
      {data && isShowGridTable && (
        <DataArtworkGrid
          data={data}
          setPageSize={setPageSize}
          setPage={setPage}
          onRowClicked={handleOpenModalDetail}
        />
      )}
      <ModalArtwork
        isOpen={isOpenAddNew}
        close={() => {
          setIsOpenAddNew(false);
          setDetail(null);
        }}
        refetch={refetch}
        detailId={detail?.artwork.id}
        inputs={INPUT_ARTWORK}
        artistIdDefault={id}
      />
      {detail && (
        <ModalArtistDetail
          isOpen={isOpenDetail}
          close={() => {
            setIsOpenDetail(false);
            setDetail(null);
          }}
          detailId={detail.artwork.id}
          handleOpenModalEdit={() => {
            setIsOpenAddNew(true);
            setIsOpenDetail(false);
          }}
        />
      )}
    </div>
  );
}

Artwork.propTypes = { id: PropTypes.number };

export default Artwork;
