import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/artist/statistics/graphs`;

export const artistProfitPeriod = async ({ fromDate, toDate }) => {
  const res = await axiosService.get(`${url}/artist-profit/date-period`, {
    fromDate,
    toDate,
  });
  return res.data;
};

export const artistProfitThisWeek = async () => {
  const res = await axiosService.get(`${url}/artist-profit/this-week`);
  return res.data;
};
export const artistProfitThisMonth = async () => {
  const res = await axiosService.get(`${url}/artist-profit/this-month`);
  return res.data;
};
export const artistProfitThisYear = async () => {
  const res = await axiosService.get(`${url}/artist-profit/this-year`);
  return res.data;
};
