import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import { checkObjectEmpty } from '@Utils/helper';
import { getUsers } from '@Apis/user';
import DataTableCustom from '@Components/DataTableCustom';
import { useNavigate } from 'react-router-dom';
import ModalDeleteUser from '@Components/ModalDeleteUser';
import { useSelector } from 'react-redux';
import { usePermissions } from '@Utils/usePermissions';

import columns from './StaffColumn';
import StaffFilter from './StaffFilter';

function Staff() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [itemDelete, setItemDelete] = useState(null);

  const { data, refetch } = useCustomQuery({
    callApi: getUsers,
    isShowGlobalLoading: true,
    params: filter,
    enabled: checkObjectEmpty(filter),
  });
  const onChangeSort = ({ order, orderBy }) => {
    if (order) {
      setFilter({
        ...filter,
        order,
        orderBy,
      });
    } else {
      const { order, orderBy, ...restFilter } = filter;
      setFilter(restFilter);
    }
  };
  const showButtonDelete = usePermissions({ roleName: 'user', permission: 'delete' });
  const showButtonDetail = usePermissions({ roleName: 'user', permission: 'update' });
  return (
    <>
      <StaffFilter setFilter={setFilter} page={page} pageSize={pageSize} navigate={navigate} />
      {data && (
        <DataTableCustom
          columns={columns({
            order: filter.order,
            orderBy: filter.orderBy,
            onChangeSort,
            navigate,
            setItemDelete,
            showButtonDelete,
            showButtonDetail,
          })}
          data={data}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPage={setPage}
          isPageEffect
        />
      )}
      <ModalDeleteUser
        isOpen={itemDelete !== null}
        refetch={refetch}
        close={() => setItemDelete(null)}
        detail={itemDelete}
      />
    </>
  );
}

Staff.propTypes = {};

export default Staff;
