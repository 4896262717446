import React from 'react';
import SignIn from '@Pages/SignIn';
import PublicLayout from '@Components/Layouts/PublicLayout';

export const publicConstant = [
  {
    path: '/',
    element: <PublicLayout />,
  },
  {
    path: '/login',
    element: <PublicLayout />,
    children: [
      {
        path: '/login',
        element: <SignIn />,
      },
    ],
  },
  {
    path: '/login-artist',
    element: <PublicLayout />,
    children: [
      {
        path: '/login-artist',
        element: <SignIn />,
      },
    ],
  },
];
