import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import { dayjsUtc, dayjsNow, processValueTextInput } from '@Utils/helper';
import FlatPickrDate from '@Components/FlatPickrDate';
import dayjs from 'dayjs';
import MultiSelectInput from './MultiSelectInput';
import FileInput from './FileInput';
import AvatarInput from './AvatarInput';

function Input({
  label,
  type = 'text',
  placeholder = '',
  name,
  register = () => {},
  style,
  validate,
  errors,
  icon,
  className,
  setValue,
  iconRight,
  defaultValue,
  setValueForm = () => {},
  disabled,
  options,
  ...props
}) {
  const error = errors && errors[name];
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(defaultValue || '');
    if (setValueForm) {
      if (type === 'multiSelect') {
        const defaultIds = defaultValue ? defaultValue.map((item) => item.id) : [];
        setValueForm(name, defaultIds);
      } else if (type !== 'file' && type !== 'avatar') {
        setValueForm(name, defaultValue);
      }
    }
    if (setValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (value) => {
    const valueProcessed = processValueTextInput({ value, type });
    setInputValue(valueProcessed);
    if (setValue) {
      setValue(valueProcessed);
    }
    if (setValueForm) {
      setValueForm(name, valueProcessed);
    }
  };

  const onChangeDate = (date, enableTime) => {
    let dateFormatted;
    if (enableTime) {
      dateFormatted = dayjs(date).toISOString();
    } else {
      dateFormatted = dayjs(date).format('YYYY-MM-DD');
    }
    setInputValue(dateFormatted);
    if (setValue) {
      setValue(dateFormatted);
    }
    if (setValueForm) {
      setValueForm(name, dateFormatted);
    }
  };

  const handleChangeMultiSelect = (e) => {
    setInputValue(e);
    setValueForm(name, e);
  };

  const isRequired = validate && validate.required;

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label>
          {label} {isRequired && <span className="text-danger">*</span>}
        </Form.Label>
      )}
      <InputGroup style={style} className={iconRight ? 'input-group-with-icon' : ''}>
        {icon && (
          <InputGroup.Text>
            <img src={icon} alt={name} />
          </InputGroup.Text>
        )}
        <input
          className="form-control"
          name={name}
          type={
            type === 'date' ||
            type === 'radio' ||
            type === 'checkbox' ||
            type === 'select' ||
            type === 'textarea' ||
            type === 'multiSelect' ||
            type === 'file' ||
            type === 'avatar'
              ? 'hidden'
              : type
          }
          placeholder={placeholder || label}
          {...register}
          onChange={(e) => handleChange(e.target.value)}
          value={inputValue}
          disabled={disabled}
        />
        {iconRight && (
          <InputGroup.Text>
            <img src={iconRight} alt={`${name}-right`} />
          </InputGroup.Text>
        )}
      </InputGroup>
      {type === 'date' && (
        <FlatPickrDate
          date={inputValue}
          setDate={(date, enableTime) => onChangeDate(date, enableTime)}
          name={name}
          placeholder={placeholder}
          {...props}
        />
      )}
      {(type === 'radio' || type === 'checkbox') && (
        <div className="d-flex align-items-center justify-content-start gap-8">
          {options.map((option, index) => {
            if (!option.hide) {
              return (
                <div key={option.value}>
                  <Form.Check
                    type={type}
                    label={option.label}
                    id={`${name}-${index}`}
                    style={{ color: option.color, fontWeight: '600' }}
                    disabled={disabled}
                    defaultChecked={defaultValue}
                  >
                    <Form.Check.Input
                      type={type}
                      name={name}
                      onChange={(e) => {
                        if (!option.isToggle) {
                          handleChange(e.target.value);
                          return;
                        }
                        const checked = e.target.checked;
                        if (checked) {
                          handleChange(true);
                        } else {
                          handleChange(false);
                        }
                      }}
                      checked={inputValue === option.value}
                      value={option.value}
                    />
                    <Form.Check.Label className="fs-14">{option.label}</Form.Check.Label>
                  </Form.Check>
                </div>
              );
            }
          })}
        </div>
      )}

      {type === 'select' && (
        <Form.Select
          onChange={(e) => handleChange(e.target.value)}
          value={inputValue}
          disabled={disabled}
        >
          {options.map((option, index) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Form.Select>
      )}
      {type === 'textarea' && (
        <textarea
          className="form-control"
          name={name}
          type={
            type === 'date' || type === 'radio' || type === 'select' || type === 'textarea'
              ? 'hidden'
              : type
          }
          placeholder={placeholder || label}
          {...register}
          onChange={(e) => handleChange(e.target.value)}
          value={inputValue}
          disabled={disabled}
          {...props}
        />
      )}
      {type === 'multiSelect' && (
        <MultiSelectInput
          allOptions={options}
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
              };
            },
          }}
          handleOnChange={(e) => handleChangeMultiSelect(e)}
          placeholder={placeholder}
          defaultValue={defaultValue}
          name={name}
        />
      )}
      {type === 'file' && (
        <FileInput name={name} {...props} setValueForm={setValueForm} defaultValue={defaultValue} />
      )}
      {type === 'avatar' && (
        <AvatarInput
          name={name}
          {...props}
          setValueForm={setValueForm}
          defaultValue={defaultValue}
        />
      )}
      {error && (
        <p className="text-danger mb-0 fs-14">
          {error.message ? error.message : 'Trường bắt buộc'}
        </p>
      )}
    </Form.Group>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  validate: PropTypes.object,
  name: PropTypes.string,
  errors: PropTypes.object,
  icon: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  setValue: PropTypes.func,
  iconRight: PropTypes.string,
  defaultValue: PropTypes.any,
  setValueForm: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.array,
};

export default Input;
