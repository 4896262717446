import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { info } from '@Apis/user';
import { info as artistInfo } from '@Apis/artist';
import { getAllRoles } from '@Apis/role';
import { handleDefaultHomepage, handleProcessPermissions } from '@Utils/helper';

export const getProfile = createAsyncThunk('user/getInfo', async (thunkApi) => {
  try {
    const userType = localStorage.getItem('USER_TYPE');
    let res;
    if (userType === 'USER') {
      res = await info();
      res.userType = 'USER';
    } else if (userType === 'ARTIST') {
      res = await artistInfo();
      res.userType = 'ARTIST';
    }
    return res;
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

export const getPermission = createAsyncThunk('user/rolesPermission', async (thunkApi) => {
  try {
    const { data } = await getAllRoles();
    return data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    info: null,
  },
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => {
      const data = action.payload;
      if (!data) return;
      const { permissions } = data;
      if (permissions) {
        const permissionsProcessed = handleProcessPermissions({ permissions });
        data.permissions = permissionsProcessed;
      }
      const defaultHomepage = handleDefaultHomepage({ permissions });
      state.info = data;
      state.defaultHomepage = defaultHomepage;
    },
    [getProfile.rejected]: () => {
      localStorage.removeItem(process.env.TOKEN);
    },
  },
});

const { reducer } = userSlice;
export const selectUser = createSelector(
  (state) => ({
    info: state.user.info,
  }),
  (state) => state,
);

export default reducer;
