import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/roles`;

export const getAllRoles = async () => {
  const res = await axiosService.get(url);
  return res.data;
};

export const updateRole = async (body) => {
  const { id, ...restBody } = body;
  const res = await axiosService.put(`${url}/${id}`, restBody);
  return res.data;
};

export const deleteRole = async ({ id }) => {
  const res = await axiosService.delete(`${url}/${id}`);
  return res.data;
};

export const createRole = async (body) => {
  const res = await axiosService.post(url, body);
  return res.data;
};

export const togglePermission = async (body) => {
  const { id, ...restBody } = body;
  const res = await axiosService.put(`${url}/${id}/toggle-permission`, restBody);
  return res.data;
};
