import styled from 'styled-components';

export const FlatPickrWrapper = styled.div`
  border-radius: 4px;
  color: #828282;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid #ced4da;
  background: #fff;
  min-width: 230px;
  .flatpickr-input {
    border: none;
    width: 100%;
    border-radius: 0.375rem;
    padding: 6px 10px 6px 0.75rem;

    font-size: 14px;
  }
  .flatpickr-input.active {
    border: none;
  }
  .flatpickr-input:focus-visible {
    outline: none;
  }
`;
