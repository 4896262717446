import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PeridDropdown from '@Components/PeriodDropdown';
import { getFirstDateOfWeek, getLastDateOfWeek } from '@Utils/helper';
import FlatPickrRange from '@Components/FlatPickrRange';
import Summary from './Summary';
import ProfitChart from './ProfitChart';
import TableMostSold from './TableMostSold';

function Statistic({ id }) {
  const [period, setPeriod] = useState('this_year');
  const firstDate = getFirstDateOfWeek();
  const lastDate = getLastDateOfWeek();
  const [dateRange, setDateRange] = useState([
    firstDate.format('YYYY-MM-DD'),
    lastDate.format('YYYY-MM-DD'),
  ]);
  return (
    <div className="p-3">
      <div className="d-flex align-items-center justify-content-between">
        <p className="fw-bold fs-5">Tổng quan</p>
        <div className="d-flex gap-14">
          <PeridDropdown period={period} setPeriod={setPeriod} />
          {period === 'optional' && (
            <FlatPickrRange
              name="date-range"
              placeholder="Chọn ngày"
              date={dateRange}
              setDate={setDateRange}
              style={{ borderRadius: '100px' }}
            />
          )}
        </div>
      </div>
      <Summary period={period} dateRange={dateRange} artistId={id} />
      <ProfitChart period={period} dateRange={dateRange} artistId={id} />
      <TableMostSold period={period} dateRange={dateRange} artistId={id} />
    </div>
  );
}

Statistic.propTypes = {
  id: PropTypes.number,
};

export default Statistic;
