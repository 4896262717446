import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@Assets/images/icons/search.png';
import TextInput from '@Components/Input';

function SearchInput({ setSearch, search, placeholder, name = 'name' }) {
  const typingRef = useRef(null);

  const onChange = useCallback((newValue) => {
    if (typingRef.current) {
      clearTimeout(typingRef.current);
    }
    typingRef.current = setTimeout(() => {
      setSearch(newValue);
    }, 300);
  }, []);

  return (
    <TextInput
      type="search"
      iconRight={SearchIcon}
      style={{ width: '240px' }}
      placeholder={placeholder}
      setValue={onChange}
      defaultValue={search}
    />
  );
}

SearchInput.propTypes = {
  setSearch: PropTypes.func,
  placeholder: PropTypes.string,
  search: PropTypes.string,
  name: PropTypes.string,
};

export default SearchInput;
