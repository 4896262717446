import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import InputOption from './InputOption';
import SelectContainer from './SelectContainer';
import ControlCustom from './ControlCustom';

function MultiSelect({
  style,
  allOptions,
  selectedOptions,
  setSelectedOptions,
  placeholder,
  handleOnChange,
  ...props
}) {
  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      // onChange={(options) => {
      //   if (Array.isArray(options)) {
      //     setSelectedOptions(options);
      //   }
      //   handleOnChange
      // }}
      isClearable={false}
      onChange={handleOnChange}
      options={allOptions}
      components={{
        Option: InputOption,
        // MultiValue: ControlCustom,
      }}
      value={selectedOptions}
      placeholder={placeholder}
      className="multi-select"
      {...props}
    />
  );
}

MultiSelect.propTypes = {
  style: PropTypes.object,
  allOptions: PropTypes.array,
  selectedOptions: PropTypes.any,
  setSelectedOptions: PropTypes.func,
  placeholder: PropTypes.string,
  handleOnChange: PropTypes.func,
};

export default MultiSelect;
