import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import FormInput from '@Components/FormInput';
import ButtonConfirm from '@Components/ButtonConfirm';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { INPUT_PRODUCT_LINE } from '@Constants/artworkConstants';
import { createArtworkProductLine, updateArtworkProductLine } from '@Apis/artworkProductLine';

function ModalProductLine({ isOpen, close, refetch, detail }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
    watch,
  } = useForm();

  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: createArtworkProductLine,
    isShowGlobalLoading: true,
  });

  const {
    mutate: mutateUpdate,
    isSuccess: isSuccessUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
  } = useCustomMutation({
    callApi: updateArtworkProductLine,
    isShowGlobalLoading: true,
  });

  const onSubmit = (data) => {
    if (detail) {
      data.id = detail.id;
      mutateUpdate(data);
    } else {
      mutate(data);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Tạo Line sản phẩm thành công');
      close();
      refetch();
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isSuccessUpdate) {
      toastSuccess('Sửa Line sản phẩm thành công');
      close();
      refetch();
    }
    if (isErrorUpdate) {
      toastError(errorUpdate);
    }
  }, [isSuccessUpdate, isErrorUpdate]);

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered show={isOpen} onHide={close}>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="fs-5 fw-bold text-grey">
            {detail ? 'Sửa Line sản phẩm' : 'Thêm mới Line sản phẩm'}
          </p>
          <X size={18} onClick={close} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            inputsForm={INPUT_PRODUCT_LINE({
              data: detail,
            })}
            register={register}
            errors={errors}
            setValueForm={setValueForm}
            watch={watch}
          />
          <div className="d-flex justify-content-center gap-8">
            <ButtonConfirm
              variant="danger"
              name="Lưu lại"
              type="submit"
              className="fs-14 px-3"
              loading={isLoading}
            />
            <ButtonConfirm
              variant="outline-danger"
              className="fs-14 px-3"
              name="Huỷ"
              onClick={close}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

ModalProductLine.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  refetch: PropTypes.func,
  detail: PropTypes.object,
};

export default ModalProductLine;
