import styled from 'styled-components';

export const ImageHoverWrapper = styled.div`
  .dropdown-menu.show {
    min-width: 200px;
    height: 200px;
    border-radius: 16px;
    border: none;
  }
`;
