import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/statistics/graphs`;

export const artistProfitPeriod = async ({ fromDate, toDate, artistId }) => {
  const res = await axiosService.get(`${url}/artist-profit/date-period`, {
    fromDate,
    toDate,
    artistId,
  });
  return res.data;
};

export const artistProfitThisWeek = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/artist-profit/this-week`, { artistId });
  return res.data;
};
export const artistProfitThisMonth = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/artist-profit/this-month`, { artistId });
  return res.data;
};
export const artistProfitThisYear = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/artist-profit/this-year`, { artistId });
  return res.data;
};

export const artistCountPeriod = async ({ fromDate, toDate, artistId }) => {
  const res = await axiosService.get(`${url}/artists-count/date-period`, {
    fromDate,
    toDate,
    artistId,
  });
  return res.data;
};

export const artistCountThisWeek = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/artists-count/this-week`, { artistId });
  return res.data;
};
export const artistCountThisMonth = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/artists-count/this-month`, { artistId });
  return res.data;
};
export const artistCountThisYear = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/artists-count/this-year`, { artistId });
  return res.data;
};

export const artworkCountPeriod = async ({ fromDate, toDate, artistId }) => {
  const res = await axiosService.get(`${url}/artworks-count/date-period`, {
    fromDate,
    toDate,
    artistId,
  });
  return res.data;
};

export const artworkCountThisWeek = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/artworks-count/this-week`, { artistId });
  return res.data;
};
export const artworkCountThisMonth = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/artworks-count/this-month`, { artistId });
  return res.data;
};
export const artworkCountThisYear = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/artworks-count/this-year`, { artistId });
  return res.data;
};
