import styled from 'styled-components';

export const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 100vh;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
export const ImageRightSide = styled.img`
  width: 100%;
  height: 100vh;
`;
