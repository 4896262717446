import React, { useEffect } from 'react';
import useCustomQuery from '@Utils/useCustomQuery';
import { getAllRoles, togglePermission } from '@Apis/role';
import { Col, Row } from 'react-bootstrap';
import shortid from 'shortid';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError } from '@Utils/toastrHelper';
import { PERMISSION_ROWS } from '@Constants/constant';
import { PermissionTableWrapper } from '../styled';

function RolePermissions() {
  const { data: roles, refetch } = useCustomQuery({
    callApi: getAllRoles,
    isShowGlobalLoading: true,
  });
  const { mutate, isError, isSuccess, error } = useCustomMutation({
    callApi: togglePermission,
    isShowGlobalLoading: true,
  });

  useEffect(() => {
    if (isSuccess) refetch();
    if (isError) toastError(error);
  }, [isSuccess, isError, error]);

  return (
    <PermissionTableWrapper className="pt-4">
      <Row className="header border-bottom pb-4">
        <Col md="5" className="first-col">
          Tính năng
        </Col>
        {roles?.map((role) => !role.isAdmin && <Col className="col text-center">{role.name}</Col>)}
      </Row>
      {PERMISSION_ROWS.map((row) => {
        const { title, entity, verb, className } = row;
        return (
          <Row key={shortid.generate()} className="border-bottom py-3">
            <Col md="5" className={className}>
              {title}
            </Col>
            {entity &&
              roles?.map(
                (role) =>
                  !role.isAdmin && (
                    <Col key={role.id} className="text-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={role.permissions[entity]?.includes(verb)}
                        onChange={() => mutate({ id: role.id, entity, verb })}
                      />
                    </Col>
                  ),
              )}
          </Row>
        );
      })}
    </PermissionTableWrapper>
  );
}

RolePermissions.propTypes = {};

export default RolePermissions;
