import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '@Components/MultiSelect';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Plus } from 'react-feather';
import { ARTIST_STATUS } from '@Constants/artistConstants';
import SearchInput from '@Components/SearchInput';
import FlatPickrRange from '@Components/FlatPickrRange';
import TagControl from '@Components/TagControl';
import {
  removeOptionValueAll,
  processTagsFilterWhenClear,
  getOptionsByName,
  processTagsFilter,
} from '@Utils/helper';
import useQuery from '@Utils/useQuery';
import ColumnsControl from '@Components/ColumnsControl';
import { usePermissions } from '@Utils/usePermissions';

function FilterArtist({
  setFilter,
  pageSize,
  filter,
  columnsShow,
  setColumnsShow,
  localStorageName,
}) {
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState();
  const [tags, setTags] = useState([]);
  const page = useQuery({ name: 'page' }) || 1;
  localStorage.setItem('ARTIST_PAGE', page);
  const [search, setSearch] = useState();
  const showButtonAddnew = usePermissions({ roleName: 'artist', permission: 'create' });
  const inititalOptions = [
    {
      name: 'statuses',
      inititialOptions: ARTIST_STATUS,
      allOptions: [{ value: 'all', label: 'Tất cả trạng thái' }, ...ARTIST_STATUS],
      selectedOptions: [],
    },
  ];
  const [options, setOptions] = useState(inititalOptions);

  useEffect(() => {
    if (search) {
      filter.searchText = search;
    } else {
      delete filter.searchText;
    }
    if (dateRange && dateRange.length > 0) {
      filter.mostRecentContractSigningDateStart = dateRange[0];
      filter.mostRecentContractSigningDateEnd = dateRange[1];
    } else {
      delete filter.mostRecentContractSigningDateStart;
      delete filter.mostRecentContractSigningDateEnd;
    }
    if (tags && tags.length > 0) {
      tags.map((tag) => {
        const tagValues = tag.values.map((item) => item.value);
        filter[tag.tagName] = tagValues.join(',');
      });
    } else {
      delete filter.statuses;
    }
    setFilter({
      order: 'DESC',
      orderBy: 'mostRecentContactedEventDate',
      ...filter,
      page,
      pageSize,
    });
  }, [dateRange, page, pageSize, search, tags]);

  const handleOnChangeSelect = (selected, event) => {
    if (selected && event) {
      const { name } = event;

      const optionsByName = options.find((item) => item.name === name);
      if (event.action === 'select-option' && event.option.value === 'all') {
        optionsByName.selectedOptions = optionsByName.allOptions;
        setOptions([...options]);
        return;
      }
      if (event.action === 'deselect-option' && event.option.value === 'all') {
        optionsByName.selectedOptions = [];
        setOptions([...options]);
        return;
      }
      optionsByName.selectedOptions = selected.filter((item) => item.value !== 'all');
      setOptions([...options]);
    }
  };

  useEffect(() => {
    const tags = [];
    options.map((option) => {
      const { name } = option;
      if (option.selectedOptions.length > 0) {
        const values = removeOptionValueAll(option.selectedOptions);
        tags.push({
          tagName: name,
          values,
        });
      } else {
        tags.push({
          tagName: name,
          values: [],
        });
      }
    });
    setTags(processTagsFilter(tags));
  }, [options]);

  const onDeleteAllFilter = () => {
    const optionsDeselected = options.map((item) => {
      item.selectedOptions = [];
      return item;
    });
    setOptions([...optionsDeselected]);
  };

  const onDeleteFilter = ({ tagName, value }) => {
    const optionsByName = options.find((item) => item.name === tagName);
    const filterSelectedOptions = processTagsFilterWhenClear({
      option: optionsByName,
      valueNeedRemove: value,
    });
    optionsByName.selectedOptions = filterSelectedOptions;
    setOptions([...options]);
  };

  const optionStatus = getOptionsByName({ inititalOptions: options, name: 'statuses' });

  return (
    <div className="filter-wrapper">
      <div className="d-flex gap-14 justify-content-between">
        <div className="d-flex gap-14 justify-content-between">
          <SearchInput
            placeholder="Tìm theo tên, mã Artist, nghệ danh"
            setSearch={setSearch}
            defaultValue={search}
            name="searchText"
          />
          <MultiSelect
            style={{ width: '200px' }}
            allOptions={optionStatus.allOptions}
            placeholder="Trạng thái"
            className="multi-select"
            selectedOptions={optionStatus.selectedOptions}
            handleOnChange={handleOnChangeSelect}
            name={optionStatus.name}
          />
          <FlatPickrRange
            name="date-range"
            placeholder="Ngày kí hợp đồng gần nhất"
            date={dateRange}
            setDate={setDateRange}
            maxDate="today"
          />
          <ColumnsControl
            columnsShow={columnsShow}
            setColumnsShow={setColumnsShow}
            localStorageName={localStorageName}
          />
        </div>
        {showButtonAddnew ? (
          <Button variant="danger" onClick={() => navigate('/artist/add-new')}>
            <Plus size="14" />
            <span className="fs-14 fw-semibold">Thêm mới Artist</span>
          </Button>
        ) : (
          ''
        )}
      </div>
      <TagControl
        tags={tags}
        onDeleteAllFilter={onDeleteAllFilter}
        onDeleteFilter={onDeleteFilter}
      />
    </div>
  );
}

FilterArtist.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  pageSize: PropTypes.number,
  columnsShow: PropTypes.array,
  setColumnsShow: PropTypes.func,
  localStorageName: PropTypes.string,
};

export default FilterArtist;
