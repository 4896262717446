import { configureStore } from '@reduxjs/toolkit';
import uiSlice from '@Libs/slices/uiSlice';
import userSlice from '@Libs/slices/userSlice';

export default configureStore({
  reducer: {
    ui: uiSlice,
    user: userSlice,
  },
  devTools: true,
  // enhancers: [devToolsEnhancer({ realtime: true, port: 8080 })],
});
