import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import UploadIcon from '@Assets/images/icons/upload-icon.png';
import ClearIcon from '@Assets/images/icons/clear.png';
import { toastError } from '@Utils/toastrHelper';
import FileIcon from '@Assets/images/icons/file.png';
import { bytesToMegaBytes, googleImageUrl } from '@Utils/helper';
import imageCompression from 'browser-image-compression';
import { ImageInputWrapper } from './styled';
import FileReview from './FileReview';

function FileInput({ accept, name, setValueForm, defaultValue }) {
  const inputRef = useRef(null);
  const [file, setFile] = useState();
  const [fileType, setFileGroup] = useState('image');
  const [fileReview, setFileReview] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      if (name === 'previewImage') {
        setFileReview(googleImageUrl(defaultValue));
        setFileGroup('image');
      } else {
        setFileReview(FileIcon);
        setFileGroup('document');
      }
    }
  }, [defaultValue]);

  const prepareFileUpload = (file) => {
    setFile(file);
    setValueForm(name, file);
    const fileType = getFileType(file);
    if (fileType === 'image') {
      setFileReview(URL.createObjectURL(file));
    } else {
      setFileReview(FileIcon);
    }
    setFileGroup(getFileType(file));
  };

  const onChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      toastError('No file selected.');
      return;
    }
    const fileSize = bytesToMegaBytes(file.size);
    if (fileSize > 20) {
      toastError('File không được lớn hơn 20MB');
      return;
    }
    const fileType = getFileType(file);
    if (fileType === 'image' && fileSize > 1) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        prepareFileUpload(compressedFile);
      } catch (error) {
        console.log(error);
      }
    } else {
      prepareFileUpload(file);
    }
  };

  const handleUpload = () => {
    inputRef.current.click();
  };

  const getFileType = (file) => {
    if (file.type.indexOf('postscript') !== -1) {
      return 'ai';
    }
    if (file.type.indexOf('pdf') !== -1) {
      return 'pdf';
    }
    if (file.type.indexOf('photoshop') !== -1) {
      return 'psd';
    }
    return 'image';
  };

  const handleClearFile = () => {
    setFile(file);
    setValueForm(name, null);
    setFileReview('');
  };

  return (
    <ImageInputWrapper>
      <input
        name={name}
        onChange={onChange}
        accept={accept}
        type="file"
        className="d-none"
        ref={inputRef}
      />
      {fileReview && (
        <Nav.Link onClick={() => handleClearFile()}>
          <img src={ClearIcon} alt="clear" className="clear-button" />
        </Nav.Link>
      )}

      <Nav.Link className="text-center h-100" onClick={() => handleUpload()}>
        {fileReview ? (
          <FileReview fileType={fileType} name={name} fileReview={fileReview} />
        ) : (
          <div className="upload-file">
            <div className="upload-file-content">
              <img src={UploadIcon} alt={name} />
              <p className="fs-14 text-danger">Cick here to upload file </p>
            </div>
          </div>
        )}
      </Nav.Link>
    </ImageInputWrapper>
  );
}

FileInput.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.string,
  setValueForm: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default FileInput;
