import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeadingTitle from '@Components/HeadingTitle';
import { detailArtist } from '@Apis/artist';
import useCustomQuery from '@Utils/useCustomQuery';
import ArtistInfo from '@Components/ArtistInfo';
import { Container, Row, Col } from 'react-bootstrap';
import { toastError } from '@Utils/toastrHelper';
import DetailRightSide from './DetailRightSide';

function Detail({ id }) {
  const { data, error } = useCustomQuery({
    callApi: detailArtist,
    isShowGlobalLoading: true,
    params: { id },
  });

  useEffect(() => {
    if (error) {
      toastError(error);
    }
  }, [error]);

  const page = localStorage.getItem('ARTIST_PAGE');

  return (
    data && (
      <>
        <HeadingTitle title={data.stageName} back={`/artist?page=${page}`} />
        <div className="content-body">
          <Container fluid className="">
            <Row className="g-0">
              <Col md="3">
                <ArtistInfo info={data} />
              </Col>
              <Col md="9">
                <DetailRightSide />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  );
}

Detail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Detail;
