import React from 'react';
import PropTypes from 'prop-types';
import { SummaryTrendWrapper } from './styled';

function SummaryTrend({ percent, period }) {
  const periodName = () => {
    const objectPriodName = {
      this_week: 'so với tuân trước',
      this_year: 'so với năm trước',
      this_month: 'so với tháng trước',
    };
    return objectPriodName[period];
  };
  return (
    <SummaryTrendWrapper>
      {percent >= 0 && <span className="percent-compare percent-compare-up">+{percent} %</span>}
      {percent < 0 && <span className="percent-compare percent-compare-down">{percent} %</span>}
      {percent === 'N/A' && <span className="text-danger fw-bold">{percent}</span>}
      <p className="text-grey fs-14 mt-1">{periodName()}</p>
    </SummaryTrendWrapper>
  );
}

SummaryTrend.propTypes = {
  period: PropTypes.string,
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SummaryTrend;
