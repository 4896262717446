import React from 'react';
import PropTypes from 'prop-types';
import { ARTWORK_STATUS } from '@Constants/artworkConstants';

import { ArtworkStatusWrapper } from './styled';

function ArtworkStatus({ status }) {
  const artworktStatus = ARTWORK_STATUS.find((item) => item.value === status) || {};

  return (
    <ArtworkStatusWrapper style={{ color: artworktStatus.color }}>
      <img src={artworktStatus.icon} alt={artworktStatus.label} />
      <span className="fs-14 fw-semibold">{artworktStatus.label}</span>
    </ArtworkStatusWrapper>
  );
}

ArtworkStatus.propTypes = {
  status: PropTypes.string,
};

export default ArtworkStatus;
