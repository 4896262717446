/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'flatpickr/dist/themes/light.css';
import flatpickr from 'flatpickr/dist/flatpickr.js';
import { Calendar, X } from 'react-feather';
import dayjs from 'dayjs';
import shortid from 'shortid';
import { FlatPickrWrapper } from './styles';

export default function FlatPickrRange({
  placeholder,
  name,
  date,
  setDate,
  className,
  style,
  maxDate,
}) {
  const flatPickrId = `flatpickr-${shortid.generate(4)}`;
  useEffect(() => {
    flatpickr(`#${flatPickrId}`, {
      showMonths: 1,
      dateFormat: 'Y-m-d',
      defaultDate: date ? date : '',
      allowInput: true,
      mode: 'range',
      onChange: (selectedDates) => {
        if (selectedDates[1]) {
          setDate([
            dayjs(selectedDates[0]).format('YYYY-MM-DD'),
            dayjs(selectedDates[1]).format('YYYY-MM-DD'),
          ]);
        }
      },
      maxDate,
    });
  }, []);

  return (
    <FlatPickrWrapper className={className} style={style}>
      <div id={flatPickrId} className="w-100">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="pd-r-20 d-flex">
            <p className="mb-0">{date ? `${date[0]} - ${date[1]}` : placeholder}</p>
          </div>
          <Calendar className="ml-50" size={14} />
        </div>
      </div>
      {date ? <X color="#eb5757" className="me-2" size={18} onClick={() => setDate()} /> : ''}
    </FlatPickrWrapper>
  );
}

FlatPickrRange.propTypes = {
  name: PropTypes.string,
  date: PropTypes.array,
  setDate: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  maxDate: PropTypes.string,
};
