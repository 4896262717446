import { SIZE_SHIRT } from '@Constants/constant';
import { checkIsEmail } from '@Utils/helper';
import { ARTWORK_STATUS } from './artworkConstants';

export const ARTIST_STATUS = [
  { label: 'Working', value: 'working', color: '#219653', background: '#e2f5ea' },
  { label: 'Waiting', value: 'waiting', color: '#A70E13', background: '#f8e7e8' },
  // { label: 'Potential', value: 'potential', color: '#9c51e0', background: '#f6effc' },
  { label: 'Watching', value: 'watching', color: '#cc8405', background: '#fffaeb' },
  // { label: 'Uncategorized', value: 'uncategorized', color: '#667085', background: '#f0f1f3' },
];

export const INFOMATION_INPUT = ({ data }) => {
  return [
    {
      row: { className: 'col-12' },
      items: [
        {
          name: 'profileImage',
          type: 'avatar',
          accept: 'image/*',
          defaultValue: data ? data.profileImageGoogleFileId : '',
        },
      ],
    },
    // {
    //   row: { className: 'col-12' },
    //   items: [
    //     {
    //       label: 'Trạng thái',
    //       name: 'status',
    //       type: 'radio',
    //       options: ARTIST_STATUS,
    //       defaultValue: data ? data.status : 'watching',
    //     },
    //   ],
    // },
    {
      row: { className: 'col-12' },
      items: [
        {
          label: 'Mã Artist',
          validate: { required: true },
          name: 'systemCode',
          type: 'text',
          defaultValue: data?.systemCode,
        },
        {
          label: 'Nghệ danh',
          validate: { required: true },
          name: 'stageName',
          type: 'text',
          defaultValue: data?.stageName,
        },
        {
          label: 'Họ tên thật',
          validate: { required: true },
          name: 'name',
          type: 'text',
          defaultValue: data?.name,
        },
      ],
    },
    {
      row: { className: 'col-12', id: 2 },
      items: [
        // {
        //   label: 'Profit (%)',
        //   validate: {
        //     required: true,
        //     validate: (value) => {
        //       const profit = Math.ceil(value);
        //       if (profit > 100) {
        //         return 'Profit không được lớn hơn 100';
        //       }
        //     },
        //   },
        //   name: 'profitPercentage',
        //   type: 'decimal',
        //   defaultValue: data?.profitPercentage,
        // },
        {
          label: 'Email',
          validate: {
            required: true,
            validate: (email) => {
              const isEmail = checkIsEmail(email);
              if (!isEmail) {
                return 'Email không đúng định dạng';
              }
            },
          },
          name: 'email',
          type: 'text',
          defaultValue: data?.email,
        },
        {
          label: 'Phone',
          validate: { required: true },
          name: 'contactNumber',
          type: 'phone',
          defaultValue: data?.contactNumber,
        },
      ],
    },
    {
      row: { className: 'col-12' },
      items: [
        {
          label: 'Địa chỉ',
          // validate: { required: true },
          name: 'address',
          type: 'text',
          defaultValue: data?.address,
        },
      ],
    },
    {
      row: { className: 'col-12' },
      items: [
        {
          label: 'Sinh nhật',
          name: 'dateOfBirth',
          type: 'date',
          defaultValue: data?.dateOfBirth,
        },
        {
          label: 'CMT/CCCD',
          validate: { required: true },
          name: 'citizenIdentificationNumber',
          type: 'number',
          defaultValue: data?.citizenIdentificationNumber,
        },
      ],
    },
    {
      row: { className: 'col-12' },
      items: [
        {
          label: 'Học vấn',
          name: 'academicLevel',
          type: 'text',
          defaultValue: data?.academicLevel,
        },
        {
          label: 'Size áo',
          name: 'shirtSize',
          type: 'select',
          options: SIZE_SHIRT,
          defaultValue: data ? data.shirtSize : 'L',
        },
      ],
    },
    {
      row: { className: 'col-12' },
      items: [
        {
          label: 'Thời gian ký Hợp đồng đầu tiên',
          name: 'firstContractSigningDate',
          type: 'date',
          defaultValue: data?.firstContractSigningDate,
        },
        {
          label: 'Mã hợp đồng',
          name: 'contractNumber',
          type: 'text',
          defaultValue: data?.contractNumber,
        },
      ],
    },
    {
      row: { className: 'col-6' },
      items: [
        {
          label: 'File Scan HĐ (PDF)',
          name: 'contractFile',
          type: 'file',
          accept: '.pdf',
          defaultValue: data?.contractGoogleFileId,
        },
      ],
    },
    {
      row: { className: 'col-12' },
      items: [
        {
          label: 'Link portfolio',
          name: 'portfolioUrl',
          type: 'text',
          defaultValue: data?.portfolioUrl,
        },
        {
          label: 'Link facebook',
          name: 'facebookUrl',
          type: 'text',
          defaultValue: data?.facebookUrl,
        },
      ],
    },
    {
      row: { className: 'col-6' },
      items: [
        {
          label: 'Link Instagram',
          name: 'instagramUrl',
          type: 'text',
          defaultValue: data?.instagramUrl,
        },
      ],
    },
  ];
};

export const BANK_INPUT = ({ data }) => {
  return [
    {
      row: { heading: 'Tài khoản 1', className: 'col-12' },
      items: [
        {
          label: 'Tên chủ tài khoản',
          name: 'bankAccounts0[accountHolder]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[0]?.accountHolder : '',
        },
        {
          label: 'Số tài khoản',
          name: 'bankAccounts0[accountNumber]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[0]?.accountNumber : '',
        },
        {
          label: 'Ngân hàng',
          name: 'bankAccounts0[bankName]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[0]?.bankName : '',
        },
      ],
    },
    {
      row: { heading: 'Tài khoản 2', className: 'col-12' },
      items: [
        {
          label: 'Tên chủ tài khoản',
          name: 'bankAccounts1[accountHolder]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[1]?.accountHolder : '',
        },
        {
          label: 'Số tài khoản',
          name: 'bankAccounts1[accountNumber]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[1]?.accountNumber : '',
        },
        {
          label: 'Ngân hàng',
          name: 'bankAccounts1[bankName]',
          type: 'text',
          defaultValue: data?.bankAccounts ? data.bankAccounts[1]?.bankName : '',
        },
      ],
    },
  ];
};

export const ACCOUNT_INFO = ({ data }) => {
  return [
    {
      row: { className: 'col-12' },
      items: [
        {
          label: 'Tên đăng nhập',
          name: 're_email',
          type: 'email',
          disabled: true,
          defaultValue: data?.re_email,
        },
        {
          label: 'Mật khẩu',
          name: 'password',
          type: 'text',
          defaultValue: data?.password,
          validate: {
            required: 'Mật khẩu phải có tối thiểu 8 kí tự, có ít nhất 1 kí tự viết hoa và 1 chữ số',
            validate: (password) => {
              const minLength = password.length >= 8;
              const leastOneUppercase = /.*[A-Z].*/.test(password);
              const leastOneNumber = /.*[0-9].*/.test(password);
              if (!minLength || !leastOneUppercase || !leastOneNumber) {
                return 'Mật khẩu phải có tối thiểu 8 kí tự, có ít nhất 1 kí tự viết hoa và 1 chữ số';
              }
            },
          },
        },
      ],
    },
  ];
};

export const ACCOUNT_INFO_UPDATE = ({ data }) => {
  return [
    {
      row: { className: 'col-12', id: 1 },
      items: [
        {
          label: 'Tên đăng nhập',
          name: 'email',
          type: 'email',
          disabled: true,
          defaultValue: data?.email,
        },
        {
          label: 'Mật khẩu',
          name: 'password',
          type: 'text',
          validate: {
            validate: (password) => {
              if (password && password.length > 0) {
                const minLength = password.length >= 8;
                const leastOneUppercase = /.*[A-Z].*/.test(password);
                const leastOneNumber = /.*[0-9].*/.test(password);
                if (!minLength || !leastOneUppercase || !leastOneNumber) {
                  return 'Mật khẩu phải có tối thiểu 8 kí tự, có ít nhất 1 kí tự viết hoa và 1 chữ số';
                }
              }
            },
          },
        },
      ],
    },
  ];
};

export const INPUT_ARTWORK = ({
  data,
  collections,
  artistIdDefault,
  tags,
  artists,
  productLines,
}) => {
  return [
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tên Artwork',
          validate: { required: true },
          name: 'name',
          type: 'text',
          defaultValue: data?.name,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Mã artwork',
          validate: { required: true },
          name: 'systemCode',
          type: 'text',
          options: artists,
          defaultValue: data?.systemCode,
        },
        {
          label: 'Chọn artist',
          name: 'artistId',
          type: 'select',
          options: artists,
          defaultValue: artistIdDefault,
          disabled: true,
        },
        {
          label: 'Chọn collection',
          name: 'collectionId',
          type: 'select',
          options: collections,
          defaultValue: data ? data.collection.id : collections[0]?.value,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'File preview (PNG, JPG)',
          name: 'previewImage',
          type: 'file',
          defaultValue: data ? data.image?.googleFileId : '',
          accept: 'image/*',
        },
        // {
        //   label: 'File gốc (.Ai, .PSD)',
        //   name: 'originalDesignFile',
        //   type: 'file',
        //   accept: '.psd,.ai',
        //   defaultValue: data?.originalDesignGoogleFileId,
        // },
        // {
        //   label: 'File Scan HĐ (PDF)',
        //   name: 'contractFile',
        //   type: 'file',
        //   accept: '.pdf',
        //   defaultValue: data?.contractGoogleFileId,
        // },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Link file gốc',
          name: 'originalDesignGoogleFileId',
          type: 'text',
          placeholder: 'Nhập link file gốc',
          defaultValue: data?.originalDesignGoogleFileId,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Câu chuyện gốc',
          name: 'originalStory',
          type: 'textarea',
          placeholder: 'Câu chuyện gốc giới thiệu artwork..',
          rows: 3,
          defaultValue: data?.originalStory,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Mô tả',
          name: 'description',
          type: 'textarea',
          placeholder: 'Viết mô tả giới thiệu artwork..',
          rows: 3,
          defaultValue: data?.description,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Profit (%)',
          validate: {
            required: true,
            validate: (value) => {
              const profit = Math.ceil(value);
              if (profit > 100) {
                return 'Profit không được lớn hơn 100';
              }
            },
          },
          name: 'profitPercentage',
          type: 'decimal',
          defaultValue: data?.profitPercentage,
        },
        {
          label: 'Chọn ngày mở bán',
          name: 'saleOpeningDate',
          type: 'date',
          defaultValue: data?.saleOpeningDate,
        },
        {
          label: 'Chọn ngày ký HĐ',
          name: 'contractSigningDate',
          type: 'date',
          defaultValue: data?.contractSigningDate,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Trạng thái',
          name: 'status',
          type: 'radio',
          options: ARTWORK_STATUS,
          defaultValue: data ? data.status : 'waiting',
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tag ',
          name: 'tagIds',
          type: 'multiSelect',
          options: tags,
          placeholder: 'Nhập tag',
          defaultValue: data?.tags,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Line sản phẩm',
          name: 'productLineIds',
          type: 'multiSelect',
          options: productLines,
          placeholder: 'Nhập Line sản phẩm',
          defaultValue: data?.productLines,
        },
      ],
    },
  ];
};

export const INPUT_LOG = ({ data }) => {
  return [
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Tiêu đề',
          validate: { required: true },
          name: 'name',
          type: 'text',
          width: '8',
          defaultValue: data?.name,
        },
        {
          label: 'Thời gian sự kiện',
          name: 'timestamp',
          type: 'date',
          width: '4',
          placeholder: 'Chọn thời gian',
          enableTime: true,
          defaultValue: data ? data?.timestamp : null,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          label: 'Mô tả',
          name: 'description',
          type: 'textarea',
          placeholder: 'Nhập mô tả',
          rows: 4,
          defaultValue: data?.description,
        },
      ],
    },
    {
      row: {
        className: 'row',
      },
      items: [
        {
          name: 'contacted',
          type: 'checkbox',
          rows: 4,
          defaultValue: data?.contacted,
          options: [
            {
              value: true,
              label: 'Liên hệ Artist',
              isToggle: true,
            },
          ],
        },
      ],
    },
  ];
};

export const COLUMNS_TABLE = [
  {
    label: 'STT',
    value: 'id',
  },
  {
    label: 'Nghệ danh',
    value: 'name',
  },
  {
    label: 'Mã Artist',
    value: 'systemCode',
  },
  {
    label: 'Trạng thái',
    value: 'status',
  },
  {
    label: 'Collection đang bán',
    value: 'collectionsCount',
  },
  {
    label: 'AW đang bán',
    value: 'artworksCount',
  },
  {
    label: 'Doanh thu TC',
    value: 'totalRevenueAfterDiscount',
  },
  {
    label: 'Tỉ trọng DT',
    value: 'revenueToTotalPercentage',
  },
  {
    label: 'LN Artist nhận',
    value: 'totalArtistProfit',
  },
  {
    label: 'Tỉ trọng LN',
    value: 'profitToTotalPercentage',
  },
  {
    label: 'Ký HĐ đầu tiên',
    value: 'firstContractSigningDate',
  },
  {
    label: 'Liên hệ gần nhất',
    value: 'mostRecentContactedEventDate',
  },
  {
    label: 'Ký HĐ gần nhất',
    value: 'mostRecentContractSigningDate',
  },
];
