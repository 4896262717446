import React from 'react';
import PropTypes from 'prop-types';

function ControlCustom(props) {
  return <div>ControlCustom</div>;
}

ControlCustom.propTypes = {};

export default ControlCustom;
