import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeadingTitle from '@Components/HeadingTitle';
import PeridDropdown from '@Components/PeriodDropdown';
import FlatPickrRange from '@Components/FlatPickrRange';
import { getFirstDateOfWeek, getLastDateOfWeek } from '@Utils/helper';
import { Container, Row, Col } from 'react-bootstrap';
import Summary from './components/Summary';
import ColumnChartArtist from './components/ColumnChartArtist';
import ColumnChartArtwork from './components/ColumnChartArtwork';
import LineChartProfit from './components/LineChartProfit';
import TableMostSoldArtworks from './components/TableMostSoldArtworks';
import TableMostProfitableArtists from './components/TableMostProfitableArtists';

function Dashboard() {
  const [period, setPeriod] = useState('this_year');
  const firstDate = getFirstDateOfWeek();
  const lastDate = getLastDateOfWeek();
  const [dateRange, setDateRange] = useState([
    firstDate.format('YYYY-MM-DD'),
    lastDate.format('YYYY-MM-DD'),
  ]);

  return (
    <div className="content-wrapper">
      <HeadingTitle title="Dashboard" />
      <div className="content-body">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="fw-bold fs-5">Tổng quan</div>
            <div className="d-flex align-items-center gap-14">
              <PeridDropdown period={period} setPeriod={setPeriod} />
              {period === 'optional' && (
                <FlatPickrRange
                  name="date-range"
                  placeholder="Chọn ngày"
                  date={dateRange}
                  setDate={setDateRange}
                  style={{ borderRadius: '100px' }}
                />
              )}
            </div>
          </div>
          <Summary period={period} dateRange={dateRange} />
          <Container fluid className="pt-4 px-0">
            <Row className="g-0 gap-20">
              <Col>
                <ColumnChartArtist period={period} dateRange={dateRange} />
              </Col>
              <Col>
                <ColumnChartArtwork period={period} dateRange={dateRange} />
              </Col>
            </Row>
          </Container>
          <Container fluid className="pt-4 px-0">
            <LineChartProfit period={period} dateRange={dateRange} />
          </Container>
          <Container fluid className="pt-4 px-0">
            <Row className="g-0 gx-3">
              <Col md={7}>
                <TableMostSoldArtworks period={period} dateRange={dateRange} />
              </Col>
              <Col md={5}>
                <TableMostProfitableArtists period={period} dateRange={dateRange} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
