import React from 'react';
import PropTypes from 'prop-types';
import { usePagination } from '@Utils/usePagination';
import { Pagination, Nav, ToggleButton, Dropdown } from 'react-bootstrap';
import shortid from 'shortid';
import { ChevronRight, ChevronLeft, ChevronDown } from 'react-feather';
import { useLocation, Link, useParams } from 'react-router-dom';

function Paginate({ data, pageSize = 15, setPageSize = () => {}, pageSizeName = 'page' }) {
  const { currentPage: current_page, lastPage: totalPage, perPage, total, to } = data;
  const paginationRange = usePagination({
    total: totalPage,
    siblingCount: 1,
    current_page,
  });

  const getPageLink = (index) => {
    const { pathname, search } = window.location;
    const urlParams = new URLSearchParams(search);
    urlParams.set(pageSizeName, index);
    return `${pathname}?${urlParams.toString()}`;
  };

  return (
    total !== 0 && (
      <div className="d-flex justify-content-between align-items-center pt-2">
        {pageSize && (
          <div className="d-flex align-items-center">
            <Dropdown className="pagination-per-page">
              <Dropdown.Toggle>
                <span className="me-2 fs-14 text-grey">{pageSize} per Page</span>
                <ChevronDown size={18} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-grey fs-14" onClick={() => setPageSize(15)}>
                  15 per Page
                </Dropdown.Item>
                <Dropdown.Item className="text-grey fs-14" onClick={() => setPageSize(25)}>
                  25 per Page
                </Dropdown.Item>
                <Dropdown.Item className="text-grey fs-14" onClick={() => setPageSize(35)}>
                  35 per Page
                </Dropdown.Item>
                <Dropdown.Item className="text-grey fs-14" onClick={() => setPageSize(50)}>
                  50 per Page
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <span className="fs-14 ms-2 text-grey">
              Đang xem {to} trên tổng {total} kết quả
            </span>
          </div>
        )}
        <div className="pagination-custom">
          <div className={`page-item ${current_page === 1 ? 'disabled' : ''}`}>
            <Link className="page-link" to={getPageLink(current_page - 1)} aria-label="First">
              <span>
                <ChevronLeft />
              </span>
            </Link>
          </div>
          {paginationRange.map((pagi) => {
            if (pagi === 'DOTS') {
              return (
                <Pagination.Item key={shortid.generate(3)}>
                  <div className="page-link">...</div>
                </Pagination.Item>
              );
            }
            return (
              <div className={`page-item ${pagi === current_page ? 'active' : ''} `} key={pagi}>
                <Link className="page-link" to={getPageLink(pagi)}>
                  <span>{pagi}</span>
                </Link>
              </div>
            );
          })}
          <div className={`page-item ${current_page === totalPage ? 'disabled' : ''}`}>
            <Link className="page-link" to={getPageLink(current_page + 1)} aria-label="Last">
              <span>
                <ChevronRight />
              </span>
            </Link>
          </div>
        </div>
      </div>
    )
  );
}

Paginate.propTypes = {
  data: PropTypes.object,
  query: PropTypes.object,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  pageSizeName: PropTypes.string,
};

export default Paginate;
