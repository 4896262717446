import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showGlobalLoading, hideGlobalLoading } from '@Libs/slices/uiSlice';
import { useQuery } from 'react-query';

export default function useCustomQuery({
  callApi,
  params,
  isShowGlobalLoading,
  enabled = true,
  refetchInterval,
}) {
  const conditions = {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchInterval,
    refetchOnReconnect: false,
    retry: 0,
    fetchPolicy: 'no-cache',
    enabled,
  };
  const res = useQuery([`${callApi.name}`, params], () => callApi(params), conditions);
  const dispatch = useDispatch();
  const { isLoading } = res;

  useEffect(() => {
    if (isShowGlobalLoading) {
      if (isLoading) {
        dispatch(showGlobalLoading());
      } else {
        dispatch(hideGlobalLoading());
      }
    }
  }, [isLoading]);

  return res;
}
