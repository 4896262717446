import React from 'react';
import PrivateLayout from '@Components/Layouts/PrivateLayout';
import PageNotFound from '@Pages/PageNotFound';
import { getSibarArtist } from '@Utils/sibarHelper';
import PageForbidden from '@Pages/PageForbidden';

export default function PrivateArtistRouter() {
  return [
    {
      path: '/',
      element: <PrivateLayout />,
      children: getSibarArtist(),
    },
    { path: '/forbidden', element: <PageForbidden /> },
    { path: '*', element: <PageNotFound /> },
  ];
}
