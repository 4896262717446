import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import FormInput from '@Components/FormInput';
import ButtonConfirm from '@Components/ButtonConfirm';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { INPUT_COLLECTION } from '@Constants/artworkConstants';
import { updateLog } from '@Apis/artistLog';
import { INPUT_LOG } from '@Constants/artistConstants';

function ModalLog({ isOpen, close, refetch, detail, artistId }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
    watch,
  } = useForm();

  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: updateLog,
    isShowGlobalLoading: true,
  });

  const onSubmit = (data) => {
    data.id = detail.id;
    data.artistId = artistId;
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Sửa ghi chú thành công');
      close();
      refetch();
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isOpen}
      onHide={close}
    >
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="fs-5 fw-bold text-grey">Sửa ghi chú</p>
          <X size={18} onClick={close} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            inputsForm={INPUT_LOG({
              data: detail,
            })}
            register={register}
            errors={errors}
            setValueForm={setValueForm}
            watch={watch}
          />
          <div className="d-flex gap-8">
            <ButtonConfirm
              variant="danger"
              name="Lưu lại"
              type="submit"
              className="fs-14 px-3"
              loading={isLoading}
            />
            <ButtonConfirm
              variant="outline-danger"
              className="fs-14 px-3"
              name="Huỷ"
              onClick={close}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

ModalLog.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  refetch: PropTypes.func,
  detail: PropTypes.object,
  artistId: PropTypes.number,
};

export default ModalLog;
