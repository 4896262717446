import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  mostProfitableArtistsPeriod,
  mostProfitableArtistsThisWeek,
  mostProfitableArtistsThisMonth,
  mostProfitableArtistsThisYear,
} from '@Apis/statisticTable';
import DataTableCustom from '@Components/DataTableCustom';
import useDashboardData from '@Utils/useDashboardData';
import columns from './ColumnsTable';

function TableMostProfitableArtists({ period, dateRange }) {
  const objectFunc = {
    this_year: mostProfitableArtistsThisYear,
    this_week: mostProfitableArtistsThisWeek,
    this_month: mostProfitableArtistsThisMonth,
    optional: mostProfitableArtistsPeriod,
  };
  const [dataTable, setDataTable] = useState({});
  const func = objectFunc[period];
  const data = useDashboardData({ func, dateRange });

  useEffect(() => {
    if (data) {
      const { currentPeriodResult } = data;
      setDataTable({
        data: currentPeriodResult,
      });
    }
  }, [data]);

  return (
    <div className="table-top-value-wrapper">
      <div className="fw-bold fs-5 mb-3">Top 10 Artist LN cao nhất</div>
      <DataTableCustom columns={columns()} data={dataTable} noPagination />
    </div>
  );
}

TableMostProfitableArtists.propTypes = { period: PropTypes.string, dateRange: PropTypes.array };

export default TableMostProfitableArtists;
