import React, { useState } from 'react';
import HeadingTitle from '@Components/HeadingTitle';
import useCustomQuery from '@Utils/useCustomQuery';
import { getArtists } from '@Apis/artist';
import DataTableCustom from '@Components/DataTableCustom';
import { useNavigate } from 'react-router-dom';
import { checkObjectEmpty } from '@Utils/helper';
import { COLUMNS_TABLE } from '@Constants/artistConstants';
import FilterArtist from './components/FilterArtist';
import columns from './components/ColumnsTable';

function Artist() {
  const [filter, setFilter] = useState({});
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(15);

  const localStorageName = 'COLUMNS_TABLE_ARTIST';
  let selectedOptions = [];
  const selectedSaved = localStorage.getItem(localStorageName);
  if (selectedSaved) {
    selectedOptions = JSON.parse(selectedSaved);
  }
  const inititalOptions = [
    {
      name: 'columns',
      inititialOptions: COLUMNS_TABLE,
      allOptions: [{ value: 'all', label: 'Tất cả các cột' }, ...COLUMNS_TABLE],
      selectedOptions,
    },
  ];
  const [columnsShow, setColumnsShow] = useState(inititalOptions);

  const { data } = useCustomQuery({
    callApi: getArtists,
    isShowGlobalLoading: true,
    params: filter,
    enabled: checkObjectEmpty(filter),
  });

  const onChangeSort = ({ order, orderBy }) => {
    if (order) {
      setFilter({
        ...filter,
        order,
        orderBy,
      });
    } else {
      const { order, orderBy, ...restFilter } = filter;
      setFilter(restFilter);
    }
  };

  return (
    <div className="content-wrapper">
      <HeadingTitle title="Quản lý Artist" />
      <div className="content-body">
        <div>
          <FilterArtist
            filter={filter}
            setFilter={setFilter}
            pageSize={pageSize}
            columnsShow={columnsShow}
            setColumnsShow={setColumnsShow}
            localStorageName={localStorageName}
          />
          {data && (
            <DataTableCustom
              columns={columns({
                order: filter.order,
                orderBy: filter.orderBy,
                onChangeSort,
                columnsShow,
              })}
              data={data}
              onRowClicked={(item) => navigate(`/artist/${item.id}`)}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          )}
        </div>
      </div>
    </div>
  );
}

Artist.propTypes = {};

export default Artist;
