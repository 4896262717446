/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { showGlobalLoading, hideGlobalLoading } from '@Libs/slices/uiSlice';
import { connect } from 'react-redux';
import { Title, BackButton } from './styles';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  async componentDidCatch(error, errorInfo) {
    this.props.hide();
    console.log(error.stack);
    // sendMessageToSlack(error.stack);
    this.setState({ errorInfo });
  }

  render() {
    const { name, url } = this.props;
    const { hasError, errorInfo } = this.state;

    const rediretToDashboard = () => {
      document.location = '/dashboard';
    };

    if (hasError) {
      return (
        <div>
          <div className="d-flex justify-content-center pt-4">
            <img src="/images/logo.png" width="166px" alt="logo" />
          </div>
          <div className="container">
            <div className="content-public-wrapper">
              <div className="d-flex justify-content-center align-items-center pt-5">
                <div className="row">
                  <div className="col-6 d-flex align-items-center justify-content-end">
                    <div style={{ width: '80%' }}>
                      <p className="fs-3 text-grey">
                        <b style={{ fontSize: '60px' }}>500 </b>
                        <span className="fw-normal">Error occured.</span>
                      </p>
                      <p className="fs-3 pt-4 fw-normal text-grey lh-sm">
                        Woops. Looks like this page doesn’t exist.
                      </p>
                      <p className="fs-3 pt-2 fw-normal text-grey">
                        back to
                        <BackButton onClick={() => rediretToDashboard()}> home</BackButton>
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <img src="/images/error.png" width="500" alt="page not found" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  name: PropTypes.string,
  url: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    hide: () => dispatch(hideGlobalLoading()),
    show: () => dispatch(showGlobalLoading()),
  };
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
