import styled from 'styled-components';

export const LeftSideWrapper = styled.div`
  width: 428px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  padding: 40px 0 20px;
`;

export const ImgLogo = styled.img`
  margin: auto;
  display: block;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 0 48px;
`;

export const BackWrrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 48px;
`;
