import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import ProfitIcon from '@Assets/images/dashboard/profit.png';
import TotalArtistIcon from '@Assets/images/dashboard/total-artist.png';
import SellingArtistIcon from '@Assets/images/dashboard/selling-artist.png';
import SellingArtworkIcon from '@Assets/images/dashboard/selling-artwork.png';
import PendingArtworkIcon from '@Assets/images/dashboard/pending-artwork.png';
import { period as summaryPeriod, thisYear, thisMonth, thisWeek } from '@Apis/statistic';
import { calcPeriodTrend, formatVND } from '@Utils/helper';
import useDashboardData from '@Utils/useDashboardData';
import { SummaryWrapper } from './styled';
import Trend from './Trend';

function Summary({ period, dateRange }) {
  const objectFunc = {
    this_year: thisYear,
    this_week: thisWeek,
    this_month: thisMonth,
    optional: summaryPeriod,
  };

  const objectSummaryItem = [
    {
      name: 'Tổng lợi nhuận Artist',
      property: 'artistProfit',
      icon: ProfitIcon,
    },
    {
      name: 'SL Artist đã hợp tác',
      property: 'totalArtistCount',
      icon: TotalArtistIcon,
    },
    {
      name: 'SL Artist đang bán',
      property: 'sellingArtistCount',
      icon: SellingArtistIcon,
    },
    {
      name: 'SL AW đang bán',
      property: 'sellingArtworkCount',
      icon: SellingArtworkIcon,
    },
    {
      name: 'SL AW chờ bán',
      property: 'pendingArtworkCount',
      icon: PendingArtworkIcon,
    },
  ];

  const func = objectFunc[period];
  const data = useDashboardData({ func, dateRange });

  return (
    <SummaryWrapper>
      <div className="d-flex align-items-center justify-content-between gap-3">
        {objectSummaryItem.map((item) => {
          const percent = calcPeriodTrend({ data, property: item.property });
          return (
            <div className="summary-item" key={item.property}>
              <Trend percent={percent} icon={item.icon} />
              <p className="text-grey fw-bold py-4">{item.name}</p>
              {item.property === 'artistProfit' ? (
                <p className="fs-5 fw-bold">
                  {formatVND(data?.currentPeriodNumbers[item.property])}
                </p>
              ) : (
                <p className="fs-5 fw-bold">{data?.currentPeriodNumbers[item.property] || 0}</p>
              )}
            </div>
          );
        })}
      </div>
    </SummaryWrapper>
  );
}

Summary.propTypes = {
  period: PropTypes.string,
  dateRange: PropTypes.array,
};

export default Summary;
