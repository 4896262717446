import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/statistics/numbers/summary`;

export const period = async ({ fromDate, toDate, artistId }) => {
  const res = await axiosService.get(`${url}/date-period`, { fromDate, toDate, artistId });
  return res.data;
};

export const thisWeek = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/this-week`, { artistId });
  return res.data;
};

export const thisMonth = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/this-month`, { artistId });
  return res.data;
};

export const thisYear = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/this-year`, { artistId });
  return res.data;
};
