import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/artwork-tags`;

export const getAllArtworkTag = async () => {
  const res = await axiosService.get(`${url}/all`);
  return res.data;
};

export const getArtworkTag = async (params) => {
  const res = await axiosService.get(url, params);
  return res.data;
};

export const updateArtworkTag = async (body) => {
  const { id, ...restBody } = body;
  const res = await axiosService.put(`${url}/${id}`, restBody);
  return res.data;
};

export const deleteArtworkTag = async ({ id }) => {
  const res = await axiosService.delete(`${url}/${id}`);
  return res.data;
};

export const createArtworkTag = async (body) => {
  const res = await axiosService.post(url, body);
  return res.data;
};
