import React, { useEffect, useState } from 'react';
import Logo from '@Assets/images/logo.png';
import { Link, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import MenuExpand from '@Assets/images/icons/menu-expand.png';
import MenuCollapse from '@Assets/images/icons/menu-collapse.png';
import { toggleSideBar } from '@Libs/slices/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import LogoMini from '@Assets/images/logo-mini.png';
import { getSibarUser, getSibarArtist } from '@Utils/sibarHelper';

function Sidebar() {
  const info = useSelector((state) => state.user.info);
  const [menu, setMenu] = useState([]);
  useEffect(() => {
    if (info) {
      const { userType } = info;
      let sidebar = [];
      if (userType === 'USER') {
        sidebar = getSibarUser({ permissions: info?.permissions });
      } else if (userType === 'ARTIST') {
        sidebar = getSibarArtist();
      }
      const menu = sidebar.filter((item) => !item.isHideSidebar);
      setMenu(menu);
    }
  }, [info]);
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const menuExpand = useSelector((state) => state.ui.menuExpand);

  const checkActiveMenu = (slug) => {
    const pathNameArr = pathname.split('/');
    const slugArr = slug.split('/');
    if (pathNameArr[1] === slugArr[1]) {
      return true;
    }
    return false;
  };

  return (
    <div className="main-menu menu-fixed menu-accordion" data-scroll-to-active="true">
      <div className="navbar-header my-3 text-center">
        {menuExpand ? (
          <img src={Logo} alt="logo" width="166px" />
        ) : (
          <img src={LogoMini} alt="menu-expand" width="40px" />
        )}
      </div>
      <div className="shadow-bottom" />
      <div className="main-menu-content pt-3">
        <div>
          {menu.map((item) => {
            const isActive = checkActiveMenu(item.path);
            return (
              <Link
                className="mb-4 d-flex align-items-center"
                to={item.path}
                key={`${item.path}-${item.name}`}
              >
                <img
                  src={isActive ? item.iconActive : item.icon}
                  alt={item.name}
                  width="18px"
                  height="100%"
                />
                {menuExpand && (
                  <p className={`mb-0 fs-14 ps-2 ${isActive ? 'text-danger' : ''}`}>{item.name}</p>
                )}
              </Link>
            );
          })}
        </div>
        <Nav.Link onClick={() => dispatch(toggleSideBar())} className="d-flex align-items-center">
          {menuExpand ? (
            <>
              <img src={MenuCollapse} alt="menu-collapse" width="32px" />
              <span className="fs-14 ms-2">Đóng menu</span>
            </>
          ) : (
            <img src={MenuExpand} alt="menu-expand" width="32px" />
          )}
        </Nav.Link>
      </div>
    </div>
  );
}

Sidebar.propTypes = {};

export default Sidebar;
