import React from 'react';
import PropTypes from 'prop-types';
import DownTrendIcon from '@Assets/images/dashboard/down-trend.png';
import UpTrendIcon from '@Assets/images/dashboard/up-trend.png';

function Trend({ icon, percent }) {
  return (
    <div className="trend">
      <img src={icon} alt="profit" className="trend-avatar" />
      {percent >= 0 && (
        <>
          <img src={UpTrendIcon} className="trend-icon" alt="up-trend" />
          <span className="text-success fw-bold">+{percent} %</span>
        </>
      )}
      {percent < 0 && (
        <>
          <img src={DownTrendIcon} className="trend-icon" alt="up-trend" />
          <span className="text-danger fw-bold">{percent} %</span>
        </>
      )}
      {percent === 'N/A' && <span className="text-danger fw-bold">{percent}</span>}
    </div>
  );
}

Trend.propTypes = {
  icon: PropTypes.string,
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Trend;
