import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HeadingTitle from '@Components/HeadingTitle';
import useCustomQuery from '@Utils/useCustomQuery';
import { processTagMultiOption } from '@Utils/helper';
import { createUser, detailUsers, updateUser } from '@Apis/user';
import useCustomMutation from '@Utils/useCustomMuation';
import { useForm } from 'react-hook-form';
import FormInput from '@Components/FormInput';
import ButtonConfirm from '@Components/ButtonConfirm';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { USER_ADDNEW_INPUT, USER_UPDATE_INPUT } from '@Constants/userConstants';
import { getAllRoles } from '@Apis/role';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-feather';
import ModalDeleteUser from '@Components/ModalDeleteUser';
import { usePermissions } from '@Utils/usePermissions';

function UserDetail(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [itemDelete, setItemDelete] = useState(null);

  const { data: detail, refetch } = useCustomQuery({
    callApi: detailUsers,
    isShowGlobalLoading: true,
    params: { id },
    enabled: id !== 'addnew',
  });
  const { data: roles } = useCustomQuery({
    callApi: getAllRoles,
    isShowGlobalLoading: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
    watch,
  } = useForm();

  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: createUser,
    isShowGlobalLoading: true,
  });

  const {
    mutate: mutateUpdate,
    isSuccess: isSuccessUpdate,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
  } = useCustomMutation({
    callApi: updateUser,
    isShowGlobalLoading: true,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    const { re_email, roleId, enabled, password, ...restData } = data;
    Object.keys(restData).map((key) => {
      if (restData[key]) {
        formData.append(key, restData[key]);
      }
    });
    formData.append('enabled', enabled === 'active' ? true : false);
    formData.append('roleId', parseInt(roleId));
    if (detail) {
      restData.id = detail.id;
      if (password) {
        formData.append('password', password);
      }
      mutateUpdate({ id, formData });
    } else {
      formData.append('password', password);
      mutate({ formData });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Tạo User thành công');
      navigate('/user');
      refetch();
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isSuccessUpdate) {
      toastSuccess('Sửa User thành công');
      navigate('/user');
      refetch();
    }
    if (isErrorUpdate) {
      toastError(errorUpdate);
    }
  }, [isSuccessUpdate, isErrorUpdate]);
  const showButtonDelete = usePermissions({ roleName: 'user', permission: 'delete' });

  return (
    <div className="content-wrapper">
      <HeadingTitle
        title={`${detail ? 'Chi tiết nhân viên' : 'Thêm nhân viên mới'}`}
        back="/user"
        subTitle={detail?.name}
      />

      <div className="content-body">
        <div className="p-4">
          <div className="d-flex align-items-center justify-content-between">
            <p className="fs-4 mb-4">Thông tin cá nhân</p>
            {showButtonDelete ? (
              <Button
                variant="outline-danger"
                className="fs-14 d-flex align-items-center"
                style={{ borderRadius: '20px' }}
                onClick={() => setItemDelete(detail)}
              >
                <Trash size={18} />
                <span className="ms-2"> Xoá nhân viên</span>
              </Button>
            ) : (
              ''
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="col-8">
            {detail ? (
              <FormInput
                inputsForm={USER_UPDATE_INPUT({
                  data: detail,
                  roles: processTagMultiOption(roles),
                  watch,
                })}
                register={register}
                errors={errors}
                setValueForm={setValueForm}
                watch={watch}
              />
            ) : (
              <FormInput
                inputsForm={USER_ADDNEW_INPUT({
                  data: detail,
                  roles: processTagMultiOption(roles),
                  watch,
                })}
                register={register}
                errors={errors}
                setValueForm={setValueForm}
                watch={watch}
              />
            )}

            <div className="d-flex gap-14">
              <ButtonConfirm
                variant="danger"
                className="fs-14 px-3"
                name="Lưu lại"
                type="submit"
                loading={isLoading}
              />
              <ButtonConfirm
                variant="outline-danger"
                className="fs-14 px-3"
                name="Huỷ"
                onClick={() => navigate('/user')}
              />
            </div>
          </form>
        </div>
        <ModalDeleteUser
          isOpen={itemDelete !== null}
          refetch={() => navigate('/user')}
          close={() => setItemDelete(null)}
          detail={itemDelete}
        />
      </div>
    </div>
  );
}

UserDetail.propTypes = {};

export default UserDetail;
