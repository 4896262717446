import React, { useEffect } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axiosService from '@Libs/axiosService';
import { getProfile } from '@Libs/slices/userSlice';
import AuthenWrapper from '@Components/AuthenWrapper';
import Sidebar from './Sidebar';
import UserDropDown from './UserDropDown';

function PrivateLayout() {
  const menuExpand = useSelector((state) => state.ui.menuExpand);
  return (
    <AuthenWrapper>
      <div
        className={`vertical-layout vertical-menu-modern navbar-floating footer-static ${
          menuExpand ? '' : 'menu-collapsed'
        }`}
      >
        <Sidebar />
        <div className="app-content content">
          <div className="content-overlay" />
          <div className="header-navbar navbar navbar-expand-lg header-absolute">
            <div className="navbar-container d-flex content">
              <UserDropDown />
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </AuthenWrapper>
  );
}

PrivateLayout.propTypes = {};

export default PrivateLayout;
