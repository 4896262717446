import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/artist/statistics/numbers/summary`;

export const period = async ({ fromDate, toDate }) => {
  const res = await axiosService.get(`${url}/date-period`, { fromDate, toDate });
  return res.data;
};

export const thisWeek = async () => {
  const res = await axiosService.get(`${url}/this-week`);
  return res.data;
};

export const thisMonth = async () => {
  const res = await axiosService.get(`${url}/this-month`);
  return res.data;
};

export const thisYear = async () => {
  const res = await axiosService.get(`${url}/this-year`);
  return res.data;
};
