import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Nav } from 'react-bootstrap';
import { OPTION_PERIOD } from '@Constants/constant';
import { ChevronDown } from 'react-feather';
import { PeriodDropdownWrapper } from './styled';

function PeridDropdown({ period, setPeriod }) {
  const getPeriodName = (value) => {
    const item = OPTION_PERIOD.find((item) => item.value === value);
    return item.label;
  };
  return (
    <PeriodDropdownWrapper>
      <Dropdown>
        <Dropdown.Toggle>
          <span className="fs-14 me-2">{getPeriodName(period)}</span>
          <ChevronDown size={18} color="#333" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {OPTION_PERIOD.map((option) => (
            <Dropdown.Item key={option.value} onClick={() => setPeriod(option.value)}>
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </PeriodDropdownWrapper>
  );
}

PeridDropdown.propTypes = { period: PropTypes.string, setPeriod: PropTypes.func };

export default PeridDropdown;
