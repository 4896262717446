import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInput from '@Components/Input';
import { useForm } from 'react-hook-form';
import EmailIcon from '@Assets/images/icons/email.png';
import PasswordInput from '@Components/Input/PasswordInput';
import ButtonConfirm from '@Components/ButtonConfirm';
import useCustomMutation from '@Utils/useCustomMuation';
import axiosService from '@Libs/axiosService';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFuncAuthen } from '@Utils/useFuncAuthen';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '@Libs/slices/userSlice';
import { Title } from '../styled';
import { SHOW_RECOVERY_PASSWORD } from '../constants';

function LeftSideLogin({ setView }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
  } = useForm();
  const location = useLocation();
  const { pathname } = location;
  const funcLogin = useFuncAuthen({ name: 'login' });
  const { mutate, isSuccess, isLoading, isError, data } = useCustomMutation({
    callApi: funcLogin,
  });
  const user = useSelector((state) => state.user);

  const onSubmit = (data) => {
    mutate(data);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      const { accessToken, refreshToken } = data;
      localStorage.setItem(process.env.TOKEN, accessToken);
      localStorage.setItem(process.env.REFRESH_TOKEN, refreshToken);
      if (pathname === '/login') {
        localStorage.setItem('USER_TYPE', 'USER');
      } else {
        localStorage.setItem('USER_TYPE', 'ARTIST');
      }
      axiosService.setHeader('Authorization', `Bearer ${accessToken}`);
      dispatch(getProfile());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (user) {
      const { defaultHomepage } = user;
      navigate(defaultHomepage);
    }
  }, [user]);

  return (
    <>
      <Title>Đăng nhập</Title>
      <form onSubmit={handleSubmit(onSubmit)} className="form-authen">
        <TextInput
          type="email"
          placeholder="Email"
          name="username"
          validate={{ required: true }}
          register={register}
          errors={errors}
          icon={EmailIcon}
          className="mb-4"
          setValueForm={setValueForm}
        />
        <PasswordInput
          name="password"
          placeholder="Password"
          register={register}
          validate={{ required: true }}
          errors={errors}
        />
        <div className="text-danger d-flex justify-content-end pt-4">
          <span onClick={() => setView(SHOW_RECOVERY_PASSWORD)} role="button" tabIndex={0}>
            Quên mật khẩu?
          </span>
        </div>
        {isError && <span className="text-danger fs-14">Email hoặc mật khẩu không đúng</span>}
        <ButtonConfirm
          type="submit"
          name="Đăng nhập"
          variant="danger"
          className="w-100 mt-5"
          loading={isLoading}
        />
      </form>
    </>
  );
}

LeftSideLogin.propTypes = {
  setView: PropTypes.func,
};

export default LeftSideLogin;
