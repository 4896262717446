import React from 'react';
import { formatVND, googleImageUrl } from '@Utils/helper';
import NoImage from '@Assets/images/no-image.png';

function columns() {
  return [
    {
      name: 'STT',
      selector: (row, index) => index + 1,
      width: '60px',
      center: true,
      compact: true,
    },
    {
      name: 'Mã AW',
      selector: (row) => row.systemCode,
      width: '100px',
    },
    {
      name: 'Artwork',
      selector: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="avatar-table-wrapper">
              {row.imageGoogleFileId ? (
                <img
                  src={googleImageUrl(row.imageGoogleFileId)}
                  alt={row.name}
                  data-tag="allowRowEvents"
                />
              ) : (
                <img src={NoImage} alt={row.name} />
              )}
            </div>
            <div className="ms-1">
              <p
                className="d-block fs-14 fw-semibold"
                data-tag="allowRowEvents"
                style={{ lineHeight: '20px' }}
              >
                {row.name}
              </p>
            </div>
          </div>
        );
      },
      grow: 4,
    },
    {
      name: 'SL bán',
      selector: (row) => row.totalSoldQuantity,
    },
    {
      name: 'Doanh thu (Sau CK)',
      selector: (row) => formatVND(row.totalRevenueAfterDiscount),
      minWidth: '150px',
      center: true,
    },
    {
      name: 'Lợi nhuận ',
      selector: (row) => formatVND(row.totalArtistProfit),
      center: true,
    },
    {
      name: 'Tỉ trọng',
      selector: (row) => `${row.profitRatio} %`,
      right: true,
    },
  ];
}

export default columns;
