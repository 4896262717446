import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/artists`;

export const getLogs = async (params) => {
  const { artistId, ...restParams } = params;
  const res = await axiosService.get(`${url}/${artistId}/events`, restParams);
  return res.data;
};

export const createLog = async (body) => {
  const { artistId, ...restBody } = body;
  const res = await axiosService.post(`${url}/${artistId}/events`, restBody);
  return res.data;
};

export const updateLog = async (body) => {
  const { id, artistId, ...restBody } = body;
  const res = await axiosService.put(`${url}/${artistId}/events/${id}`, restBody);
  return res.data;
};

export const deleteLog = async ({ id, artistId }) => {
  const res = await axiosService.delete(`${url}/${artistId}/events/${id}`);
  return res.data;
};
