export const ADD_NEW = 'add-new';

export const SIZE_SHIRT = [
  { label: 'S', value: 'S' },
  { label: 'M', value: 'M' },
  { label: 'L', value: 'L' },
  { label: 'XL', value: 'XL' },
  { label: 'XXL', value: 'XXL' },
];

export const OPTION_PERIOD = [
  {
    value: 'this_week',
    label: 'Tuần này',
  },
  {
    value: 'this_month',
    label: 'Tháng này',
  },
  {
    value: 'this_year',
    label: 'Năm nay',
  },
  {
    value: 'optional',
    label: 'Tùy chọn',
  },
];

export const PERMISSION_ROWS = [
  // ARTWORK
  {
    title: 'ARTWORK',
    className: 'fw-bolder',
  },
  {
    title: 'Xem thông tin Artwork',
    className: 'px-4 fw-bold',
  },
  {
    title: '• Cơ bản (Tên, Hình  Preview, Mô tả, Ngày mở bán)',
    className: 'px-4',
    entity: 'artwork',
    verb: 'read_basic',
  },
  {
    title: '• Nâng cao',
    className: 'px-4',
    entity: 'artwork',
    verb: 'read_advance',
  },
  {
    title: 'Tạo/nhập file Artwork',
    className: 'px-4 fw-bold',
    entity: 'artwork',
    verb: 'create',
  },
  {
    title: 'Sửa Artwork',
    className: 'px-4 fw-bold',
  },
  {
    title: '• Sửa thông tin cơ bản',
    className: 'px-4',
    entity: 'artwork',
    verb: 'update_basic',
  },
  {
    title: '• Sửa câu chuyện gốc',
    className: 'px-4',
    entity: 'artwork',
    verb: 'update_original_story',
  },
  {
    title: '• Sửa mô tả',
    className: 'px-4',
    entity: 'artwork',
    verb: 'update_description',
  },
  {
    title: '• Upload file Artwork preview',
    className: 'px-4',
    entity: 'artwork',
    verb: 'upload_preview_file',
  },
  {
    title: '• Upload file Artwork gốc',
    className: 'px-4',
    entity: 'artwork',
    verb: 'upload_original_file',
  },
  {
    title: 'Xoá Artwork',
    className: 'px-4 fw-bold',
    entity: 'artwork',
    verb: 'delete',
  },
  // ARTIST
  {
    title: 'ARTIST',
    className: 'fw-bolder',
  },
  {
    title: 'Xem thông tin Artist',
    className: 'px-4 fw-bold',
    entity: 'artist',
    verb: 'read',
  },
  {
    title: 'Tạo/ nhập file Artist',
    className: 'px-4 fw-bold',
    entity: 'artist',
    verb: 'create',
  },
  {
    title: 'Sửa thông tin Artist',
    className: 'px-4 fw-bold',
    entity: 'artist',
    verb: 'update',
  },
  {
    title: 'Xoá Artist',
    className: 'px-4 fw-bold',
    entity: 'artist',
    verb: 'delete',
  },
  // TÀI CHÍNH/ BÁO CÁO
  {
    title: 'TÀI CHÍNH/ BÁO CÁO',
    className: 'fw-bolder',
  },
  {
    title: 'Xem Dashboard',
    className: 'px-4 fw-bold',
    entity: 'dashboard',
    verb: 'read',
  },
  {
    title: 'Xem doanh thu, lợi nhuận',
    className: 'px-4 fw-bold',
    entity: 'statistics',
    verb: 'read',
  },
  {
    title: 'Nhâp file doanh thu, lợi nhuận',
    className: 'px-4 fw-bold',
    entity: 'statistics',
    verb: 'upload',
  },
  // NHÂN VIÊN
  {
    title: 'NHÂN VIÊN',
    className: 'fw-bolder',
  },
  {
    title: 'Xem danh sách nhân viên',
    className: 'px-4 fw-bold',
    entity: 'user',
    verb: 'read',
  },
  {
    title: 'Thêm nhân viên',
    className: 'px-4 fw-bold',
    entity: 'user',
    verb: 'create',
  },
  {
    title: 'Sửa nhân viên',
    className: 'px-4 fw-bold',
    entity: 'user',
    verb: 'update',
  },
  {
    title: 'Xoá nhân viên',
    className: 'px-4 fw-bold',
    entity: 'user',
    verb: 'delete',
  },
  // PHÂN QUYỀN
  {
    title: 'NHÓM QUYỀN & PHÂN QUYỀN',
    className: 'fw-bolder',
  },
  {
    title: 'Xem danh sách nhóm quyền',
    className: 'px-4 fw-bold',
    entity: 'role',
    verb: 'read',
  },
  {
    title: 'Thêm nhóm quyền',
    className: 'px-4 fw-bold',
    entity: 'role',
    verb: 'create',
  },
  {
    title: 'Sửa nhóm quyền',
    className: 'px-4 fw-bold',
    entity: 'role',
    verb: 'update',
  },
  {
    title: 'Xoá nhóm quyền',
    className: 'px-4 fw-bold',
    entity: 'role',
    verb: 'delete',
  },
  {
    title: 'Phân quyền',
    className: 'px-4 fw-bold',
    entity: 'role',
    verb: 'assign_permission',
  },
];
