import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeft } from 'react-feather';
import { NavLink, useNavigate } from 'react-router-dom';
import { HeadingTitleWrapper } from './styled';

function HeadingTitle({ title, back, subTitle, backHistory = false }) {
  const nav = useNavigate();
  return (
    <HeadingTitleWrapper>
      {back && (
        <NavLink to={back}>
          <ArrowLeft />
        </NavLink>
      )}
      {backHistory && (
        <NavLink onClick={() => nav(-1)}>
          <ArrowLeft />
        </NavLink>
      )}
      <span className="ms-2">{title}</span>
      {subTitle && <span className="text-grey d-block ms-1">{` ${subTitle}`}</span>}
    </HeadingTitleWrapper>
  );
}

HeadingTitle.propTypes = {
  title: PropTypes.string,
  back: PropTypes.string,
  subTitle: PropTypes.string,
  backHistory: PropTypes.bool,
};

export default HeadingTitle;
