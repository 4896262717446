/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import store from '@Libs/store';
import '@Assets/scss/styles.scss';
import 'flatpickr/dist/themes/light.css';
import GlobalLoading from '@Components/GlobalLoading';
import Routers from './routers';

const queryClient = new QueryClient();

export default function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Routers />
        <GlobalLoading />
        <ToastContainer />
      </QueryClientProvider>
    </Provider>
  );
}
