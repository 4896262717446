import { sideBarUser, sidebarArtist } from '@Constants/sidebarConstants';

export const getSibarUser = ({ permissions }) => {
  if (!permissions) {
    return [];
  }
  const siderBarPermission = [];

  sideBarUser.map((item) => {
    if (!item.key) {
      siderBarPermission.push(item);
      return;
    }
    const permissionArr = permissions[item.key];
    if (permissionArr) {
      const { children } = item;
      const childrenFilter = children.reduce((acc, child) => {
        const checkExist = permissionArr.find((item) => item.includes(child.key));
        if (checkExist) {
          acc.push(child);
        }
        return acc;
      }, []);
      item.children = childrenFilter;
      siderBarPermission.push(item);
    }
  });
  return siderBarPermission;
};

export const getSibarArtist = () => {
  return sidebarArtist;
};
