import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Col, Container, Row } from 'react-bootstrap';
import NoImage from '@Assets/images/no-image.png';
import ArtistIcon from '@Assets/images/artwork/artist-icon.png';
import ProjectIcon from '@Assets/images/artwork/project-icon.png';
import ArtworkStatus from '@Components/ArtworkStatus';
import dayjs from 'dayjs';
import { Delete, Edit3, Trash2 } from 'react-feather';
import { formatPercent, formatVND, googleFileDownloadUrl, googleImageUrl } from '@Utils/helper';
import FileReview from '@Assets/images/icons/file-review.png';
import useCustomQuery from '@Utils/useCustomQuery';
import { artworkDetail } from '@Apis/artwork';
import { useSelector } from 'react-redux';

function ModalArtworkDetail({
  isOpen,
  close,
  detailId,
  handleOpenModalEdit,
  handleOpenModalDelete,
}) {
  const [figureStyled, setFigureStyled] = useState({
    backgroundImage: '',
    backgroundPosition: '0% 0%',
  });
  const info = useSelector((state) => state.user.info);

  const { data } = useCustomQuery({
    callApi: artworkDetail,
    enabled: !!detailId,
    params: {
      id: detailId,
    },
  });

  const getDisplayState = ({ type }) => {
    if (!info) {
      return 'd-none';
    }
    const { permissions } = info;
    const { artwork } = permissions;
    if (artwork.includes('read_full')) {
      return '';
    }
    if (artwork.includes('read_basic') && type === 'read_basic') {
      return '';
    }
    if (artwork.includes('read_advance') && type === 'read_advance') {
      return '';
    }
    return 'd-none';
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setFigureStyled({
      backgroundPosition: `${x}% ${y}%`,
      backgroundImage: `url(${googleImageUrl(data.artwork.image.googleFileId)})`,
    });
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isOpen}
      onHide={close}
    >
      <Modal.Body>
        {data && (
          <div className="container-flud">
            <Row className="mb-3">
              <Col md="4" sm="6">
                <div className="image-artwork-detail">
                  {data.artwork.image ? (
                    <figure onMouseMove={handleMouseMove} style={figureStyled}>
                      <img src={googleImageUrl(data.artwork.image.googleFileId)} alt={data.name} />
                    </figure>
                  ) : (
                    <img src={NoImage} alt={data.artwork.name} />
                  )}
                </div>
              </Col>

              <Col md="8" sm="6">
                <p className="fs-4 fw-bold">{data.artwork.name}</p>
                <div
                  className={`d-flex align-items-center gap-4 py-3 ${getDisplayState({
                    type: 'read_advance',
                  })}`}
                >
                  <div className="d-flex align-items-center gap-8">
                    <img src={ArtistIcon} alt={data.artwork?.artist?.name} width="16px" />
                    <span className="fs-6 fw-bold text-grey">{data.artwork?.artist?.name}</span>
                  </div>
                  <div className="d-flex align-items-center gap-8">
                    <img src={ProjectIcon} alt={data.artwork?.collection?.name} width="16px" />
                    <span className="fs-6 fw-bold text-grey">{data.artwork?.collection?.name}</span>
                  </div>
                </div>
                <ArtworkStatus status={data.artwork.status} />
                <p className="fs-6 fw-500 py-3">{data.artwork.description}</p>
              </Col>
            </Row>
            <Row
              className={`mb-3 ${getDisplayState({
                type: 'read_advance',
              })}`}
            >
              <Col>
                <p className="fs-6 fw-semibold">Câu chuyện gốc:</p>
                <p className="fs-6 fw-normal">{data.artwork.originalStory}</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col
                className={`mb-3 ${getDisplayState({
                  type: 'read_advance',
                })}`}
              >
                <p className="fs-6 text-grey fw-semibold">Mã Artwork:</p>
                <p className="fs-6 fw-semibold">{data.artwork.systemCode}</p>
              </Col>
              <Col
                className={`mb-3 ${getDisplayState({
                  type: 'read_advance',
                })}`}
              >
                <p className="fs-6 text-grey fw-semibold">Ngày đăng:</p>
                <p className="fs-6 fw-semibold">
                  {dayjs(data.artwork.createdAt).format('DD/MM/YYYY')}
                </p>
              </Col>
              <Col
                className={`mb-3 ${getDisplayState({
                  type: 'read_advance',
                })}`}
              >
                <p className="fs-6 text-grey fw-semibold">Sửa gần nhất:</p>
                <p className="fs-6 fw-semibold">
                  {dayjs(data.artwork.updatedAt).format('DD/MM/YYYY')}
                </p>
              </Col>
              <Col
                className={`mb-3 ${getDisplayState({
                  type: 'read_advance',
                })}`}
              >
                <p className="fs-6 text-grey fw-semibold">Ngày ký HĐ:</p>
                <p className="fs-6 fw-semibold">
                  {data.artwork.contractSigningDate
                    ? dayjs(data.artwork.contractSigningDate).format('DD/MM/YYYY')
                    : ''}
                </p>
              </Col>
              <Col
                className={`mb-3 ${getDisplayState({
                  type: 'read_advance',
                })}`}
              >
                <p className="fs-6 text-grey fw-semibold">Mã HĐ:</p>
                <p className="fs-6 fw-semibold">{data.artwork.artist?.contractNumber}</p>
              </Col>
              <Col
                className={`mb-3 ${getDisplayState({
                  type: 'read_basic',
                })}`}
              >
                <p className="fs-6 text-grey fw-semibold">Ngày mở bán:</p>
                <p className="fs-6 fw-semibold">
                  {data.artwork.saleOpeningDate
                    ? dayjs(data.artwork.saleOpeningDate).format('DD/MM/YYYY')
                    : ''}
                </p>
              </Col>
            </Row>
            <Row
              className={`mb-3 ${getDisplayState({
                type: 'read_advance',
              })}`}
            >
              <Col>
                <p className="fs-6 text-grey fw-semibold">Profit:</p>
                <p className="fs-6 fw-semibold">{data.artwork.profitPercentage}</p>
              </Col>
              <Col>
                <p className="fs-6 text-grey fw-semibold">SL bán:</p>
                <p className="fs-6 fw-semibold">{data.statistics.soldCount}</p>
              </Col>
              <Col>
                <p className="fs-6 text-grey fw-semibold white-space-nowrap">Doanh thu TC:</p>
                <p className="fs-6 fw-semibold">
                  {formatVND(data.statistics.totalRevenueAfterDiscount)}
                </p>
              </Col>
              <Col>
                <p className="fs-6 text-grey fw-semibold">Tỉ trọng DT:</p>
                <p className="fs-6 fw-semibold">
                  {formatPercent(data.statistics.revenueToTotalPercentage)}
                </p>
              </Col>
              <Col>
                <p className="fs-6 text-grey fw-semibold">LN Artist:</p>
                <p className="fs-6 fw-semibold">{formatVND(data.statistics.totalArtistProfit)}</p>
              </Col>
              <Col>
                <p className="fs-6 text-grey fw-semibold">Tỉ trọng LN:</p>
                <p className="fs-6 fw-semibold">
                  {formatPercent(data.statistics.profitToTotalPercentage)}
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                className={`mb-3 ${getDisplayState({
                  type: 'read_basic',
                })}`}
                md="4"
              >
                <p className="fs-6 text-grey fw-semibold">File AW preview:</p>
                <div className="artwork-file-review" style={{ background: '#fef8ec' }}>
                  {data.artwork.image ? (
                    <a
                      className="file-content"
                      href={googleFileDownloadUrl(data.artwork.image.googleFileId)}
                      download
                    >
                      <img src={FileReview} alt="file-review" width="48px" />
                      <p className="fw-semibold ms-1">aw-preview</p>
                    </a>
                  ) : (
                    <p className="file-content fw-semibold ms-1">No file</p>
                  )}
                </div>
              </Col>
              {/* <Col
                className={`mb-3 ${getDisplayState({
                  type: 'read_advance',
                })}`}
                md="4"
              >
                <p className="fs-6 text-grey fw-semibold">File gốc AW:</p>
                <div className="artwork-file-review" style={{ background: '#edecf7' }}>
                  {data.artwork.originalDesignGoogleFileId ? (
                    <a
                      className="file-content"
                      href={googleFileDownloadUrl(data.artwork.originalDesignGoogleFileId)}
                      download
                    >
                      <img src={FileReview} alt="file-review" width="48px" />
                      <p className="fw-semibold ms-1">file-goc</p>
                    </a>
                  ) : (
                    <p className="file-content fw-semibold ms-1">No file</p>
                  )}
                </div>
              </Col>
              <Col
                className={`mb-3 ${getDisplayState({
                  type: 'read_advance',
                })}`}
                md="4"
              >
                <p className="fs-6 text-grey fw-semibold">File HĐ scan:</p>
                <div className="artwork-file-review" style={{ background: '#f6e7e6' }}>
                  {data.artwork.contractGoogleFileId ? (
                    <a
                      className="file-content"
                      href={googleFileDownloadUrl(data.artwork.contractGoogleFileId)}
                      download
                    >
                      <img src={FileReview} alt="file-review" width="48px" />
                      <p className="fw-semibold fs-14">file-HD-scan</p>
                    </a>
                  ) : (
                    <p className="file-content fw-semibold">No file</p>
                  )}
                </div>
              </Col> */}
            </Row>
            <Row
              className={`mb-3 ${getDisplayState({
                type: 'read_advance',
              })}`}
            >
              <Col className="d-flex align-items-center fw-semibold gap-8">
                <span className="fs-6 text-grey ">Link file gốc: </span>
                {data.artwork.originalDesignGoogleFileId && (
                  <a
                    className="file-content"
                    href={`https://drive.google.com/uc?id=${data.artwork.originalDesignGoogleFileId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="fw-semibold ms-1">{`https://drive.google.com/uc?id=${data.artwork.originalDesignGoogleFileId}`}</p>
                  </a>
                )}
              </Col>
            </Row>
            <Row
              className={`mb-3 ${getDisplayState({
                type: 'read_advance',
              })}`}
            >
              <Col className="d-flex align-items-center fw-semibold gap-8">
                <span className="fs-6 text-grey ">Tag: </span>
                {data.artwork.tags?.map((tag) => (
                  <span key={tag.id}>{tag.name}, </span>
                ))}
              </Col>
            </Row>
            <Row
              className={`mb-3 ${getDisplayState({
                type: 'read_advance',
              })}`}
            >
              <Col className="d-flex align-items-center fw-semibold gap-8">
                <span className="fs-6 text-grey ">Line sản phẩm: </span>
                {data.artwork.productLines?.map((productLine) => (
                  <span key={productLine.id}>{productLine.name}, </span>
                ))}
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Row className="">
          <Button
            variant="danger"
            className="w-auto fs-14 d-flex gap-8 align-items-center"
            onClick={handleOpenModalEdit}
          >
            <Edit3 size={16} />
            Sửa thông tin
          </Button>
          <Button
            variant="outline-danger"
            className="w-auto fs-14 d-flex gap-8 ms-2 align-items-center"
            onClick={handleOpenModalDelete}
          >
            <Trash2 size={16} />
            Xoá Artwork
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

ModalArtworkDetail.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  detailId: PropTypes.number,
  handleOpenModalEdit: PropTypes.func,
  handleOpenModalDelete: PropTypes.func,
};

export default ModalArtworkDetail;
