import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

function MultiSelectInput({
  style,
  allOptions,
  placeholder,
  handleOnChange,
  defaultValue,
  ...props
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      const options = defaultValue.map((item) => ({ value: item.id, label: item.name }));
      setSelectedOptions(options);
    }
  }, [defaultValue]);

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={(options) => {
        if (Array.isArray(options)) {
          setSelectedOptions(options);
        }
        handleOnChange(options.map((option) => option.value));
      }}
      isClearable={false}
      options={allOptions}
      value={selectedOptions}
      placeholder={placeholder}
      className="multi-select"
      {...props}
    />
  );
}

MultiSelectInput.propTypes = {
  style: PropTypes.object,
  allOptions: PropTypes.array,
  selectedOptions: PropTypes.any,
  setSelectedOptions: PropTypes.func,
  placeholder: PropTypes.string,
  handleOnChange: PropTypes.func,
  defaultValue: PropTypes.array,
  name: PropTypes.string,
};

export default MultiSelectInput;
