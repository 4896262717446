import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/statistics/tables`;
export const mostSoldArtworksPeriod = async ({ fromDate, toDate, artistId }) => {
  const res = await axiosService.get(`${url}/most-sold-artworks/date-period`, {
    fromDate,
    toDate,
    artistId,
  });
  return res.data;
};

export const mostSoldArtworksThisWeek = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/most-sold-artworks/this-week`, { artistId });
  return res.data;
};

export const mostSoldArtworksThisMonth = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/most-sold-artworks/this-month`, { artistId });
  return res.data;
};

export const mostSoldArtworksThisYear = async ({ artistId }) => {
  const res = await axiosService.get(`${url}/most-sold-artworks/this-year`, { artistId });
  return res.data;
};

export const mostProfitableArtistsPeriod = async ({ fromDate, toDate }) => {
  const res = await axiosService.get(`${url}/most-profitable-artists/date-period`, {
    fromDate,
    toDate,
  });
  return res.data;
};

export const mostProfitableArtistsThisWeek = async () => {
  const res = await axiosService.get(`${url}/most-profitable-artists/this-week`);
  return res.data;
};

export const mostProfitableArtistsThisMonth = async () => {
  const res = await axiosService.get(`${url}/most-profitable-artists/this-month`);
  return res.data;
};

export const mostProfitableArtistsThisYear = async () => {
  const res = await axiosService.get(`${url}/most-profitable-artists/this-year`);
  return res.data;
};
