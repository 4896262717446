import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import Lock from '@Assets/images/icons/lock.png';
import Visibility from '@Assets/images/icons/visibility.png';
import VisibilityOff from '@Assets/images/icons/visibilityOff.png';

function PasswordInput({
  register,
  errors,
  placeholder,
  style,
  validate,
  name,
  className,
  setValue,
}) {
  const [type, setType] = useState('password');
  const [isShowPass, setIsShowPass] = useState(false);

  const togglePassword = () => {
    const newStateShowPass = !isShowPass;
    setIsShowPass(newStateShowPass);
    if (newStateShowPass) {
      setType('text');
    } else {
      setType('password');
    }
  };

  const handleChange = (value) => {
    if (setValue) {
      setValue(value);
    }
  };

  const error = errors && errors[name];

  return (
    <Form.Group className={className}>
      <InputGroup>
        <InputGroup.Text>
          <img src={Lock} width="20px" alt={name} />
        </InputGroup.Text>
        {register ? (
          <Form.Control
            type={type}
            placeholder={placeholder}
            {...register(name, validate)}
            style={style}
          />
        ) : (
          <Form.Control
            type={type}
            placeholder={placeholder}
            style={style}
            onChange={(e) => handleChange(e.target.value)}
          />
        )}

        <InputGroup.Text onClick={() => togglePassword()}>
          <img src={isShowPass ? VisibilityOff : Visibility} width="20px" alt={name} />
        </InputGroup.Text>
      </InputGroup>
      {error && (
        <p className="text-danger mb-0 fs-14">
          {error.message ? error.message : 'Trường bắt buộc'}
        </p>
      )}
    </Form.Group>
  );
}

PasswordInput.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  validate: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
  setValue: PropTypes.func,
};

export default PasswordInput;
