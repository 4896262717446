import React from 'react';
import { useParams } from 'react-router-dom';
import { ADD_NEW } from '@Constants/constant';
import Addnew from './components/Addnew';
import Detail from './components/Detail';

function ArtistDetail() {
  const { id } = useParams();
  return <div className="content-wrapper">{id ? <Detail id={id} /> : <Addnew />}</div>;
}

ArtistDetail.propTypes = {};

export default ArtistDetail;
