import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import Input from '@Components/Input';

function FormInput({ inputsForm, errors, register, setValueForm }) {
  return (
    <div className="container-flud">
      {Object.entries(inputsForm).map(([key, value]) => {
        const { items, row } = value;
        return (
          <Row className={`${row && row.className} mb-4`} key={items[0].name}>
            {row && row.heading && <span className="fs-16 fw-500 mb-2">{row.heading}</span>}
            {items.map((input) => {
              return (
                <Col sm={input.width} key={input.name}>
                  <Input
                    errors={errors}
                    register={{ ...register(input.name, input.validate) }}
                    setValueForm={setValueForm}
                    {...input}
                  />
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
}

FormInput.propTypes = {
  inputsForm: PropTypes.array,
  errors: PropTypes.object,
  register: PropTypes.func,
  setValueForm: PropTypes.func,
};

export default FormInput;
