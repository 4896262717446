import React, { useEffect, useState } from 'react';
import PieChartIcon from '@Assets/images/icons/pie-chart-artist.png';
import PieChartActiveIcon from '@Assets/images/icons/pie-chart-artist-active.png';
import ArtworkIcon from '@Assets/images/icons/artwork-artist.png';
import ArtworkIconActive from '@Assets/images/icons/artwork-artist-active.png';
import LogActivityIcon from '@Assets/images/icons/log-activity-artist.png';
import LogActivityIconActive from '@Assets/images/icons/log-activity-artist-active.png';
import TabPanel from '@Components/TabPanel';
import { useParams } from 'react-router-dom';
import { usePermissions } from '@Utils/usePermissions';

import { DetailPanelWrapper } from '../styled';
import Statistic from './Statistic';
import Log from './Log';
import Artwork from './Artwork';

function DetailRightSide() {
  const { id } = useParams();
  const isReadDashboard = usePermissions({ roleName: 'dashboard', permission: 'read' });
  const isReadArtwork = usePermissions({ roleName: 'artwork', permission: 'read' });
  useEffect(() => {
    const tabs = [];
    if (isReadDashboard) {
      tabs.push({
        key: 'statistic',
        name: 'Thống kê',
        icon: PieChartIcon,
        iconActive: PieChartActiveIcon,
      });
    }
    if (isReadArtwork) {
      tabs.push({
        key: 'artwork',
        name: 'Artwork',
        icon: ArtworkIcon,
        iconActive: ArtworkIconActive,
      });
    }
    tabs.push({
      key: 'log',
      name: 'Log Activity',
      icon: LogActivityIcon,
      iconActive: LogActivityIconActive,
    });
    setTabs(tabs);
    setActiveKey(tabs[0].key);
  }, [isReadDashboard, isReadArtwork]);

  const [tabs, setTabs] = useState([]);
  const [activeKey, setActiveKey] = useState(null);

  return (
    <DetailPanelWrapper>
      <TabPanel tabs={tabs} activeKey={activeKey} setActiveKey={setActiveKey} />
      {activeKey === 'statistic' && <Statistic id={parseInt(id)} />}
      {activeKey === 'artwork' && <Artwork id={parseInt(id)} />}
      {activeKey === 'log' && <Log id={parseInt(id)} />}
    </DetailPanelWrapper>
  );
}

DetailRightSide.propTypes = {};

export default DetailRightSide;
