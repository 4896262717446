import React, { Suspense } from 'react';
import ErrorBoundary from '@Components/ErrorBoundary';
import { useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { getUserType } from '@Utils/helper';
import AuthenWrapper from '@Components/AuthenWrapper';
import PageNotFound from '@Pages/PageNotFound';
import PublicRouter from './Public';
import PrivateRouter from './Private';
import { publicConstant } from './Public/constants';

function Routes() {
  const pageNotFound = { path: '*', element: <PageNotFound /> };
  const routesPrivate = PrivateRouter();
  const routes = [...publicConstant, ...routesPrivate];
  routes.push(pageNotFound);
  const browserRouter = createBrowserRouter(routes);

  return (
    <ErrorBoundary>
      <Suspense>
        <AuthenWrapper>
          <RouterProvider router={browserRouter} />
        </AuthenWrapper>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Routes;
