import styled from 'styled-components';

export const ArtistEditWrapper = styled.div`
  .tab-edit {
    .nav-item {
    }
    .nav-item:nth-child(1) {
      .nav-link {
        border-top-left-radius: 40px;
        // border-right-radius: 40px;
      }
    }
    .nav-link.active {
      background: #f6e7e6;
      color: #a70e13;
      border-right: 3px solid #a70e13;
    }

    .nav-link {
      color: #1a202c;
      padding: 24px 40px;
      font-size: 14px;
      border-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      justify-content: start;
      img {
        width: 40px;
      }
      display: flex;
      align-items: center;
      line-height: 1.2rem;
    }

    > div {
      &:nth-child(2) {
        border-left: 1px solid #e2e8f0;
      }
    }
  }
  .artist-content {
    padding: 28px 40px;
  }
`;
