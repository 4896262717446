/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import HeadingTitle from '@Components/HeadingTitle';
import { FileUploader } from 'react-drag-drop-files';
import { importExcel } from '@Apis/revenue';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { RevenueWrapper } from './styled';
import CustomUpload from './CustomUpload';

function Revenue() {
  const fileTypes = ['XLSX', 'CSV'];
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  const { mutate, isSuccess, isLoading, isError, error, data } = useCustomMutation({
    callApi: importExcel,
    isShowGlobalLoading: true,
  });

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      mutate({
        formData,
      });
    }
  }, [file]);

  useEffect(() => {
    if (isError) {
      toastError(error);
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      const { failedExcelRowGroups } = data;
      if (failedExcelRowGroups.length > 0) {
        const message = failedExcelRowGroups.map((item) => item.reason.detail);
        toastError(message);
      } else {
        toastSuccess('Upload file thành công');
      }
    }
  }, [data]);

  return (
    <div className="content-wrapper">
      <HeadingTitle title="Quản lý doanh thu" />
      <div className="content-body">
        <div>
          <RevenueWrapper>
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              children={<CustomUpload file={file} />}
              classes="custom-upload"
            />
          </RevenueWrapper>
        </div>
      </div>
    </div>
  );
}

Revenue.propTypes = {};

export default Revenue;
