import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '@Components/Input';
import EmailIcon from '@Assets/images/icons/email.png';
import ArrowLeft from '@Assets/images/icons/arrow-left.png';
import ButtonConfirm from '@Components/ButtonConfirm';
import useCustomMutation from '@Utils/useCustomMuation';
import { useForm } from 'react-hook-form';
import { useFuncAuthen } from '@Utils/useFuncAuthen';
import { Title, BackWrrapper } from '../styled';
import { SHOW_LOGIN, SHOW_RECOVERY_PASSWORD_CONFIRM } from '../constants';

function LeftSideRecoveryPassword({ setView, setEmail }) {
  const funcGenerateResetCode = useFuncAuthen({ name: 'generateResetCode' });
  const { mutate, isSuccess, isLoading, isError } = useCustomMutation({
    callApi: funcGenerateResetCode,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
  } = useForm();

  const onSubmit = (data) => {
    mutate(data);
    setEmail(data.email);
  };

  useEffect(() => {
    if (isSuccess) {
      setView(SHOW_RECOVERY_PASSWORD_CONFIRM);
    }
  }, [isSuccess]);

  return (
    <>
      <BackWrrapper>
        <div className="d-flex align-items-center">
          <img src={ArrowLeft} alt="back" width="20px" className="mr-1" />
          <span onClick={() => setView(SHOW_LOGIN)} tabIndex={0} role="button" className="">
            Quay lại
          </span>
        </div>
      </BackWrrapper>
      <Title>Quên mật khẩu</Title>
      <form onSubmit={handleSubmit(onSubmit)} className="form-authen">
        <p className="text-grey mb-5">Nhập email của bạn để xác nhận thay đổi mật khẩu</p>

        <Input
          type="email"
          placeholder="Email"
          name="email"
          validate={{ required: true }}
          register={register}
          icon={EmailIcon}
          className="mb-2"
          errors={errors}
          setValueForm={setValueForm}
        />
        {isError && <span className="text-danger fs-14">Email không hợp lệ</span>}

        <ButtonConfirm
          type="submit"
          name="Gửi"
          variant="danger"
          className="w-100 mt-5"
          loading={isLoading}
        />
      </form>
    </>
  );
}

LeftSideRecoveryPassword.propTypes = {
  setView: PropTypes.func.isRequired,
  setEmail: PropTypes.func,
};

export default LeftSideRecoveryPassword;
