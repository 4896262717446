import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/orders`;

export const importExcel = async ({ formData }) => {
  const res = await axiosService.post(`${url}/importFromSapo`, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return res.data;
};
