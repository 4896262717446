import React from 'react';
import { formatVND, googleImageUrl } from '@Utils/helper';
import NoImage from '@Assets/images/no-image.png';

function columns() {
  return [
    {
      name: 'STT',
      selector: (row, index) => index + 1,
      width: '60px',
      center: true,
      compact: true,
    },
    {
      name: 'Artwork',
      selector: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="avatar-table-wrapper">
              {row.imageGoogleFileId ? (
                <img
                  src={googleImageUrl(row.imageGoogleFileId)}
                  alt={row.name}
                  data-tag="allowRowEvents"
                />
              ) : (
                <img src={NoImage} alt={row.name} />
              )}
            </div>
            <div className="ms-1">
              <p
                className="d-block fs-14 fw-semibold"
                data-tag="allowRowEvents"
                style={{ lineHeight: '20px' }}
              >
                {row.name}
              </p>
              <p className="d-block" style={{ lineHeight: '20px' }}>
                #{row.systemCode}
              </p>
            </div>
          </div>
        );
      },
      minWidth: '260px',
    },
    {
      name: 'Artist',
      selector: (row) => row.artistName,
    },
    {
      name: 'SL bán',
      selector: (row) => row.totalSoldQuantity,
    },
    {
      name: 'Doanh thu',
      selector: (row) => (
        <span className="text-success fw-semibold">{formatVND(row.totalArtistProfit)}</span>
      ),
      right: true,
    },
  ];
}
export default columns;
