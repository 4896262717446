import React, { useState } from 'react';
import HeadingTitle from '@Components/HeadingTitle';
import TabPanel from '@Components/TabPanel';
import Collection from './components/Collection';
import Tag from './components/Tag';
import ProductLine from './components/ProductLine';

function Category() {
  const [activeKey, setActiveKey] = useState('collection');
  const tabs = [
    {
      key: 'collection',
      name: 'COLLECTIONS',
    },
    {
      key: 'tag',
      name: 'TAGS',
    },
    {
      key: 'productLine',
      name: 'Line sản phẩm',
    },
  ];
  return (
    <div className="content-wrapper">
      <HeadingTitle title="Quản lý doanh mục" />
      <div className="content-body">
        <div>
          <TabPanel tabs={tabs} activeKey={activeKey} setActiveKey={setActiveKey} />
          {activeKey === 'collection' && <Collection />}
          {activeKey === 'tag' && <Tag />}
          {activeKey === 'productLine' && <ProductLine />}
        </div>
      </div>
    </div>
  );
}

Category.propTypes = {};

export default Category;
