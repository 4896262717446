import React from 'react';
import { toast } from 'react-toastify';
import { AlertCircle, CheckCircle } from 'react-feather';
import parse from 'html-react-parser';

export function toastSuccess(message) {
  toast(
    <div className="bg-white">
      <div className="toastify-header">
        <div className="title-wrapper d-flex align-items-center">
          <CheckCircle color="#28c76f" />
          <p className="toast-title font-weight-bolder text-success mb-0 ml-25">Success!</p>
        </div>
      </div>
      <div className="toastify-body mt-1">
        <span role="img" aria-label="toast-text text-info" className="fs-12">
          {message}
        </span>
      </div>
    </div>,
  );
}

const GetMessageText = ({ error }) => {
  if (typeof error === 'string') {
    return error;
  }
  let message = '';
  error.map((err) => {
    message += `<span className="d-block text-danger">* ${err}</span>`;
  });
  return parse(message);
};

export function toastError(error) {
  toast(
    <div className="bg-white">
      <div className="toastify-header">
        <div className="title-wrapper d-flex align-items-center">
          <AlertCircle color="#ea5455" size={18} />
          <p className="toast-title font-weight-bolder text-danger mb-0 ms-1">Error!</p>
        </div>
      </div>
      <div className="toastify-body mt-1">
        <span role="img" aria-label="toast-text text-info" className="fs-12">
          <GetMessageText error={error} />
        </span>
      </div>
    </div>,
  );
}
