import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FlatPickrDate from '@Components/FlatPickrDate';
import ButtonConfirm from '@Components/ButtonConfirm';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { massUpdateSaleOpeningDate } from '@Apis/artwork';
import dayjs from 'dayjs';

function ControlSaleOpeningDate({ items, refetch, clearSeletedRow, setAction }) {
  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: massUpdateSaleOpeningDate,
    isShowGlobalLoading: true,
  });
  const [date, setDate] = useState();

  const handleChangeDate = () => {
    const ids = items.map((item) => item.id);
    mutate({ ids, saleOpeningDate: dayjs(date).format('YYYY-MM-DD') });
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Cập nhập ngày mở bán thành công');
      refetch();
      clearSeletedRow();
      setAction();
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  return (
    <div className="ms-3 d-flex">
      <FlatPickrDate date={date} setDate={setDate} placeholder="Chọn ngày mở bán" />
      <ButtonConfirm
        variant="danger"
        disabled={!date}
        className="ms-3"
        onClick={() => handleChangeDate()}
        isLoading={isLoading}
        name="Xác nhận"
      />
    </div>
  );
}

ControlSaleOpeningDate.propTypes = {
  items: PropTypes.array,
  refetch: PropTypes.func,
  clearSeletedRow: PropTypes.func,
  setAction: PropTypes.func,
};

export default ControlSaleOpeningDate;
