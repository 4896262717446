import React from 'react';
import PrivateLayout from '@Components/Layouts/PrivateLayout';
import PageNotFound from '@Pages/PageNotFound';
import { getSibarUser } from '@Utils/sibarHelper';

export default function PrivateUserRouter({ info }) {
  if (info) {
    return [
      {
        path: '/',
        element: <PrivateLayout />,
        children: getSibarUser({ permissions: info ? info.permissions : {} }),
      },
      { path: '*', element: <PageNotFound /> },
    ];
  }
  return [
    {
      path: '/',
      element: <PrivateLayout />,
      children: getSibarUser({ permissions: info ? info.permissions : {} }),
    },
  ];
}
