import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import weekday from 'dayjs/plugin/weekday';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(customParseFormat);

export const processValueTextInput = ({ value, type }) => {
  let valueProcessed = value;
  if (type === 'phone' || type === 'number') {
    valueProcessed = parsePositiveNumber(value);
  }
  if (type === 'decimal') {
    valueProcessed = parseDecimal(value);
  }
  return valueProcessed;
};

export const parsePrice = (expression) => {
  if (expression) {
    return expression
      .toString()
      .replace(/[^0-9-]/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
};

export const parseDecimal = (expression) => {
  if (expression) {
    return expression.toString().replace(/[^0-9.-]/g, '');
  }
  return '';
};

export const parsePositiveNumber = (expression) => {
  if (expression) {
    return expression.toString().replace(/[^0-9-]/g, '');
  }
  return '';
};

export const checkIsEmail = (expression) => {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return expression.match(validRegex);
};

export const removeOptionValueAll = (options) => {
  const optionsWithoutAll = options.filter((item) => item.value !== 'all');
  return optionsWithoutAll;
};

export const checkObjectEmpty = (object) => {
  const objectStringify = JSON.stringify(object);
  if (objectStringify !== '{}') {
    return true;
  }
  return false;
};

export const processTagMultiOption = (options) => {
  if (!options) {
    return [];
  }
  const tagOptions = options.map((tag) => {
    return {
      value: tag.id,
      label: tag.name,
    };
  });
  return tagOptions;
};

export const processArtistMultiOption = (options) => {
  if (!options) {
    return [];
  }
  const tagOptions = options.map((tag) => {
    return {
      value: tag.id,
      label: tag.stageName,
    };
  });
  return tagOptions;
};

export const processTagsFilter = (tags) => {
  const tagsProcessed = tags.reduce((acc, current) => {
    if (current.values.length > 0) {
      acc.push(current);
    }
    return acc;
  }, []);
  return tagsProcessed;
};

export const processTagsFilterWhenClear = ({ option, valueNeedRemove }) => {
  const filterSelectedOptions = option.selectedOptions.filter(
    (item) => item.value !== valueNeedRemove && item.value !== 'all',
  );
  return filterSelectedOptions;
};

export const getOptionsByName = ({ inititalOptions, name }) => {
  if (!inititalOptions) {
    return [];
  }
  const options = inititalOptions.find((item) => item.name === name);
  return options;
};

export const dayjsNow = (date) => {
  const now = dayjs(date).add(-7, 'h');
  return now;
};

export const dayjsUtc = (date) => {
  const now = dayjs().add(-7, 'h');
  return now;
};

export const googleImageUrl = (imageId) => {
  return `https://drive.google.com/uc?id=${imageId}`;
};

export const googleFileDownloadUrl = (url) => {
  return `https://drive.google.com/uc?id=${url}&export=download`;
};

export const getUserType = () => {
  const userType = localStorage.getItem('USER_TYPE');
  return userType;
};

export const formatVND = (price) => {
  if (price === undefined || price === null) {
    return '-';
  }
  price = Math.trunc(price);
  if (price) {
    return `${price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
  }
  return '0';
};

export const calcPeriodTrend = ({ property, data }) => {
  if (!data) {
    return '';
  }
  const { currentPeriod, previousPeriod } = data;
  if (!previousPeriod || !currentPeriod) {
    return 'N/A';
  }
  const currentValue = currentPeriod[property];
  const previousValue = previousPeriod[property];
  if (!previousValue) {
    return 'N/A';
  }
  const percent = ((currentValue - previousValue) / previousValue) * 100;
  const perCentFormat = Math.round((percent + Number.EPSILON) * 2) / 2;
  return perCentFormat;
};

export const getFirstDateOfWeek = () => {
  const fistDayOfWeek = dayjs().weekday(1);
  return fistDayOfWeek;
};

export const getLastDateOfWeek = () => {
  const lastDayOfWeek = dayjs().weekday(7);
  return lastDayOfWeek;
};

export const getDatesFromPeriod = ({ fromDate, toDate }) => {
  const dateArr = [];
  const startDateObj = dayjs(fromDate);
  let currentDate = startDateObj;
  while (currentDate.diff(toDate, 'day') < 0) {
    currentDate = currentDate.add(1, 'day');
    dateArr.push(currentDate.format('YYYY-MM-DD'));
  }
  return dateArr;
};

export const dayCustomParse = ({ date, format }) => {
  return dayjs(date, format);
};

export const formatDecimal = ({ value, length = 2 }) => {
  if (value === undefined) {
    return '-';
  }
  return Math.round((value + Number.EPSILON) * length) / length;
};

export const getIndexTable = ({ index, ...props }) => {
  const { orderBy, order } = props;
  if (orderBy === 'id') {
    //
  }
  return index + 1;
};

export const getColumnsArtworkTable = ({ permissions, columnsTable }) => {
  if (!permissions) {
    return;
  }
  const { artwork } = permissions;
  if (artwork.includes('read_full')) {
    return columnsTable;
  }
  if (artwork.includes('read_basic')) {
    return columnsTable.filter((item) => item.role === 'read_basic');
  }
  if (artwork.includes('read_advance')) {
    return columnsTable.filter((item) => !item.role);
  }
};

export const handleProcessPermissions = ({ permissions }) => {
  if (!permissions) {
    return;
  }
  const { artwork, statistics } = permissions;
  if (artwork) {
    let artworkFilter = [...artwork];
    if (artwork.includes('read_basic') && artwork.includes('read_advance')) {
      artworkFilter = artworkFilter.filter(
        (item) => item !== 'read_basic' && item !== 'read_advance',
      );
      artworkFilter.push('read_full');
    }
    if (artwork.includes('read_basic') || artwork.includes('read_advance')) {
      artworkFilter.push('read');
    }
    permissions.artwork = artworkFilter;
    if (
      artwork.includes('update_basic') &&
      artwork.includes('update_description') &&
      artwork.includes('upload_original_file') &&
      artwork.includes('upload_preview_file') &&
      artwork.includes('update_original_story')
    ) {
      artworkFilter = artworkFilter.filter(
        (item) =>
          item !== 'update_basic' &&
          item !== 'update_description' &&
          item !== 'upload_original_file' &&
          item !== 'upload_preview_file' &&
          item !== 'update_original_story',
      );
      artworkFilter.push('update_full');
      permissions.artwork = artworkFilter;
    }
  }
  if (statistics) {
    const isUploadFile = statistics.includes('upload');
    if (isUploadFile) {
      permissions.revenue = ['update'];
    }
  }
  return permissions;
};

export const handleDefaultHomepage = ({ permissions }) => {
  if (!permissions) {
    return '/dashboard';
  }
  const { dashboard, ...restPermissions } = permissions;
  if (dashboard) {
    const isReadDashboard = dashboard.includes('read');
    if (isReadDashboard) {
      return '/dashboard';
    }
  }
  let mainPage = 'forbidden';
  const property = Object.keys(restPermissions);
  property.every((v) => {
    const isRead = restPermissions[v].includes('read');
    if (isRead) {
      mainPage = v;
      return true;
    }
    return false;
  });
  return `/${mainPage}`;
};

export const formatPercent = (percent) => {
  if (percent === undefined || percent === null) {
    return '-';
  }
  const percentFormat = Math.round((percent + Number.EPSILON) * 2) / 2;
  return `${percentFormat}%`;
};

export const bytesToMegaBytes = (bytes, roundTo) => {
  const converted = bytes / (1024 * 1024);
  return roundTo ? converted.toFixed(roundTo) : converted;
};
