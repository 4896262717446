import styled from 'styled-components';

export const FlatPickrWrapper = styled.div`
  width: 100%;
  .input-group {
    flex-wrap: inherit;
  }
  .flatpickr-input {
    border: none;
    border-radius: 0.375rem 0 0 0.375rem;
    border: 1px solid #ced4da;
    font-size: 14px;
  }
  .flatpickr-input.active {
  }
  .flatpickr-input:focus-visible {
    outline: none;
  }
  img {
    width: 18px;
  }
`;
