import { createSlice, createSelector } from '@reduxjs/toolkit';

const getStateMenuExpandFromLocalStorage = () => {
  const menuExpand = localStorage.getItem('MENU_EXPAND');
  if (menuExpand === 'true') {
    return true;
  }
  return false;
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isGlobalLoading: false,
    sidebar: [],
    notificationCount: 0,
    menuExpand: getStateMenuExpandFromLocalStorage(),
  },
  reducers: {
    pushNotificaiton: (state, action) => {
      state.notificationCount = action.payload;
    },
    showGlobalLoading: (state) => {
      state.isGlobalLoading = true;
    },
    hideGlobalLoading: (state) => {
      state.isGlobalLoading = false;
    },
    setSideBar: (state, action) => {
      const sidebar = action.payload;
      state.sidebar = sidebar;
    },
    sidebarActive: (state, action) => {
      const sidebarId = action.payload;

      const { sidebar } = state;
      const setActiveSidebar = sidebar.map((item) => {
        if (item.id === sidebarId) {
          item.isActive = true;
          item.isOpen = true;
        } else {
          item.isActive = false;
          item.isOpen = false;
        }

        if (item.child) {
          const activeChild = item.children.map((child) => {
            const isActive = false;
            return { ...child, isActive };
          });
          item.child = activeChild;
        }

        return item;
      });
      state.sidebar = [...setActiveSidebar];
    },
    toggleMenuHasChild: (state, action) => {
      const sideBarItem = action.payload;
      const { sidebar } = state;
      const setActiveSidebar = sidebar.map((item) => {
        if (item.id === sideBarItem.id) {
          item.isOpen = !item.isOpen;
        } else {
          item.isOpen = false;
        }
        return item;
      });
      state.sidebar = [...setActiveSidebar];
    },
    sidebarChildActive: (state, action) => {
      const { parentId, childId } = action.payload;
      const { sidebar } = state;
      const setActiveSidebar = sidebar.map((item) => {
        if (item.id === parentId) {
          item.isOpen = true;
          item.isActive = true;
          const activeChild = item.children.map((child) => {
            let isActive = false;
            if (child.id === childId) {
              isActive = true;
            }
            return { ...child, isActive };
          });
          item.children = activeChild;
        } else {
          item.isOpen = false;
          item.isActive = false;
        }
        return item;
      });

      state.sidebar = [...setActiveSidebar];
    },
    toggleSideBar: (state) => {
      localStorage.setItem('MENU_EXPAND', !state.menuExpand);
      state.menuExpand = !state.menuExpand;
    },
  },
});

const { reducer } = uiSlice;

export const selectUi = createSelector(
  (state) => ({
    isGlobalLoading: state.ui.isGlobalLoading,
  }),
  (state) => state,
);

export const {
  showGlobalLoading,
  hideGlobalLoading,
  setSideBar,
  sidebarActive,
  toggleMenuHasChild,
  sidebarChildActive,
  pushNotificaiton,
  toggleSideBar,
} = uiSlice.actions;

export default reducer;
