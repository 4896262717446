/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError } from '@Utils/toastrHelper';
import { useFuncAuthen } from '@Utils/useFuncAuthen';

function Completionist({ resendCode }) {
  return (
    <span
      className="text-center d-block pt-4 text-danger fw-bolder"
      role="button"
      onClick={() => resendCode()}
      tabIndex={0}
    >
      Gửi lại mã
    </span>
  );
}
function LeftSideRecoveryPasswordConfirmCountDown({ email }) {
  const [countDownTime, setCountDownTime] = useState(Date.now() + 120000);
  const [key, setKey] = useState(1);
  const funcGenerateResetCode = useFuncAuthen({ name: 'generateResetCode' });
  const { mutate, isError, error } = useCustomMutation({
    callApi: funcGenerateResetCode,
  });

  const resendCode = () => {
    setCountDownTime(countDownTime + 120000);
    setKey(key + 1);
    mutate({
      email,
    });
  };

  useEffect(() => {
    if (isError) {
      toastError(error);
    }
  }, [isError]);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist resendCode={resendCode} />;
    }
    return (
      <span className="text-center d-block pt-4" key={key}>
        Gửi lại mã sau {minutes}:{seconds}
      </span>
    );
  };

  return <Countdown date={countDownTime} renderer={renderer} key={key} />;
}

LeftSideRecoveryPasswordConfirmCountDown.propTypes = {
  email: PropTypes.string,
};

export default LeftSideRecoveryPasswordConfirmCountDown;
