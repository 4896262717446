/* eslint-disable no-confusing-arrow */
import React from 'react';
import NoImage from '@Assets/images/no-image.png';
import dayjs from 'dayjs';
import ArtworkStatus from '@Components/ArtworkStatus';
import TableSortColumn from '@Components/DataTableCustom/TableSortColumn';
import {
  formatDecimal,
  formatPercent,
  formatVND,
  getColumnsArtworkTable,
  googleImageUrl,
} from '@Utils/helper';
import ImageHover from '@Components/ImageHover';

function columns({ ...props }) {
  const { columnsShow, info } = props;
  let selectedColumns = [];
  if (columnsShow && columnsShow[0]) {
    selectedColumns = columnsShow[0].selectedOptions;
  }
  const initialColumns = [
    {
      name: 'Artwork',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="avatar-table-wrapper">
              {row.artwork.image?.googleFileId ? (
                <ImageHover imgSrc={row.artwork.image?.googleFileId} alt={row.artwork.name} />
              ) : (
                // <img
                //   src={googleImageUrl(row.artwork.image?.googleFileId)}
                //   alt={row.artwork.name}
                //   data-tag="allowRowEvents"
                // />
                <img src={NoImage} alt={row.artwork.name} />
              )}
            </div>
            <b className="d-block fs-14 fw-bold ms-1" data-tag="allowRowEvents">
              {row.artwork.name}
            </b>
          </div>
        );
      },
      minWidth: '180px',
      key: 'artwork',
      role: 'read_basic',
    },
    {
      name: 'Mã AW',
      selector: (row) => row.artwork.systemCode,
      key: 'systemCode',
    },
    {
      name: 'Trạng thái',
      selector: (row) => <ArtworkStatus status={row.artwork.status} />,
      key: 'status',
    },
    {
      name: 'Artist',
      selector: (row) => row.artwork.artist?.stageName,
      key: 'artistName',
    },
    {
      name: 'Collection',
      selector: (row) => row.artwork.collection?.name,
      key: 'collectionName',
    },
    {
      name: 'Mã HĐ',
      selector: (row) => row.artwork.artist?.contractNumber,
      key: 'contractNumber',
    },
    {
      name: 'Ngày ký HĐ',
      selector: (row) =>
        row.artwork.contractSigningDate
          ? dayjs(row.artwork.contractSigningDate).format('DD/MM/YYYY')
          : '',
      key: 'contractSigningDate',
    },
    {
      name: 'Ngày mở bán',
      selector: (row) =>
        row.artwork.saleOpeningDate ? dayjs(row.artwork.saleOpeningDate).format('DD/MM/YYYY') : '',
      key: 'saleOpeningDate',
      role: 'read_basic',
    },
    {
      name: 'SL bán',
      selector: (row) => row.statistics.soldCount,
      key: 'soldCount',
    },
    {
      name: 'Profit %',
      selector: (row) => row.artwork.profitPercentage,
      key: 'profit',
    },
    {
      name: 'Doanh thu TC',
      selector: (row) => formatVND(row.statistics.totalRevenueAfterDiscount),
      key: 'totalRevenueAfterDiscount',
    },
    {
      name: 'Tỉ trọng DT',
      selector: (row) => formatDecimal({ value: row.statistics.revenueToTotalPercentage }),
      key: 'revenueToTotalPercentage',
    },
    {
      name: 'Tỉ trọng LN',
      selector: (row) => formatDecimal({ value: row.statistics.profitToTotalPercentage }),
      key: 'profitToTotalPercentage',
    },
    {
      name: <TableSortColumn columnName="Ngày tạo" defaultOrderBy="createdAt" {...props} />,
      selector: (row) => dayjs(row.artwork.createdAt).format('DD/MM/YYYY'),
      key: 'createdAt',
    },
    {
      name: <TableSortColumn columnName="Sửa gần nhất" defaultOrderBy="updatedAt" {...props} />,
      selector: (row) => dayjs(row.artwork.updatedAt).format('DD/MM/YYYY'),
      width: '110px',
      key: 'updatedAt',
    },
  ];

  const initialColumnsPermission = getColumnsArtworkTable({
    permissions: info?.permissions,
    columnsTable: initialColumns,
  });
  if (selectedColumns.length === 0) {
    return initialColumnsPermission;
  }
  const columns = initialColumnsPermission.reduce((acc, column) => {
    const checkShow = selectedColumns.find((item) => item.value === column.key);
    if (checkShow) {
      acc.push(column);
    }
    return acc;
  }, []);
  return columns;
}

export default columns;
