import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PaginateEffect from '@Components/PaginateEffect';
import NoImage from '@Assets/images/no-image.png';
import { googleImageUrl } from '@Utils/helper';
import ArtworkStatus from '@Components/ArtworkStatus';
import { ImageArtworkWrapper } from './styled';

function DataArtworkGrid({ page, setPage, pageSize, setPageSize, data, onRowClicked }) {
  const [pagi, setPaginate] = useState(null);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    if (data) {
      const { data: dataTable, meta } = data;
      setDataTable(dataTable);
      setPaginate(meta);
    }
  }, [data]);

  return (
    <div className="">
      <div className="row">
        {dataTable.map((detail) => {
          return (
            <div
              className="col-3"
              onClick={() => onRowClicked(detail)}
              tabIndex={0}
              role="button"
              key={detail.id}
            >
              <ImageArtworkWrapper>
                <div className="image-artwork-wrapper mb-2">
                  {detail.artwork.image?.googleFileId ? (
                    <img
                      src={googleImageUrl(detail.artwork.image?.googleFileId)}
                      alt={detail.name}
                      className="w-100"
                    />
                  ) : (
                    <img src={NoImage} alt={detail.name} />
                  )}
                </div>
                <ArtworkStatus status={detail.status} />
                <p className="fs-14 fw-semibold">{detail.name}</p>
              </ImageArtworkWrapper>
            </div>
          );
        })}
      </div>

      {pagi && (
        <PaginateEffect
          data={pagi}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
}

DataArtworkGrid.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  data: PropTypes.object,
  onRowClicked: PropTypes.func,
};

export default DataArtworkGrid;
