import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PasswordInput from '@Components/Input/PasswordInput';
import useCustomMutation from '@Utils/useCustomMuation';
import ButtonConfirm from '@Components/ButtonConfirm';
import { Check } from 'react-feather';
import { useFuncAuthen } from '@Utils/useFuncAuthen';
import { Title } from '../styled';
import { SHOW_LOGIN } from '../constants';

function LeftSideNewPassword({ setView, tokenReset }) {
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordConditions, setPasswordConditions] = useState({
    minLength: false,
    leastOneUppercase: false,
    leastOneNumber: false,
  });
  const [errorMessage, setErrorMessage] = useState();

  const [password, setPassword] = useState();
  const [rePassword, setRePassword] = useState();
  const funcResetPassword = useFuncAuthen({ name: 'resetPassword' });
  const { mutate, isSuccess, isLoading, isError, data } = useCustomMutation({
    callApi: funcResetPassword,
  });

  useEffect(() => {
    if (isSuccess) {
      setView(SHOW_LOGIN);
    }
    if (isError) {
      setErrorMessage('Có lỗi xảy ra');
    }
  }, [isSuccess, isError]);

  const handleConfirm = () => {
    if (password === rePassword) {
      mutate({
        password,
        token: tokenReset,
      });
      setErrorMessage();
    } else {
      setErrorMessage('Mật khẩu không trùng khớp');
    }
  };

  useEffect(() => {
    if (password) {
      const minLength = password.length >= 8;
      const leastOneUppercase = /.*[A-Z].*/.test(password);
      const leastOneNumber = /.*[0-9].*/.test(password);
      setPasswordConditions({
        minLength,
        leastOneUppercase,
        leastOneNumber,
      });
      if (minLength && leastOneUppercase && leastOneNumber) {
        setPasswordValid(true);
      }
    }
  }, [password]);

  return (
    <>
      <Title>Nhập mật khẩu mới</Title>
      <PasswordInput
        name="password"
        placeholder="Nhập mật khẩu mới"
        className="mb-4"
        setValue={setPassword}
      />
      <PasswordInput name="re-password" placeholder="Xác nhận mật khẩu" setValue={setRePassword} />
      <div className="d-flex algin-items-center justify-content-between pt-4">
        <div className="d-flex algin-items-center">
          <Check color={`${passwordConditions.minLength ? '#219653' : 'black'}`} />
          <span className={`${passwordConditions.minLength ? 'text-success' : ''}`}>
            Tối thiểu 8 ký tự
          </span>
        </div>
        <div className="d-flex algin-items-center">
          <Check color={`${passwordConditions.leastOneUppercase ? '#219653' : 'black'}`} />
          <span className={`${passwordConditions.leastOneUppercase ? 'text-success' : ''}`}>
            Có ký tự viết hoa
          </span>
        </div>
        <div className="d-flex algin-items-center">
          <Check color={`${passwordConditions.leastOneNumber ? '#219653' : 'black'}`} />
          <span className={`${passwordConditions.leastOneNumber ? 'text-success' : ''}`}>
            Có chứa số
          </span>
        </div>
      </div>
      {errorMessage && <div className="text-danger text-center pt-2">{errorMessage}</div>}
      <ButtonConfirm
        type="button"
        name="Xác nhận"
        variant="danger"
        className="w-100 mt-4"
        loading={isLoading}
        onClick={() => handleConfirm()}
        disabled={!passwordValid}
      />
    </>
  );
}

LeftSideNewPassword.propTypes = {
  setView: PropTypes.func,
  tokenReset: PropTypes.string,
};

export default LeftSideNewPassword;
