import React from 'react';
import { Navigate } from 'react-router-dom';
import Dashboard from '@Pages/Dashboard';
import DashboardArtist from '@PagesArtist/Dashboard';

import Artist from '@Pages/Artist';
import ArtistDetail from '@Pages/ArtistDetail';
import ArtistEdit from '@Pages/ArtistEdit';
import Artwork from '@Pages/Artwork';
import User from '@Pages/User';
import Revenue from '@Pages/Revenue';
import Category from '@Pages/Category';
import UserDetail from '@Pages/UserDetail';
import DashboardIcon from '@Assets/images/icons/dashboard.png';
import DashboardIconActive from '@Assets/images/icons/dashboard-active.png';
import ArtistIcon from '@Assets/images/icons/artist.png';
import ArtistIconActive from '@Assets/images/icons/artist-active.png';
import ArtworkIcon from '@Assets/images/icons/artwork.png';
import ArtworkIconActive from '@Assets/images/icons/artwork-active.png';
import CategoryIcon from '@Assets/images/icons/category.png';
import CategoryIconActive from '@Assets/images/icons/category-active.png';
import UserIcon from '@Assets/images/icons/user.png';
import UserIconActive from '@Assets/images/icons/user-active.png';
import RevenueIcon from '@Assets/images/icons/revenue.png';
import RevenueIconActive from '@Assets/images/icons/revenue-active.png';
import InfoUpdate from '@PagesArtist/InfoUpdate';
import PageForbidden from '@Pages/PageForbidden';

export const sideBarUser = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
        key: 'read',
      },
    ],
    icon: DashboardIcon,
    iconActive: DashboardIconActive,
    key: 'dashboard',
  },
  {
    name: 'Quản lý Artist',
    path: '/artist',
    children: [
      {
        path: '/artist',
        element: <Artist />,
        key: 'read',
      },
      {
        path: '/artist/:id',
        element: <ArtistDetail />,
        key: 'read',
      },
      {
        path: '/artist/add-new',
        element: <ArtistDetail />,
        key: 'create',
      },
      {
        path: '/artist/edit/:id',
        element: <ArtistEdit />,
        key: 'update',
      },
    ],
    icon: ArtistIcon,
    iconActive: ArtistIconActive,
    key: 'artist',
  },
  {
    name: 'Quản lý Artwork',
    path: '/artwork',
    children: [{ path: '/artwork', element: <Artwork />, key: 'read' }],
    icon: ArtworkIcon,
    iconActive: ArtworkIconActive,
    key: 'artwork',
  },
  {
    name: 'Quản lý nhân viên',
    path: '/user',
    children: [
      {
        path: '/user',
        element: <User />,
        key: 'read',
      },
      {
        path: '/user/:id',
        element: <UserDetail />,
        key: 'read',
      },
    ],
    icon: UserIcon,
    iconActive: UserIconActive,
    key: 'user',
  },
  {
    name: 'Quản lý file doanh thu',
    path: '/revenue',
    children: [
      {
        path: '/revenue',
        element: <Revenue />,
        key: 'update',
      },
    ],
    icon: RevenueIcon,
    iconActive: RevenueIconActive,
    key: 'revenue',
  },
  {
    name: 'Quản lý danh mục',
    path: '/category',
    children: [
      {
        path: '/category',
        element: <Category />,
      },
    ],
    icon: CategoryIcon,
    iconActive: CategoryIconActive,
  },
  { path: '/forbidden', element: <PageForbidden /> },
];

export const sidebarArtist = [
  {
    path: '/',
    name: 'Dashboard',
    element: <Navigate to="/dashboard" />,
    isHideSidebar: true,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    children: [
      {
        path: '/dashboard',
        element: <DashboardArtist />,
      },
      {
        path: '/dashboard/artist',
        element: <InfoUpdate />,
      },
    ],
    icon: DashboardIcon,
    iconActive: DashboardIconActive,
  },
];
