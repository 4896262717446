import React from 'react';
import PropTypes from 'prop-types';
import { ACCOUNT_INFO } from '@Constants/artistUserContants';
import { useForm } from 'react-hook-form';
import FormInput from '@Components/FormInput';
import ButtonConfirm from '@Components/ButtonConfirm';
import { useNavigate } from 'react-router-dom';

function Account({ data, mutate, isLoading }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
    watch,
  } = useForm();

  const onSubmit = (dataUpdate) => {
    const { password } = dataUpdate;
    const formData = new FormData();
    formData.append('password', password);
    if (password) {
      mutate({
        id: data.id,
        formData,
      });
    } else {
      mutate({ id: data.id });
    }
  };

  return (
    <div className="artist-content">
      <div className="mb-4">
        <p className="fs-4 mb-4">Tài khoản & mật khẩu</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            inputsForm={ACCOUNT_INFO({ data })}
            register={register}
            errors={errors}
            setValueForm={setValueForm}
            watch={watch}
          />
          <div className="d-flex gap-8">
            <ButtonConfirm variant="danger" name="Lưu lại" type="submit" loading={isLoading} />
            <ButtonConfirm
              variant="outline-danger"
              name="Huỷ"
              onClick={() => navigate('/dashboard')}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

Account.propTypes = { data: PropTypes.object, mutate: PropTypes.func, isLoading: PropTypes.bool };

export default Account;
