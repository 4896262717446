import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { formatVND } from '@Utils/helper';

function BasicColumnChart({ data, seriesFirst, seriesSecond, name }) {
  const getDataAxisByProperty = (data, property) => {
    return data.map((item) => item[property]);
  };

  const series = [
    {
      name: seriesFirst.name,
      data: getDataAxisByProperty(data, seriesFirst.property),
    },
    {
      name: seriesSecond.name,
      data: getDataAxisByProperty(data, seriesSecond.property),
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: 'bar',
      zoom: {
        enabled: false,
      },
      toolbar: {
        tools: {
          download: false,
        },
      },
      fontSize: '14px',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 7,
        borderRadiusApplication: 'end',
        columnWidth: '80%',
        // distributed: true,
      },
    },
    colors: ['#0062FF', '#FAA52D'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'category',
      categories: getDataAxisByProperty(data, 'date'),
      showForNullSeries: false,
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Manrope,Arial, sans-serif',
        },
        formatter: (val) => {
          return val;
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      logBase: 0,
      showForNullSeries: false,
      decimalsInFloat: 1,
      labels: {
        style: {
          fontSize: '14px',
          colors: ['#828282'],
          fontFamily: 'Manrope, Arial, sans-serif',
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd/M',
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `<div class="bar-chart-tooltip">
                  <div class="series mb-3">
                    <p class="title-series">${seriesFirst.tooltipLabel}</p>
                    <p class="value-series">${series ? series[0][dataPointIndex] : 0}</p>
                  </div>
                  <div class="series">
                    <p class="title-series">${seriesSecond.tooltipLabel}</p>
                    <p class="value-series">${series ? series[1][dataPointIndex] : 0}</p>
                  </div>
                </div>`;
      },
    },
    legend: {
      show: false,
    },
  };
  return (
    <div className="bar-chart-wrapper">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="fw-bold fs-5 ">{name}</div>
        <div className="d-flex gap-14">
          <div className="legend series-first">
            <div /> {seriesFirst.name}
          </div>
          <span className="legend series-second">
            <div />
            {seriesSecond.name}
          </span>
        </div>
      </div>
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
}

BasicColumnChart.propTypes = {
  data: PropTypes.array,
  seriesFirst: PropTypes.object,
  seriesSecond: PropTypes.object,
  name: PropTypes.string,
};

export default BasicColumnChart;
