import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import ButtonConfirm from '@Components/ButtonConfirm';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { deleteUsers } from '@Apis/user';
import { X } from 'react-feather';

function ModalDeleteUser({ isOpen, close, refetch, detail }) {
  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: deleteUsers,
    isShowGlobalLoading: true,
  });

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Xoá User thành công');
      close();
      refetch();
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered show={isOpen} onHide={close}>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="fs-5 fw-bold text-grey">Xác nhận</p>
          <X size={18} onClick={close} />
        </div>
        <div className="fs-6">
          Bạn muốn xóa Nhân viên <b>{`"${detail?.name}"`}</b> ?
        </div>
        <div className="d-flex justify-content-start gap-8 mt-4">
          <ButtonConfirm
            variant="danger"
            name="Xoá"
            type="submit"
            className="fs-14 px-3"
            loading={isLoading}
            onClick={() => mutate({ id: detail.id })}
          />
          <ButtonConfirm
            variant="outline-danger"
            className="fs-14 px-3"
            name="Huỷ"
            onClick={close}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

ModalDeleteUser.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  refetch: PropTypes.func,
  detail: PropTypes.object,
};

export default ModalDeleteUser;
