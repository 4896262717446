/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { dayCustomParse, formatVND } from '@Utils/helper';
import dayjs from 'dayjs';

function LineChart({ data, seriesFirst, seriesSecond, name, period, style }) {
  const getDataAxisByProperty = (dataPeriod, property) => {
    return dataPeriod.map((item) => item[property]);
  };
  const series = [
    {
      data: getDataAxisByProperty(data, seriesFirst.property),
    },
  ];
  if (period !== 'optional') {
    series.push({
      data: getDataAxisByProperty(data, seriesSecond.property),
    });
  }

  const labelTooltip = ({ isCurrent, dataPointIndex }) => {
    if (period === 'this_year' && isCurrent) {
      return `Tháng ${dataPointIndex + 1}/${dayjs().format('YYYY')} `;
    }
    if (period === 'this_year' && !isCurrent) {
      return `Tháng ${dataPointIndex + 1}/${dayjs().add(-1, 'year').format('YYYY')} `;
    }
    const date = data[dataPointIndex].date;
    const year = dayjs().format('YYYY');

    if (isCurrent) {
      return date;
    }
    const dateParse = dayCustomParse({ date: `${date}/${year}`, format: 'DD/MM/YYYY' });

    if (!isCurrent && period === 'this_week') {
      const previousDate = dateParse.add(-1, 'week');
      return previousDate.format('DD/MM');
    }
    if (!isCurrent && period === 'this_month') {
      const previousDate = dateParse.add(-1, 'month');
      return previousDate.format('DD/MM');
    }
  };

  const legendLabel = ({ type }) => {
    const objType = {
      this_year_current: 'Năm này',
      this_year_previous: 'Năm trước',
      this_month_current: 'Tháng này',
      this_month_previous: 'Tháng trước',
      this_week_current: 'Tuần này',
      this_week_previous: 'Tuần trước',
    };
    return objType[type];
  };

  const options = {
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false,
      },
      toolbar: {
        tools: {
          download: false,
        },
      },
      fontSize: '14px',
    },
    colors: ['#0062FF', '#FAA52D'],
    grid: {
      borderColor: '#F1F1F5',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      // tickPlacement: 'on',
      type: 'category',
      categories: getDataAxisByProperty(data, 'date'),
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Lexend Deca, Arial, sans-serif',
        },
        formatter: (val) => {
          return val;
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '14px',
          colors: ['#828282'],
          fontFamily: 'Lexend Deca, Arial, sans-serif',
        },
        formatter: (val) => {
          return `${formatVND(val)} đ`;
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd/M',
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `<div class="line-chart-tooltip">    
                  <div class="series mb-25">
                    <p class="title-series">${labelTooltip({
                      dataPointIndex,
                      isCurrent: true,
                    })}</p>
                    <p class="value-series">${
                      series ? `${formatVND(series[0][dataPointIndex])} đ` : 0
                    }</p>
                  </div>
                  ${
                    period !== 'optional'
                      ? `<div class="series">
                      <p class="title-series">${labelTooltip({
                        dataPointIndex,
                        isCurrent: false,
                      })}</p>
                      <p class="value-series">${
                        series ? `${formatVND(series[1][dataPointIndex])} đ` : 0
                      }</p>
                    </div>`
                      : ''
                  }
                </div>`;
      },
    },
    legend: {
      show: false,
    },
  };

  const refTitle = useRef();
  const refChart = useRef();

  const [heightChart, setHeightChart] = useState(350);

  useEffect(() => {
    if (refTitle && refChart) {
      const heightChartWrapper = refChart.current.clientHeight;
      const heightTitle = refTitle.current.clientHeight;
      const heightChart = heightChartWrapper - heightTitle - 24 * 3;
      setHeightChart(heightChart);
    }
  }, [refTitle, refChart]);

  return (
    <div className="line-chart-wrapper" ref={refChart} style={style}>
      <div className="mb-3" ref={refTitle}>
        <div className="fw-bold fs-5 mb-3">{name}</div>
        {period !== 'optional' && (
          <div className="d-flex gap-14">
            <div className="legend series-first">
              <div /> {legendLabel({ type: `${period}_current` })}
            </div>
            <span className="legend series-second">
              <div />
              {legendLabel({ type: `${period}_previous` })}
            </span>
          </div>
        )}
      </div>
      <ReactApexChart options={options} series={series} type="area" height={heightChart} />
    </div>
  );
}

LineChart.propTypes = {
  data: PropTypes.array,
  seriesFirst: PropTypes.object,
  seriesSecond: PropTypes.object,
  name: PropTypes.string,
  period: PropTypes.string,
  style: PropTypes.object,
};

export default LineChart;
