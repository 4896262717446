import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import { createLog, getLogs } from '@Apis/artistLog';
import { checkObjectEmpty } from '@Utils/helper';
import { INPUT_LOG } from '@Constants/artistConstants';
import { useForm } from 'react-hook-form';
import FormInput from '@Components/FormInput';
import ButtonConfirm from '@Components/ButtonConfirm';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastSuccess, toastError } from '@Utils/toastrHelper';
import DataTableCustom from '@Components/DataTableCustom';
import ModalDeleteLog from '@Components/ModalDeleteLog';
import ModalLog from '@Components/ModalLog';
import { FormArtistLogWrapper } from './styled';
import columns from './ColumnsTable';

function Log({ id }) {
  const [filter, setFilter] = useState({});
  const [pageSize, setPageSize] = useState(15);
  const [page, setPage] = useState(1);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [detail, setDetail] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { data, refetch } = useCustomQuery({
    callApi: getLogs,
    isShowGlobalLoading: true,
    params: filter,
    enabled: checkObjectEmpty(filter),
  });
  useEffect(() => {
    if (id) {
      setFilter({ page, pageSize, artistId: id, orderBy: 'timestamp', order: 'DESC' });
    }
  }, [page, pageSize, id]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    data.artistId = id;
    mutate(data);
  };

  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: createLog,
    isShowGlobalLoading: true,
  });

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Thêm ghi chú thành công');
      refetch();
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  const openModalDetail = (item) => {
    setIsOpenModal(true);
    setDetail(item);
  };

  const openModalDelete = (item) => {
    setIsOpenModalDelete(true);
    setDetail(item);
  };

  return (
    <div className="content-wrapper ">
      <p className="fs-5 fw-bold p-3">Danh sách ghi chú</p>
      <FormArtistLogWrapper>
        <form className="artist-logs-form" onSubmit={handleSubmit(onSubmit)}>
          {!isLoading && (
            <FormInput
              inputsForm={INPUT_LOG({})}
              register={register}
              errors={errors}
              setValueForm={setValueForm}
              watch={watch}
            />
          )}

          <div className="d-flex gap-8">
            <ButtonConfirm
              variant="danger"
              className="fs-14 px-3"
              name="Thêm ghi chú"
              type="submit"
              loading={isLoading}
            />
          </div>
        </form>
        <div className="p-3">
          {data && (
            <DataTableCustom
              columns={columns({ openModalDetail, openModalDelete })}
              data={data}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isPageEffect
              setPage={setPage}
              noHeader
            />
          )}
        </div>
      </FormArtistLogWrapper>
      <ModalLog
        isOpen={isOpenModal}
        close={() => {
          setDetail(null);
          setIsOpenModal(false);
        }}
        refetch={refetch}
        detail={detail}
        artistId={id}
      />
      <ModalDeleteLog
        isOpen={isOpenModalDelete}
        refetch={refetch}
        close={() => {
          setDetail(null);
          setIsOpenModalDelete(false);
        }}
        detail={detail}
        artistId={id}
      />
    </div>
  );
}

Log.propTypes = { id: PropTypes.number };

export default Log;
