import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/auth`;

export const login = async (params) => {
  //try {
  const res = await axiosService.post(`${url}/login/user`, params);
  return res.data;
  // } catch (err) {
  //   console.log(err.response.data);
  //   debugger;
  //   return err.response.data;
  // }
};

export const generateResetCode = async (params) => {
  const res = await axiosService.post(`${url}/generate-reset-code/user`, params);
  return res.data;
};

export const resetPassword = async (params) => {
  const res = await axiosService.post(`${url}/reset-password/user`, params);
  return res.data;
};

export const verifyResetCode = async (params) => {
  const res = await axiosService.post(`${url}/verify-reset-code/user`, params);
  return res.data;
};
