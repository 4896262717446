import React from 'react';
import PropTypes from 'prop-types';
import { USER_STATUS } from '@Constants/userConstants';
import { UserStatusWrapper } from './styled';

function UserStatus({ status }) {
  const userStatus = USER_STATUS.find((item) => item.initialValue === status);
  if (userStatus) {
    return (
      <UserStatusWrapper style={{ color: userStatus.color, background: userStatus.background }}>
        {userStatus.label}
      </UserStatusWrapper>
    );
  }
  return '';
}

UserStatus.propTypes = {
  status: PropTypes.number,
};

export default UserStatus;
