import React from 'react';
import PropTypes from 'prop-types';
import { ARTIST_STATUS } from '@Constants/artistConstants';
import { ArtistStatusTableWrapper } from './styled';

function ArtistStatusTable({ status, style }) {
  const artistStatus = ARTIST_STATUS.find((item) => item.value === status);
  if (artistStatus) {
    return (
      <ArtistStatusTableWrapper
        style={{ color: artistStatus.color, background: artistStatus.background, ...style }}
      >
        {artistStatus.label}
      </ArtistStatusTableWrapper>
    );
  }
  return '';
}

ArtistStatusTable.propTypes = {
  status: PropTypes.string,
  style: PropTypes.object,
};

export default ArtistStatusTable;
