import React from 'react';
import PropTypes from 'prop-types';

function SelectContainer(props) {
  return <div>SelectContainer</div>;
}

SelectContainer.propTypes = {};

export default SelectContainer;
