import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, Navigate } from 'react-router-dom';

export default function PublicLayout() {
  const location = useLocation();
  const { pathname } = location;
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem(process.env.TOKEN);
  if ((pathname === '/login' || pathname === '/') && user.defaultHomepage) {
    return <Navigate to={user.defaultHomepage} />;
  }
  if (pathname === '/' && !token) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <Outlet />
    </div>
  );
}
