import React from 'react';
import { Edit3, Trash } from 'react-feather';
import { Nav } from 'react-bootstrap';

function columns({ openModalDetail, openModalDelete }) {
  return [
    {
      name: 'STT',
      selector: (row, index) => index + 1,
      width: '60px',
      center: true,
      compact: true,
    },
    {
      name: 'NHÓM QUYỀN',
      selector: (row) => row.name,
    },
    {
      name: 'SỐ NHÂN VIÊN',
      selector: (row) => row.usersCount,
    },
    {
      name: '',
      right: true,
      selector: (row) => (
        <div className="d-flex align-items-center gap-8">
          <Nav.Link>
            <Edit3 color="#a70e13" size={18} onClick={() => openModalDetail(row)} />
          </Nav.Link>
          <Nav.Link>
            <Trash color="#a70e13" size={18} onClick={() => openModalDelete(row)} />
          </Nav.Link>
        </div>
      ),
    },
  ];
}

export default columns;
