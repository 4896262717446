import styled from 'styled-components';

export const DashboardInfoWrapper = styled.div`
  height: 100%;
  .artist-summary {
    .summary-item {
      border: 1px solid #ebebec;
      border-radius: 24px;
      padding: 16px;
    }
  }
`;
