import React, { useEffect } from 'react';
import HeadingTitle from '@Components/HeadingTitle';
import SubHeading from '@Components/SubHeading';
import InfoIcon from '@Assets/images/artist/info.png';
import BankIcon from '@Assets/images/artist/bank-info.png';
import AccountIcon from '@Assets/images/artist/account.png';
import { useForm } from 'react-hook-form';
import FormInput from '@Components/FormInput';
import ButtonConfirm from '@Components/ButtonConfirm';
import useCustomMutation from '@Utils/useCustomMuation';
import { createArtist } from '@Apis/artist';
import { toastSuccess, toastError } from '@Utils/toastrHelper';
import { useNavigate } from 'react-router-dom';
import { INFOMATION_INPUT, BANK_INPUT, ACCOUNT_INFO } from '@Constants/artistConstants';

function Addnew() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
    watch,
  } = useForm();

  const dataDefault = {
    re_email: '',
    password: 'TC1234@abc',
  };

  const emailValue = watch('email');
  dataDefault.re_email = emailValue;

  const onSubmit = (data) => {
    const formData = new FormData();
    const { bankAccounts0, bankAccounts1, re_email, ...dataUpdate } = data;
    Object.keys(dataUpdate).map((key) => {
      if (dataUpdate[key]) {
        formData.append(key, dataUpdate[key]);
      }
    });
    if (
      bankAccounts0 &&
      bankAccounts0.bankName &&
      bankAccounts0.accountHolder &&
      bankAccounts0.accountNumber
    ) {
      formData.append('bankAccounts[0][bankName]', bankAccounts0.bankName);
      formData.append('bankAccounts[0][accountHolder]', bankAccounts0.accountHolder);
      formData.append('bankAccounts[0][accountNumber]', bankAccounts0.accountNumber);
    }
    if (
      bankAccounts1 &&
      bankAccounts1.bankName &&
      bankAccounts1.accountHolder &&
      bankAccounts1.accountNumber
    ) {
      formData.append('bankAccounts[1][bankName]', bankAccounts1?.bankName);
      formData.append('bankAccounts[1][accountHolder]', bankAccounts1?.accountHolder);
      formData.append('bankAccounts[1][accountNumber]', bankAccounts1?.accountNumber);
    }
    mutate({ formData });
  };

  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: createArtist,
    isShowGlobalLoading: true,
  });

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Thêm mới Artist thành công');
      navigate('/artist');
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  return (
    <>
      <HeadingTitle title="Thêm mới Artist" back="/artist" />
      <div className="content-body">
        <div className="row g-0">
          <form onSubmit={handleSubmit(onSubmit)} className="col-8">
            <div className="mb-4">
              <SubHeading title="Thông tin cá nhân" icon={InfoIcon} className="mb-3" />
              <FormInput
                inputsForm={INFOMATION_INPUT({})}
                register={register}
                errors={errors}
                setValueForm={setValueForm}
                watch={watch}
              />
            </div>
            <div className="mb-4">
              <SubHeading title="Thông tin tài khoản ngân hàng" icon={BankIcon} className="mb-3" />
              <FormInput
                inputsForm={BANK_INPUT({})}
                register={register}
                errors={errors}
                setValueForm={setValueForm}
                watch={watch}
              />
            </div>
            <div className="mb-4">
              <SubHeading title="Tài khoản & mật khẩu" icon={AccountIcon} className="mb-3" />
              <FormInput
                inputsForm={ACCOUNT_INFO(dataDefault)}
                register={register}
                errors={errors}
                setValueForm={setValueForm}
                watch={watch}
              />
            </div>
            <div className="d-flex gap-8">
              <ButtonConfirm
                className="fs-14 px-3"
                variant="danger"
                name="Lưu lại"
                type="submit"
                loading={isLoading}
              />
              <ButtonConfirm
                className="fs-14 px-3"
                variant="outline-danger"
                name="Huỷ"
                onClick={() => navigate('/artist')}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

Addnew.propTypes = {};

export default Addnew;
