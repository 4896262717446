import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/artists`;

export const getArtists = async (params) => {
  const res = await axiosService.get(url, params);
  return res.data;
};

export const detailArtist = async ({ id }) => {
  const res = await axiosService.get(`${url}/${id}`);
  return res.data;
};

export const createArtist = async ({ formData }) => {
  const res = await axiosService.post(url, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const updateArtist = async ({ id, formData }) => {
  const res = await axiosService.post(`${url}/${id}`, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const getAllArtist = async () => {
  const res = await axiosService.get(`${url}/all`);
  return res.data;
};

export const info = async () => {
  const res = await axiosService.get(`${process.env.API_URL}/artist/me`);
  return res.data;
};

export const editInfo = async ({ formData }) => {
  const res = await axiosService.post(`${process.env.API_URL}/artist/me`, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const deleteArtist = async ({ id }) => {
  const res = await axiosService.delete(`${url}/${id}`);
  return res.data;
};
