import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import SummaryTrend from '@Components/SummaryTrend';
import { formatVND, calcPeriodTrend } from '@Utils/helper';
import { period as summaryPeriod, thisYear, thisMonth, thisWeek } from '@Apis/statisticArtist';
import useDashboardData from '@Utils/useDashboardData';

function Summary({ period, dateRange }) {
  const [summaryData, setSummaryData] = useState({});
  const [percent, setPercent] = useState(0);

  const objectFunc = {
    this_year: thisYear,
    this_week: thisWeek,
    this_month: thisMonth,
    optional: summaryPeriod,
  };

  const func = objectFunc[period];
  const data = useDashboardData({ func, dateRange });

  useEffect(() => {
    if (data) {
      setSummaryData(data.currentPeriodNumbers);
      const percent = calcPeriodTrend({
        data: data.currentPeriodNumbers,
        property: 'artistProfit',
      });
      setPercent(percent);
    }
  }, [data]);

  return (
    <Container className="artist-summary px-0 mb-3">
      <Row className="g-0 gx-3">
        <Col>
          <div className="summary-item d-flex justify-content-between">
            <div>
              <p className="fs-14">Lợi nhuận</p>
              <p className="fs-5 fw-bold">{formatVND(summaryData.artistProfit)}</p>
            </div>
            {period !== 'optional' && <SummaryTrend percent={percent} period={period} />}
          </div>
        </Col>
        <Col>
          <div className="summary-item">
            <p className="fs-14">Số lượng Artwork đang bán</p>
            <p className="fs-5 fw-bold">{summaryData.sellingArtworksCount}</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

Summary.propTypes = { period: PropTypes.string, dateRange: PropTypes.array };

export default Summary;
