import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FacebookIcon from '@Assets/images/icons/facebook.png';
import InstagramIcon from '@Assets/images/icons/instagram.png';
import PortfolioIcon from '@Assets/images/icons/web.png';
import { Button } from 'react-bootstrap';
import { Edit3, Trash } from 'react-feather';
import ArtistStatusTable from '@Components/ArtistStatusTable';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { googleFileDownloadUrl, googleImageUrl } from '@Utils/helper';
import NoImage from '@Assets/images/no-image.png';
import { deleteArtist } from '@Apis/artist';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { usePermissions } from '@Utils/usePermissions';
import { ArtistWrapper } from './styled';

function ArtistInfo({ info }) {
  const navigate = useNavigate();
  const showButtonEdit = usePermissions({ roleName: 'artist', permission: 'update' });
  const showButtonDelete = usePermissions({ roleName: 'artist', permission: 'delete' });

  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: deleteArtist,
    isShowGlobalLoading: true,
  });
  const handleDelete = () => {
    mutate({ id: info.id });
  };
  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Xoá Artist thành công');
      navigate('/artist');
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  return (
    <ArtistWrapper>
      <div className="header-artist">
        <div className="artist-avatar-wrapper">
          <img
            src={
              info.profileImageGoogleFileId
                ? googleImageUrl(info.profileImageGoogleFileId)
                : NoImage
            }
            alt="avatar"
            className="artist-avatar mb-2"
          />
        </div>

        <p className="fs-18 fw-bold">{info.stageName}</p>
        <p className="full-name pt-1 pb-2">{info.name}</p>
        <div className="artist-social">
          <a href={info.facebookUrl} target="_blank" rel="noreferrer">
            <img src={FacebookIcon} alt="Facebook" />
          </a>
          <a href={info.instagramUrl} target="_blank" rel="noreferrer">
            <img src={InstagramIcon} alt="Instagram" />
          </a>
          <a href={info.portfolioUrl} target="_blank" rel="noreferrer">
            <img src={PortfolioIcon} alt="Portfolio" />
          </a>
        </div>
        {showButtonEdit && (
          <Button
            variant="outline-danger btn-edit"
            onClick={() => navigate(`/artist/edit/${info.id}`)}
          >
            <Edit3 size={14} />
            <span>Sửa thông tin</span>
          </Button>
        )}
      </div>
      <div className="body-artist">
        <div className="body-artist-item">
          <p className="label  mb-3">Trạng thái hợp tác</p>
          <ArtistStatusTable status={info.status} style={{ display: 'initial' }} />
        </div>
        <div className="body-artist-item">
          <p className="label">Email</p>
          <p className="fs-14 fw-semibold">{info.email}</p>
        </div>
        <div className="body-artist-item">
          <p className="label">Điện thoại</p>
          <p className="fs-14 fw-semibold">{info.contactNumber}</p>
        </div>
        <div className="body-artist-item">
          <p className="label">Địa chỉ</p>
          <p className="fs-14 fw-semibold">{info.address}</p>
        </div>
        <div className="body-artist-item">
          <p className="label">Sinh nhật</p>
          <p className="fs-14 fw-semibold">
            {info.dateOfBirth && dayjs(info.dateOfBirth).format('DD/MM/YYYY')}
          </p>
        </div>
        <div className="body-artist-item">
          <p className="label">Số CMT/CCCD</p>
          <p className="fs-14 fw-semibold">{info.citizenIdentificationNumber}</p>
        </div>
        <div className="body-artist-item">
          <p className="label">Số tài khoản</p>
          {info.bankAccounts.map((bankAccount) => (
            <p className="fs-14 fw-semibold" key={bankAccount.id}>
              {`${bankAccount.accountNumber} - ${bankAccount.bankName} - ${bankAccount.accountHolder}`}
            </p>
          ))}
        </div>
        <div className="body-artist-item">
          <p className="label">Học vấn</p>
          <p className="fs-14 fw-semibold">{info.academicLevel}</p>
        </div>
        <div className="body-artist-item">
          <p className="label">Size áo</p>
          <p className="fs-14 fw-semibold">{info.shirtSize}</p>
        </div>
        <div className="body-artist-item">
          <p className="label">Năm ký HĐ đầu tiên</p>
          <p className="fs-14 fw-semibold">
            {info.firstContractSigningDate &&
              dayjs(info.firstContractSigningDate).format('DD/MM/YYYY')}
          </p>
        </div>
        <div className="body-artist-item">
          <p className="label">Mã HĐ</p>
          <p className="fs-14 fw-semibold">{info.contractNumber}</p>
        </div>
        <div className="body-artist-item">
          <p className="label">Thời gian liên hệ gần nhất</p>
          <p className="fs-14 fw-semibold">
            {info.mostRecentContactDate && dayjs(info.mostRecentContactDate).format('DD/MM/YYYY')}
          </p>
        </div>
        <div className="body-artist-item">
          <p className="label">File scan hđ</p>
          {info.contractGoogleFileId && (
            <p className="fs-14 fw-semibold">
              <a
                target="_blank"
                href={googleFileDownloadUrl(info.contractGoogleFileId)}
                rel="noreferrer"
              >
                Xem file
              </a>
            </p>
          )}
        </div>
        {showButtonDelete && (
          <Button variant="outline-danger btn-edit" onClick={() => handleDelete()}>
            <Trash size={14} />
            <span>Xoá Artist</span>
          </Button>
        )}
      </div>
    </ArtistWrapper>
  );
}

ArtistInfo.propTypes = {
  info: PropTypes.object,
};

export default ArtistInfo;
