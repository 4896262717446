import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { googleImageUrl } from '@Utils/helper';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { ImageHoverWrapper } from './styled';

function ImageHover({ imgSrc, alt }) {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <ImageHoverWrapper
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onFocus={handleMouseOver}
      onBlur={handleMouseOut}
    >
      <img src={googleImageUrl(imgSrc)} alt={alt} data-tag="allowRowEvents" />
      <Dropdown.Menu show={isHovering} drop="up">
        <Dropdown.Item>
          <img src={googleImageUrl(imgSrc)} alt={alt} data-tag="allowRowEvents" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </ImageHoverWrapper>
  );
}

ImageHover.propTypes = {
  imgSrc: PropTypes.string,
  alt: PropTypes.string,
};

export default ImageHover;
