import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '@Components/MultiSelect';
import { getOptionsByName } from '@Utils/helper';

function ColumnsControl({ columnsShow, setColumnsShow, localStorageName }) {
  const optionStatus = getOptionsByName({ inititalOptions: columnsShow, name: 'columns' });

  const handleOnChangeSelect = (selected, event) => {
    if (selected && event) {
      const { name } = event;

      const optionsByName = columnsShow.find((item) => item.name === name);
      if (event.action === 'select-option' && event.option.value === 'all') {
        optionsByName.selectedOptions = optionsByName.allOptions;
        localStorage.setItem(localStorageName, JSON.stringify(optionsByName.selectedOptions));
        setColumnsShow([...columnsShow]);
        return;
      }
      if (event.action === 'deselect-option' && event.option.value === 'all') {
        optionsByName.selectedOptions = [];
        setColumnsShow([...columnsShow]);
        localStorage.setItem(localStorageName, JSON.stringify(optionsByName.selectedOptions));
        return;
      }
      optionsByName.selectedOptions = selected.filter((item) => item.value !== 'all');
      localStorage.setItem(localStorageName, JSON.stringify(optionsByName.selectedOptions));
      setColumnsShow([...columnsShow]);
    }
  };

  return (
    <MultiSelect
      style={{ width: '200px', fontSize: '14px' }}
      allOptions={optionStatus?.allOptions}
      placeholder="Hiển thị tất cả cột"
      className="multi-select"
      selectedOptions={optionStatus?.selectedOptions}
      handleOnChange={handleOnChangeSelect}
      name={optionStatus?.name}
    />
  );
}

ColumnsControl.propTypes = {
  columnsShow: PropTypes.array,
  setColumnsShow: PropTypes.func,
  localStorageName: PropTypes.string,
};

export default ColumnsControl;
