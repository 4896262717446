import styled from 'styled-components';

export const SortColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  .icon-arrow {
    display: inline-block;
    height: 0;
    width: 0;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
  }
  .icon-sort-desc {
    border-width: 4px 4px 0;
    border-top-color: #d0d5dd;
  }
  .icon-sort-asc {
    border-width: 0 4px 4px;
    border-bottom-color: #d0d5dd;
  }
  .icon-sort-desc.active {
    border-top-color: #a70e13 !important;
  }
  .icon-sort-asc.active {
    border-bottom-color: #a70e13 !important;
  }
`;

export const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 10px;
`;
