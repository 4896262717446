import React from 'react';
import PropTypes from 'prop-types';
import TableEmptyIcon from '@Assets/images/table-empty.png';

function TableEmpty(props) {
  return (
    <div>
      <img src={TableEmptyIcon} alt="table-empty" width="300" style={{ padding: '100px 0' }} />
    </div>
  );
}

TableEmpty.propTypes = {};

export default TableEmpty;
