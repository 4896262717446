import styled from 'styled-components';

export const Title = styled.div`
  font-size: 40px;
  text-align: center;
  padding: 30px 0 20px 0;
  font-weight: bold;
`;

export const BackButton = styled.button`
  outline: none;
  border: none;
  color: #a70e13;
  text-decoration: underline;
  background: #f8f8f8;
`;
