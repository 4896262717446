import React from 'react';
import PropTypes from 'prop-types';
import { INFOMATION_INPUT } from '@Constants/artistConstants';
import FormInput from '@Components/FormInput';
import { useForm } from 'react-hook-form';
import ButtonConfirm from '@Components/ButtonConfirm';
import { useNavigate } from 'react-router-dom';

function Info({ data, mutate, isLoading }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setValueForm,
    watch,
  } = useForm();

  const onSubmit = (dataUpdate) => {
    const formData = new FormData();

    if (dataUpdate.email === data.email) {
      delete dataUpdate.email;
    }
    Object.keys(dataUpdate).map((key) => {
      if (dataUpdate[key]) {
        formData.append(key, dataUpdate[key]);
      }
    });
    mutate({ id: data.id, formData });
  };

  return (
    <div className="artist-content">
      <div className="mb-4">
        <p className="fs-4 mb-4">Thông tin cá nhân</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            inputsForm={INFOMATION_INPUT({ data })}
            register={register}
            errors={errors}
            setValueForm={setValueForm}
            watch={watch}
          />
          <div className="d-flex gap-8">
            <ButtonConfirm variant="danger" name="Lưu lại" type="submit" loading={isLoading} />
            <ButtonConfirm
              variant="outline-danger"
              name="Huỷ"
              onClick={() => navigate(`/artist/${data.id}`)}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

Info.propTypes = {
  data: PropTypes.object,
  mutate: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Info;
