import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Nav } from 'react-bootstrap';
import { ChevronDown, Grid, List, Plus } from 'react-feather';
import { ACTION_CONTROL } from '@Constants/artworkConstants';
import { toastError } from '@Utils/toastrHelper';
import ColumnsControl from '@Components/ColumnsControl';
import { usePermissions } from '@Utils/usePermissions';
import ControlStatus from './ControlStatus';
import ControlSaleOpeningDate from './ControlSaleOpeningDate';
import UploadArtwork from './UploadArtwork';

function Control({
  setIsOpenAddNew,
  isShowGridTable,
  setIsShowGridTable,
  refetch,
  selectedRows,
  clearSelectedRow,
  columnsShow,
  setColumnsShow,
  localStorageName,
}) {
  const [action, setAction] = useState('');

  const handleSelectAction = (item) => {
    if (selectedRows.length === 0) {
      toastError('Bạn chưa chọn artwork');
      return;
    }
    setAction(item);
  };
  const showButtonAddnew = usePermissions({ roleName: 'artwork', permission: 'create' });

  return (
    <div className="py-3 d-flex algin-items-center justify-content-between">
      <div className="d-flex algin-items-center">
        <Dropdown>
          <Dropdown.Toggle className="fs-14" style={{ width: '180px' }}>
            {action ? action.label : 'Thao tác'}
            <ChevronDown size={18} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {ACTION_CONTROL.map((item) => {
              if (item.value) {
                return (
                  <Dropdown.Item onClick={() => handleSelectAction(item)} key={item.value}>
                    {item.label}
                  </Dropdown.Item>
                );
              }
            })}
          </Dropdown.Menu>
        </Dropdown>
        {action && action.value === 'CHANGE_STATUS' && (
          <ControlStatus
            items={selectedRows}
            refetch={refetch}
            clearSelectedRow={clearSelectedRow}
            setAction={setAction}
          />
        )}
        {action && action.value === 'CHANGE_SALE_OPENING_DATE' && (
          <ControlSaleOpeningDate
            items={selectedRows}
            refetch={refetch}
            clearSelectedRow={clearSelectedRow}
            setAction={setAction}
          />
        )}
        <div className="ms-3 fs-14">
          <ColumnsControl
            columnsShow={columnsShow}
            setColumnsShow={setColumnsShow}
            localStorageName={localStorageName}
          />
        </div>
      </div>

      {setIsOpenAddNew && showButtonAddnew && (
        <div className="d-flex">
          <UploadArtwork refetch={refetch} />
          <Button variant="danger" onClick={() => setIsOpenAddNew(true)}>
            <Plus size="14" />
            <span className="fs-14 fw-semibold">Thêm Artwork</span>
          </Button>
        </div>
      )}
      {setIsShowGridTable &&
        (isShowGridTable ? (
          <Nav.Link
            className="text-danger d-flex align-items-center gap-8 fs-14"
            onClick={() => setIsShowGridTable(false)}
          >
            <List size={18} />
            <span>Xem dạng danh sách</span>
          </Nav.Link>
        ) : (
          <Nav.Link
            className="text-danger d-flex align-items-center gap-8 fs-14"
            onClick={() => setIsShowGridTable(true)}
          >
            <Grid size={18} />
            <span>Xem dạng lưới</span>
          </Nav.Link>
        ))}
    </div>
  );
}

Control.propTypes = {
  setIsOpenAddNew: PropTypes.func,
  isShowGridTable: PropTypes.bool,
  setIsShowGridTable: PropTypes.func,
  refetch: PropTypes.func,
  selectedRows: PropTypes.array,
  clearSelectedRow: PropTypes.func,
  columnsShow: PropTypes.array,
  setColumnsShow: PropTypes.func,
  localStorageName: PropTypes.string,
};

export default Control;
