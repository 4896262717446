import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';

function ButtonConfirm({
  type = 'button',
  variant,
  name,
  className,
  disabled = false,
  loading = false,
  onClick,
}) {
  return (
    <Button
      type={type}
      variant={variant}
      className={`d-flex align-items-center justify-content-center ${className}`}
      disabled={disabled || loading}
      onClick={onClick}
      style={{ minWidth: '140px' }}
    >
      {loading ? (
        <Spinner animation="border" variant="white" size="sm" />
      ) : (
        <span className="text-center">{name}</span>
      )}
    </Button>
  );
}

ButtonConfirm.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ButtonConfirm;
