import styled from 'styled-components';

export const SummaryTrendWrapper = styled.div`
  .percent-compare {
    border-radius: 20px;
    font-weight: 500;
    font-size: 16px;
    padding: 2px 8px;
    margin-top: 20px;
    &.percent-compare-up {
      background: #ecfdf3;
      color: #027a48;
    }
    &.percent-compare-down {
      background: #f5e6e5;
      color: #a70d13;
    }
  }
`;
