import axiosService from '@Libs/axiosService';

const url = `${process.env.API_URL}/auth`;

export const login = async (params) => {
  const res = await axiosService.post(`${url}/login/artist`, params);
  return res.data;
};

export const generateResetCode = async (params) => {
  const res = await axiosService.post(`${url}/generate-reset-code/artist`, params);
  return res.data;
};

export const resetPassword = async (params) => {
  const res = await axiosService.post(`${url}/reset-password/artist`, params);
  return res.data;
};

export const verifyResetCode = async (params) => {
  const res = await axiosService.post(`${url}/verify-reset-code/artist`, params);
  return res.data;
};
