import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Upload } from 'react-feather';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import useCustomMutation from '@Utils/useCustomMuation';
import { importArtwork } from '@Apis/artwork';
import { UploadArtworkWrapper } from './styled';

function UploadArtwork({ refetch }) {
  const inputRef = useRef(null);
  const { mutate, isSuccess, isLoading, isError, error, data } = useCustomMutation({
    callApi: importArtwork,
    isShowGlobalLoading: true,
  });
  const onChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      toastError('No file selected.');
    }
    const formData = new FormData();
    formData.append('file', file);
    mutate({
      formData,
    });
  };

  const handleUpload = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    if (data) {
      const { failedExcelRowGroups, successfulArtworks } = data;
      if (failedExcelRowGroups.length > 0) {
        const message = failedExcelRowGroups.map((item) => item.reason);
        toastError(message);
        return;
      }
      if (successfulArtworks.length > 0) {
        toastSuccess('Upload Artworks thành công');
        refetch();
      }
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);
  return (
    <UploadArtworkWrapper>
      <input
        name="upload-artwork"
        onChange={onChange}
        accept=".xlsx"
        type="file"
        className="d-none"
        ref={inputRef}
      />
      <Button variant="outline-danger me-3" onClick={() => handleUpload()}>
        <Upload size="14" />
        <span className="fs-14 fw-semibold ms-2">Upload file</span>
      </Button>
    </UploadArtworkWrapper>
  );
}

UploadArtwork.propTypes = {
  refetch: PropTypes.func,
};

export default UploadArtwork;
