import styled from 'styled-components';

export const FormArtistLogWrapper = styled.div`
  .artist-logs-form {
    background: #fdfafa;
    padding: 10px 1.25rem 20px;
  }
  .mb-4 {
    margin-bottom: 0.8rem !important;
  }
  .rdt_TableHeadRow {
    display: none;
  }
  .rdt_TableRow {
    border: none;
  }
`;
