import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import { Dropdown } from 'react-bootstrap';
import SettingIcon from '@Assets/images/icons/setting.png';
import LogoutIcon from '@Assets/images/icons/logout.png';
import Avatar from '@Assets/images/icons/avatar-header.png';
import { getUserType } from '@Utils/helper';
import { useSelector } from 'react-redux';

function UserDropDown(props) {
  const info = useSelector((state) => state.user.info);
  const logout = () => {
    localStorage.removeItem(process.env.TOKEN);
    localStorage.removeItem('USER_TYPE');
    localStorage.removeItem(process.env.REFRESH_TOKEN);
    if (info.userType === 'USER') {
      window.location.href = '/login';
    } else {
      window.location.href = '/login-artist';
    }
  };

  const userType = getUserType();

  return (
    <Dropdown className="dropdown-user">
      <Dropdown.Toggle>
        <img src={Avatar} alt="admin" /> {userType === 'USER' && info?.role?.name}
        {userType === 'ARTIST' && info?.stageName}
        <ChevronDown size="18" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {/* <Dropdown.Item>
          <img src={SettingIcon} alt="Đổi mật khẩu" /> Đổi mật khẩu
        </Dropdown.Item> */}
        <Dropdown.Item onClick={() => logout()}>
          <img src={LogoutIcon} alt="Đăng xuất" />
          Đăng xuất
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

UserDropDown.propTypes = {};

export default UserDropDown;
