import React, { useState } from 'react';
import HeadingTitle from '@Components/HeadingTitle';
import TabPanel from '@Components/TabPanel';
import Staff from './components/Staff';
import Role from './components/Role';
import RolePermissions from './components/RolePermissions';

function User() {
  const [activeKey, setActiveKey] = useState('staff');
  const tabs = [
    {
      key: 'staff',
      name: 'NHÂN VIÊN',
    },
    {
      key: 'role',
      name: 'QUẢN LÝ NHÓM QUYỀN',
    },
    {
      key: 'role_permissions',
      name: 'PHÂN QUYỀN NHÓM',
    },
  ];
  // const showTabRole
  return (
    <div className="content-wrapper">
      <HeadingTitle title="Quản lý nhân viên" />
      <div className="content-body">
        <div>
          <TabPanel tabs={tabs} activeKey={activeKey} setActiveKey={setActiveKey} />
          {activeKey === 'staff' && <Staff />}
          {activeKey === 'role' && <Role />}
          {activeKey === 'role_permissions' && <RolePermissions />}
        </div>
      </div>
    </div>
  );
}

User.propTypes = {};

export default User;
