/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import Paginate from '@Components/Paginate';
import PaginateEffect from '@Components/PaginateEffect';
import TableEmpty from './TableEmpty';
import TableLoading from './TableLoading';

// ** Bootstrap Checkbox Component
const BootstrapCheckbox = forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        ref={ref}
        {...rest}
        onClick={onClick}
      />
      <label className="custom-control-label" />
    </div>
  );
});

function DataTableCustom({
  columns,
  data,
  pageSize,
  setPageSize,
  isLoading,
  pageSizeName,
  onSelectedRowsChange,
  selectableRowSelected,
  selectableRows = false,
  clearSelectedRows = false,
  noPagination = false,
  isPageEffect = false,
  page,
  setPage,
  ...props
}) {
  const [pagi, setPaginate] = useState(null);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    if (data) {
      const { data: dataTable, meta } = data;
      setDataTable(dataTable);
      setPaginate(meta);
    }
  }, [data]);

  return (
    <>
      <DataTable
        selectableRows={selectableRows}
        columns={columns}
        className="react-dataTable table-responsive py-2"
        data={dataTable}
        selectableRowsComponent={BootstrapCheckbox}
        onSelectedRowsChange={onSelectedRowsChange}
        noDataComponent={<TableEmpty />}
        progressPending={isLoading}
        progressComponent={<TableLoading />}
        clearSelectedRows={clearSelectedRows}
        selectableRowSelected={selectableRowSelected}
        {...props}
      />
      {pagi && !noPagination && !isPageEffect && (
        <Paginate
          data={pagi}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageSizeName={pageSizeName}
        />
      )}
      {pagi && !noPagination && isPageEffect && (
        <PaginateEffect
          data={pagi}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </>
  );
}

BootstrapCheckbox.propTypes = {
  onClick: PropTypes.func,
};

DataTableCustom.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.object,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  isLoading: PropTypes.bool,
  pageSizeName: PropTypes.string,
  onSelectedRowsChange: PropTypes.func,
  selectableRows: PropTypes.bool,
  clearSelectedRows: PropTypes.bool,
  noPagination: PropTypes.bool,
  isPageEffect: PropTypes.bool,
  page: PropTypes.number,
  setPage: PropTypes.func,
  selectableRowSelected: PropTypes.object,
};

export default DataTableCustom;
