import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  artistCountPeriod,
  artistCountThisWeek,
  artistCountThisMonth,
  artistCountThisYear,
} from '@Apis/statisticChart';
import dayjs from 'dayjs';
import BasicColumnChart from '@Components/BasicColumnChart';
import { getDatesFromPeriod } from '@Utils/helper';
import useDashboardData from '@Utils/useDashboardData';

function ColumnChartArtist({ period, dateRange }) {
  const [dataChart, setDataChart] = useState([]);
  const objectFunc = {
    this_year: artistCountThisYear,
    this_week: artistCountThisWeek,
    this_month: artistCountThisMonth,
    optional: artistCountPeriod,
  };
  const func = objectFunc[period];
  const data = useDashboardData({ func, dateRange });

  useEffect(() => {
    if (data) {
      const { sellingArtistsResult, signedArtistsResult, period: periodData } = data;
      const dataProcessed = processDataForChart({
        sellingArtistsResult,
        signedArtistsResult,
        period,
        periodData,
      });
      setDataChart(dataProcessed);
    }
  }, [data]);

  const processDataForChart = ({
    sellingArtistsResult,
    signedArtistsResult,
    period,
    periodData,
  }) => {
    if (period === 'this_year') {
      const categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
      const dataProcessed = categories.map((item) => {
        const month = dayjs().month(item);
        const monthFormat = month.format('YYYY-MM');
        const sellingArtistByMonth = sellingArtistsResult.find(
          (dataMonth) => dataMonth.timePoint === monthFormat,
        );
        const signedArtistByMonth = signedArtistsResult.find(
          (dataMonth) => dataMonth.timePoint === monthFormat,
        );
        return {
          date: month.format('MMM'),
          sellingArtist: sellingArtistByMonth ? sellingArtistByMonth.value : 0,
          signedArtist: signedArtistByMonth ? signedArtistByMonth.value : 0,
        };
      });
      return dataProcessed;
    }
    const { fromDate, toDate } = periodData;
    const categories = getDatesFromPeriod({ fromDate, toDate });
    const dataProcessed = categories.map((item) => {
      const date = dayjs(item);
      const dateFormat = date.format('YYYY-MM');
      const sellingArtistByMonth = sellingArtistsResult.find(
        (dataMonth) => dataMonth.timePoint === dateFormat,
      );
      const signedArtistByMonth = signedArtistsResult.find(
        (dataMonth) => dataMonth.timePoint === dateFormat,
      );
      return {
        date: date.format('DD/MM'),
        sellingArtist: sellingArtistByMonth ? sellingArtistByMonth.value : 0,
        signedArtist: signedArtistByMonth ? signedArtistByMonth.value : 0,
      };
    });
    return dataProcessed;
  };

  return (
    <BasicColumnChart
      data={dataChart}
      name="Số lượng Artist"
      seriesFirst={{
        property: 'signedArtist',
        tooltipLabel: 'Artist đã hợp tác',
        color: '#0062ff',
        name: 'Đã hợp tác',
      }}
      seriesSecond={{
        property: 'sellingArtist',
        tooltipLabel: 'Artist đang bán',
        color: '#F5B544',
        name: 'Đang bán',
      }}
    />
  );
}

ColumnChartArtist.propTypes = { period: PropTypes.string, dateRange: PropTypes.array };

export default ColumnChartArtist;
