import React, { useState, useEffect } from 'react';
import HeadingTitle from '@Components/HeadingTitle';
import { useParams } from 'react-router-dom';
import { detailArtist, updateArtist } from '@Apis/artist';
import useCustomQuery from '@Utils/useCustomQuery';
import { Tab, Nav, Col, Row } from 'react-bootstrap';
import TabInfoActive from '@Assets/images/icons/tab-info-active.png';
import TabInfo from '@Assets/images/icons/tab-info.png';
import TabBank from '@Assets/images/icons/tab-bank.png';
import TabBankActive from '@Assets/images/icons/tab-bank-active.png';
import TabAccount from '@Assets/images/icons/tab-account.png';
import TabAccountActive from '@Assets/images/icons/tab-account-active.png';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastSuccess, toastError } from '@Utils/toastrHelper';
import { ArtistEditWrapper } from './styled';
import Info from './components/Info';
import Bank from './components/Bank';
import Account from './components/Account';

function ArtistEdit() {
  const { id } = useParams();
  const { data } = useCustomQuery({
    callApi: detailArtist,
    isShowGlobalLoading: true,
    params: { id },
  });

  const [activeTab, setActiveTab] = useState('info');

  const { mutate, isSuccess, isLoading, isError, error } = useCustomMutation({
    callApi: updateArtist,
    isShowGlobalLoading: true,
  });
  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Sửa Artist thành công');
      // navigate('/artist');
    }
    if (isError) {
      toastError(error);
    }
  }, [isSuccess, isError]);

  return (
    <div className="content-wrapper">
      {data && (
        <ArtistEditWrapper>
          <HeadingTitle title="Sửa thông tin" subTitle={data.stageName} back={`/artist/${id}`} />
          <div className="content-body">
            <Tab.Container activeKey={activeTab}>
              <Row className="p-0 g-0 tab-edit ">
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item onClick={() => setActiveTab('info')}>
                      <Nav.Link eventKey="info">
                        <img src={activeTab === 'info' ? TabInfoActive : TabInfo} alt="info" />
                        <span className="ms-3">Thông tin cá nhân</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => setActiveTab('bank')}>
                      <Nav.Link eventKey="bank">
                        <img src={activeTab === 'bank' ? TabBankActive : TabBank} alt="bank" />
                        <span className="ms-3">Thông tin tài khoản ngân hàng</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => setActiveTab('account')}>
                      <Nav.Link eventKey="account">
                        <img
                          src={activeTab === 'account' ? TabAccountActive : TabAccount}
                          alt="account"
                        />
                        <span className="ms-3">Tài khoản & Mật khẩu</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="info">
                      <Info data={data} mutate={mutate} isLoading={isLoading} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="bank">
                      <Bank data={data} mutate={mutate} isLoading={isLoading} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="account">
                      <Account data={data} mutate={mutate} isLoading={isLoading} />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </ArtistEditWrapper>
      )}
    </div>
  );
}

ArtistEdit.propTypes = {};

export default ArtistEdit;
