import styled from 'styled-components';

export const HeadingTitleWrapper = styled.div`
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  a {
    color: #667085;
  }
`;
