import styled from 'styled-components';

export const ArtistWrapper = styled.div`
  border: 1px solid #ebebec;
  border-radius: 24px;
  .header-artist {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ebebec;
  }
  .artist-avatar-wrapper {
    position: relative;
    padding-bottom: 70px;
    width: 70px;
    border-radius: 8px;
    max-height: 38px;
    display: block;
    margin: auto;
  }
  .artist-avatar {
    border-radius: 50%;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .full-name {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    color: #667085;
  }
  .artist-social {
    display: flex;
    gap: 24px;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .btn-edit {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 100px;
    margin: auto;
    margin-top: 16px;
  }
  .body-artist {
    padding: 16px;
    .body-artist-item {
      margin-bottom: 16px;
      .label {
        font-weight: 700;
        font-size: 12px;
        color: #9b9ba1;
        margin-bottom: 4px;
      }
    }
  }
`;
