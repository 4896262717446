import React, { useEffect, useState } from 'react';
import HeadingTitle from '@Components/HeadingTitle';
import useCustomQuery from '@Utils/useCustomQuery';
import { getArtwork } from '@Apis/artwork';
import { checkObjectEmpty, getColumnsArtworkTable } from '@Utils/helper';
import DataTableCustom from '@Components/DataTableCustom';
import { getAllArtworkTag } from '@Apis/artworkTag';
import { getAllArtist } from '@Apis/artist';
import { toastError } from '@Utils/toastrHelper';
import { getAllArtworkCollection } from '@Apis/artworkCollection';
import ModalArtwork from '@Components/ModalArtwork';
import ModalArtistDetail from '@Components/ModalArtworkDetail';
import useQuery from '@Utils/useQuery';
import { INPUT, COLUMNS_TABLE, INPUT_BASIC, INPUT_ADVANCE } from '@Constants/artworkConstants';
import { useSelector } from 'react-redux';
import { getAllArtworkProductLine } from '@Apis/artworkProductLine';
import ModalArtworkDelete from '@Components/ModalArtworkDelete';
import columns from './components/ColumnsTable';
import Filter from './components/Filter';
import Control from './components/Control';

function Artwork() {
  const [filter, setFilter] = useState({});
  const [isOpenAddNew, setIsOpenAddNew] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [pageSize, setPageSize] = useState(15);
  const [detail, setDetail] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const localStorageName = 'COLUMNS_TABLE_ARTWORK';
  let selectedOptions = [];
  const selectedSaved = localStorage.getItem(localStorageName);
  if (selectedSaved) {
    selectedOptions = JSON.parse(selectedSaved);
  }
  const info = useSelector((state) => state.user.info);

  const columnsTable = getColumnsArtworkTable({
    permissions: info.permissions,
    columnsTable: COLUMNS_TABLE,
  });

  const initialOptions = [
    {
      name: 'columns',
      initialOptions: columnsTable,
      allOptions: [{ value: 'all', label: 'Tất cả các cột' }, ...columnsTable],
      selectedOptions,
    },
  ];
  const [columnsShow, setColumnsShow] = useState(initialOptions);

  const { data, isError, error, refetch } = useCustomQuery({
    callApi: getArtwork,
    isShowGlobalLoading: true,
    params: filter,
    enabled: checkObjectEmpty(filter),
  });
  const page = useQuery({ name: 'page' }) || 1;
  const { data: artworkTags } = useCustomQuery({
    callApi: getAllArtworkTag,
    isShowGlobalLoading: false,
  });

  const { data: productLines } = useCustomQuery({
    callApi: getAllArtworkProductLine,
    isShowGlobalLoading: false,
  });

  const { data: artists } = useCustomQuery({
    callApi: getAllArtist,
    isShowGlobalLoading: false,
  });

  const { data: collections } = useCustomQuery({
    callApi: getAllArtworkCollection,
    isShowGlobalLoading: false,
  });

  useEffect(() => {
    if (isError) {
      toastError(error);
    }
  }, [isError]);

  const handleOpenModalDetail = (item) => {
    setDetail(item);
    setIsOpenDetail(true);
  };

  const onChangeSort = ({ order, orderBy }) => {
    if (order) {
      setFilter({
        ...filter,
        order,
        orderBy,
      });
    } else {
      const { order, orderBy, ...restFilter } = filter;
      setFilter(restFilter);
    }
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [toggledClearRows]);

  let inputsByPermission;
  const { artwork } = info.permissions;
  if (artwork.includes('read_full')) {
    inputsByPermission = INPUT;
  }
  if (artwork.includes('read_basic')) {
    inputsByPermission = INPUT_BASIC;
  }
  if (artwork.includes('read_advance')) {
    inputsByPermission = INPUT_ADVANCE;
  }

  return (
    <div className="content-wrapper">
      <HeadingTitle title="Quản lý Artwork" />
      <div className="content-body">
        <div>
          <Filter
            filter={filter}
            setFilter={setFilter}
            pageSize={pageSize}
            artworkTags={artworkTags}
            artistTags={artists}
            collectionTags={collections}
            productLines={productLines}
            page={page}
          />
          <Control
            setIsOpenAddNew={setIsOpenAddNew}
            selectedRows={selectedRows}
            refetch={refetch}
            clearSelectedRow={() => setToggleClearRows(!toggledClearRows)}
            columnsShow={columnsShow}
            setColumnsShow={setColumnsShow}
            localStorageName={localStorageName}
          />
          {data && (
            <DataTableCustom
              columns={columns({
                order: filter.order,
                orderBy: filter.orderBy,
                onChangeSort,
                columnsShow,
                info,
              })}
              data={data}
              onRowClicked={(item) => handleOpenModalDetail(item)}
              pageSize={pageSize}
              setPageSize={setPageSize}
              selectableRows
              onSelectedRowsChange={(selected) => {
                setSelectedRows(selected.selectedRows);
              }}
              clearSelectedRows={toggledClearRows}
            />
          )}
        </div>
        {info && (
          <ModalArtwork
            isOpen={isOpenAddNew}
            close={() => {
              setIsOpenAddNew(false);
              setDetail(null);
            }}
            refetch={refetch}
            detailId={detail?.artwork.id}
            inputs={inputsByPermission}
          />
        )}
        {detail && (
          <ModalArtistDetail
            isOpen={isOpenDetail}
            close={() => {
              setIsOpenDetail(false);
              setDetail(null);
            }}
            detailId={detail.artwork.id}
            handleOpenModalEdit={() => {
              setIsOpenAddNew(true);
              setIsOpenDetail(false);
            }}
            handleOpenModalDelete={() => {
              setIsOpenDelete(true);
              setIsOpenDetail(false);
            }}
          />
        )}
        {detail && (
          <ModalArtworkDelete
            isOpen={isOpenDelete}
            close={() => {
              setIsOpenDelete(false);
              setDetail(null);
            }}
            detail={detail.artwork}
            refetch={refetch}
          />
        )}
      </div>
    </div>
  );
}

Artwork.propTypes = {};

export default Artwork;
