import React from 'react';
import PropTypes from 'prop-types';
import { X } from 'react-feather';
import { Nav } from 'react-bootstrap';

function TagControl({ tags, onDeleteAllFilter, onDeleteFilter }) {
  return (
    tags &&
    tags.length > 0 && (
      <div className="d-flex gap-8 pt-3">
        <span className="fs-14 text-grey white-space-nowrap">Lọc theo: </span>
        <div className="tag-wrapper">
          {tags &&
            tags.length > 0 &&
            tags.map((tag) => {
              return (
                <div className="tag-list" key={tag.tagName}>
                  {tag.values.map((item) => {
                    return (
                      <div className="tag-item" key={item.value}>
                        <span>{item.label}</span>
                        <div
                          type="button"
                          onClick={() =>
                            onDeleteFilter({
                              tagName: tag.tagName,
                              value: item.value,
                            })
                          }
                          role="button"
                          tabIndex="0"
                        >
                          <X size={14} color="#667085" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          <Nav.Link
            onClick={() => onDeleteAllFilter()}
            type="button"
            className="btn btn-delete white-space-nowrap"
          >
            Xoá tất cả
          </Nav.Link>
        </div>
      </div>
    )
  );
}

TagControl.propTypes = {
  tags: PropTypes.array,
  onDeleteAllFilter: PropTypes.func,
  onDeleteFilter: PropTypes.func,
};

export default TagControl;
