import styled from 'styled-components';

export const SummaryWrapper = styled.div`
  .summary-item {
    border-radius: 24px;
    padding: 24px;
    margin-top: 24px;
    flex: 1 1 0;
    .trend {
      display: flex;
      align-items: center;
      gap: 18px;
      .trend-avatar {
        width: 32px;
      }
      .trend-icon {
        width: 18px;
      }
    }
    &:nth-child(1) {
      background: #f6e7e6;
    }
    &:nth-child(2) {
      background: #f8f1fb;
    }
    &:nth-child(3) {
      background: #edf5ed;
    }
    &:nth-child(4) {
      background: #edfafe;
    }
    &:nth-child(5) {
      background: #fef5ed;
    }
  }
`;
