import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  login as loginAdmin,
  generateResetCode as generateResetCodeAdmin,
  resetPassword as resetPasswordAdmin,
  verifyResetCode as verifyResetCodeAdmin,
} from '@Apis/auth';
import {
  login as loginArtist,
  generateResetCode as generateResetCodeArtist,
  resetPassword as resetPasswordArtist,
  verifyResetCode as verifyResetCodeArtist,
} from '@Apis/authArtist';

export const useFuncAuthen = ({ name }) => {
  const location = useLocation();
  const { pathname } = location;

  const adminFunc = {
    login: loginAdmin,
    generateResetCode: generateResetCodeAdmin,
    resetPassword: resetPasswordAdmin,
    verifyResetCode: verifyResetCodeAdmin,
  };
  const artistFunc = {
    login: loginArtist,
    generateResetCode: generateResetCodeArtist,
    resetPassword: resetPasswordArtist,
    verifyResetCode: verifyResetCodeArtist,
  };
  let func = {};
  if (pathname === '/login') {
    func = adminFunc;
  } else {
    func = artistFunc;
  }
  const funcName = func[name];
  return funcName;
};
