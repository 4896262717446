import React from 'react';
import PropTypes from 'prop-types';

import { Tabs, Tab, Nav } from 'react-bootstrap';
import { TabWrapper } from './styled';

function TabPanel({ activeKey, setActiveKey, tabs }) {
  return activeKey ? (
    <TabWrapper>
      <Tab.Container defaultActiveKey={activeKey}>
        <Nav variant="" className="">
          {tabs.map((tab) => (
            <Nav.Item key={tab.key} onClick={() => setActiveKey(tab.key)}>
              <Nav.Link eventKey={tab.key}>
                {tab.icon && (
                  <img src={activeKey === tab.key ? tab.iconActive : tab.icon} alt={tab.name} />
                )}
                {tab.name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Tab.Container>
    </TabWrapper>
  ) : (
    ''
  );
}

TabPanel.propTypes = {
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setActiveKey: PropTypes.func,
  tabs: PropTypes.array,
};

export default TabPanel;
